import {
  formatNumberFull,
  formatNumberRound,
} from "../../services/UtilsService";
import _variables from "../../styles/_variables";

const McProgressChart = ({
  bar,
  small = false,
  noText = false,
  center = false,
}) => {
  return (
    <div
      className={
        "d-flex align-items-center mb-2 " +
        (center ? "justify-content-center" : "justify-content-start")
      }
    >
      {noText ? (
        <></>
      ) : (
        <div
          className="fixed-number-space me-2 big-font"
          style={{ color: bar.color }}
        >
          {formatNumberFull(bar.value)}%
        </div>
      )}
      <div className={"simple-bar-container " + (small ? "small " : "")}>
        <div
          className="simple-bar-content"
          style={{
            width: `${formatNumberRound(bar.value)}%`,
            backgroundColor: bar.color,
          }}
        ></div>
      </div>
    </div>
  );
};

export default McProgressChart;
