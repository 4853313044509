import Loader from "../Loader/Loader";
import "./Refreshing.css";

const Refreshing = ({ conditions = [false], marginLeft = true }) => {
  {
    return conditions.some((cond) => cond) ? (
      <div
        className={
          "refreshing-container px-4 py-2 " +
          (marginLeft ? "ml-expanded" : "ml-not-expanded")
        }
      >
        <div className="mb-1">Loading data</div>
        <Loader color={"#ffffff"} type={"BarLoader"} />
      </div>
    ) : (
      <></>
    );
  }
};

export default Refreshing;
