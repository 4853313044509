import _variables from "../../styles/_variables";
import "./McCharts.css";

const McDonutSideText = ({ rows }) => {
  return (
    <div className="my-auto w-100">
      {rows.length > 0 ? (
        rows.map((row, index) => {
          return (
            <div
              className={"row " + (index > 0 ? "mt-2" : "")}
              key={index + "donutsidetext"}
            >
              <div
                className="col-4 col-lg-4 align-self-center donut-left-content"
                style={{ color: row.color }}
              >
                {row.leftContent}
              </div>
              <div className="col-8 col-lg-7 offset-lg-1 align-self-center">
                {row.rightContent}
              </div>
            </div>
          );
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export default McDonutSideText;
