import { format, parse } from "date-fns";

export const convertToCSVBarChart = (
  data,
  productType = null,
  timeRange = null,
  country = null,
  acquirer = null,
  label = null,
  field1 = null,
  value1 = null,
  field2 = null,
  value2 = null,
  field3 = null,
  value3 = null,
  field4 = null,
  value4 = null,
  field5 = null,
  value5 = null,
  field6 = null,
  value6 = null
) => {
  const headers = [
    label,
    ...(productType != null ? ["Product Type"] : []),
    ...(timeRange != null ? ["Time Range"] : []),
    ...(country != null ? ["Country"] : []),
    ...(acquirer != null ? ["Acquirer"] : []),
    field1,
    field2,
    field3,
    field4,
    field5,
    field6,
  ];
  const csvData = data.map((item) => {
    let labelName;
    if (label === "Time Period") {
      const date = parse(item.month, "MMM yy", new Date());
      const formattedDate = format(date, "MMMM yyyy").toUpperCase();
      labelName = formattedDate;
    }
    if (label === "Country") {
      labelName = item.country;
    }
    if (label === "Issuer") {
      labelName = item.issuer;
    }
    if (label === "Month") {
      labelName = item.yearmonth;
    }
    if (label === "Ticket Range") {
      labelName = item.label.replace("€", "EUR");
    }

    if (acquirer === "") acquirer = "All Acquirers";
    let productTypeString = "";
    if (productType === "CR") productTypeString = "Credit";
    if (productType === "DB") productTypeString = "Debit";
    if (productType === "") productTypeString = "All Products";

    let countries;
    if (country != null) {
      if (typeof country === "string")
        countries = country != "0" ? country : "All Countries";
      else countries = country.map((c) => c.label);
    }

    return [
      labelName,
      productType != null ? productTypeString : undefined,
      timeRange != null ? timeRange : undefined,
      country != null ? countries : undefined,
      acquirer != null ? acquirer : undefined,
      item[value1] != null ? (field1.includes("%") ? item[value1].toFixed(2) + "%" : item[value1].toFixed(2)) : undefined,
      item[value2] != null ? (field2.includes("%") ? item[value2].toFixed(2) + "%" : item[value2].toFixed(2)) : undefined,
      item[value3] != null ? (field3.includes("%") ? item[value3].toFixed(2) + "%" : item[value3].toFixed(2)) : undefined,
      item[value4] != null ? (field4.includes("%") ? item[value4].toFixed(2) + "%" : item[value4].toFixed(2)) : undefined,
      item[value5] != null ? (field5.includes("%") ? item[value5].toFixed(2) + "%" : item[value5].toFixed(2)) : undefined,
      item[value6] != null ? (field6.includes("%") ? item[value6].toFixed(2) + "%" : item[value6].toFixed(2)) : undefined,
    ].filter(Boolean);
  });

  return [headers, ...csvData];
};

export const convertToCSVDeclineReasons = (
  data,
  productType = null,
  timeRange = null,
  label = null,
  subchannels = null,
  ticketRange = null,
  tokenization = null,
  program = null,
  fields = []
) => {
  const headers = [
    label,
    ...(productType != null ? ["Product Type"] : []),
    ...(timeRange != null ? ["Time Range"] : []),
    ...(subchannels != null ? ["Subchannels"] : []),
    ...(ticketRange != null ? ["Ticket Range"] : []),
    ...(tokenization != null ? ["Tokenization"] : []),
    ...(program != null ? ["Program"] : []),
    ...fields.map((f) => f.field), // Aggiungi i nomi dei campi all'intestazione
  ];

  const csvData = data.map((item) => {
    let labelName;
    if (label === "Month") {
      const date = parse(item.month, "MMM yy", new Date());
      const formattedDate = format(date, "MMMM yyyy").toUpperCase();
      labelName = formattedDate;
    }

    if (label === "Decline Reason") {
      labelName = item.label;
    }

    let productTypeString = "";
    if (productType === "CR") productTypeString = "Credit";
    if (productType === "DB") productTypeString = "Debit";
    if (productType === "") productTypeString = "All Products";

    if (subchannels === "0") subchannels = "All Subchannels";
    if (ticketRange === "") ticketRange = "All Tickets";
    if (tokenization === "") tokenization = "All Tokenizations";
    if (program === "") program = "All Programs";

    const fieldValues = fields.map((f) =>
      item[f.value] != undefined ? (f.field.includes("%") ? item[f.value].toFixed(2) + "%" : item[f.value].toFixed(2)) : "  "
    );

    return [
      labelName,
      productType != null ? productTypeString : undefined,
      timeRange != null ? timeRange : undefined,
      subchannels != null ? subchannels : undefined,
      ticketRange != null ? ticketRange : undefined,
      tokenization != null ? tokenization : undefined,
      program != null ? program : undefined,
      ...fieldValues,
    ].filter(Boolean);
  });

  return [headers, ...csvData];
};

export const convertToCSVPerformance = (
  data,
  productType = null,
  timeRange = null,
  country = null,
  fields = []
) => {
  const headers = [
    " ",
    ...(productType != null ? ["Product Type"] : []),
    ...(timeRange != null ? ["Time Range"] : []),
    ...(country != null ? ["Country"] : []),
    ...fields.map((f) => f.field),
  ];

  let first = true;
  const csvData = data.map((item) => {
    let productTypeString = "";
    if (productType === "CR") productTypeString = "Credit";
    if (productType === "DB") productTypeString = "Debit";
    if (productType === "") productTypeString = "All Products";

    if (country === "0") country = "All Countries";

    const fieldValues = fields.map((f) =>
      item[f.value] != undefined ? (f.field.includes("%") ? item[f.value].toFixed(2) + "%" : item[f.value].toFixed(2)) : "  "
    );

    let countries;
    if (country != null) {
      if (typeof country === "string")
        countries = country != "0" ? country : "All Countries";
      else countries = country.map((c) => c.label);
    }

    let returnList = [
      first ? "Merchant" : "Peer",
      productType != null ? productTypeString : undefined,
      timeRange != null ? timeRange : undefined,
      country != null ? countries : undefined,
      ...fieldValues,
    ].filter(Boolean);

    first = false;

    return returnList;
  });

  return [headers, ...csvData];
};

export const convertToCSVDecline = (
  data,
  label,
  timeRange = null,
  fields = []
) => {
  const headers = [
    label,
    ...(timeRange != null ? ["Time Range"] : []),
    ...fields.map((f) => f.field),
  ];

  const csvData = data.map((item) => {
    let labelName;
    if (label === "Decline Reason") {
      labelName = item.name;
    }

    const fieldValues = fields.map((f) =>
      item[f.value] != undefined ? (f.field.includes("%") ? item[f.value] + "%" : item[f.value]) : "  "
    );

    let returnList = [
      labelName,
      timeRange != null ? timeRange : undefined,
      ...fieldValues,
    ].filter(Boolean);

    return returnList;
  });

  return [headers, ...csvData];
};

export const convertToCSVPie = (
  data,
  label,
  timeRange = null,
  productType = null,
  country = null,
  acquirer = null,
  issuer = null,
  measure = null,
  fields = []
) => {
  const headers = [
    label,
    ...(timeRange != null ? ["Time Range"] : []),
    ...(productType != null ? ["Product Type"] : []),
    ...(country != null ? ["Country"] : []),
    ...(acquirer != null ? ["Acquirer"] : []),
    ...(issuer != null ? ["Issuer"] : []),
    ...(measure != null ? ["Measure"] : []),
    ...fields.map((f) => f.field),
  ];

  const csvData = data.map((item) => {
    let labelName;
    if (label === "Authentication Method") {
      labelName = item.auth_method;
    }
    if (label === "Subchannel ") {
      labelName = item.subchannel ? item.subchannel : item.name;
    }
    if (label === "Failure Reason") {
      labelName = item.name;
    }
    if (label === "Exemption Type") {
      labelName = item.exemption;
    }

    if (
      label === "Auth Method" ||
      label === "3DS Split" ||
      label === "Subchannel" ||
      label === "3DS Exemptions" ||
      label === "MAC" ||
      label === "Reason Code"
    ) {
      labelName = item.label;
    }

    if (label === "Ticket Range") {
      labelName = item.label.replace("€", "EUR");
    }

    const fieldValues = fields.map((f) => {
      if (item[f.value] != undefined && item[f.value] != 0) {
        if (typeof item[f.value] === "number") {
          return f.field.includes("%") ? item[f.value].toFixed(2) + "%" : item[f.value].toFixed(2);
        } else {
          return f.field.includes("%") ? item[f.value] + "%" : item[f.value];
        }
      } else {
        return " ";
      }
    });

    let countries;
    if (country != null) {
      if (typeof country === "string")
        countries = country != "0" ? country : "All Countries";
      else countries = country.map((c) => c.label);
    }

    if (acquirer === "") acquirer = "All Acquirers";
    if (issuer === "") issuer = "All Issuers";

    let productTypeString = "";
    if (productType === "CR") productTypeString = "Credit";
    if (productType === "DB") productTypeString = "Debit";
    if (productType === "") productTypeString = "All Products";

    let returnList = [
      labelName,
      timeRange != null ? timeRange : undefined,
      productType != null ? productTypeString : undefined,
      country != null ? countries : undefined,
      acquirer != null ? acquirer : undefined,
      issuer != null ? issuer : undefined,
      measure != null ? measure : undefined,
      ...fieldValues,
    ].filter(Boolean);

    return returnList;
  });

  return [headers, ...csvData];
};

export const convertToCSVYoY = (
  data,
  productType = null,
  timeRange = null,
  label = null,
  country = null,
  measure = null,
  cardSplit = null,
  fields = []
) => {
  const headers = [
    label,
    ...(productType != null ? ["Product Type"] : []),
    ...(timeRange != null ? ["Time Range"] : []),
    ...(country != null ? ["Country"] : []),
    ...(measure != null ? ["Measure"] : []),
    ...(cardSplit != null ? ["Card Split"] : []),
    ...fields.map((f) => f.field),
  ];

  const csvData = data.map((item) => {
    let labelName;
    if (label === "Month") {
      const date = parse(item.yearmonth, "MMM yy", new Date());
      const formattedDate = format(date, "MMMM yyyy").toUpperCase();
      labelName = formattedDate;
    }

    let productTypeString = "";
    if (productType === "CR") productTypeString = "Credit";
    if (productType === "DB") productTypeString = "Debit";
    if (productType === "") productTypeString = "All Products";

    let measureString = "";
    if (measure === "TRX") measureString = "Transaction Count";
    if (measure === "VOLUME") measureString = "Transaction Volume";

    let cardSplitString = "";
    if (cardSplit === "CP") cardSplitString = "Card Present";
    if (cardSplit === "CNP") cardSplitString = "Card Not Present";

    if (country === "0") country = "All Countries";

    const fieldValues = fields.map((f) =>
      item[f.value] != undefined ? (f.field.includes("%") ? item[f.value].toFixed(2) + "%" : item[f.value].toFixed(2)) : "  "
    );

    let returnList = [
      labelName,
      productType != null ? productTypeString : undefined,
      timeRange != null ? timeRange : undefined,
      country != null ? country : undefined,
      measure != null ? measureString : undefined,
      cardSplit != null ? cardSplitString : undefined,
      ...fieldValues,
    ].filter(Boolean);

    return returnList;
  });

  return [headers, ...csvData];
};

export const convertToCSV = (data) => {
  return;
};
