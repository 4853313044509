import { useState, useEffect, useContext } from "react";
import { AccountContext } from "../../services/AccountService";
import FetchService from "../../services/FetchService";
import {
  generateLastTwelveMonths,
  formatNumberFull,
  formatNumberLocale,
  formatErrorForAlert,
  findMax,
  assembleBody,
  assembleBodyWithProductType,
} from "../../services/UtilsService";
import LayoutPageContainer from "../../components/Layout/LayoutPageContainer";
import TimePeriod from "../../components/TimePeriod/TimePeriod";
import Refreshing from "../../components/Refreshing/Refreshing";
import SmallTitleFlex from "../../components/Title/SmallTitleFlex";
import KpiRow from "../../components/Kpi/KpiRow";
import Switcher from "../../components/Switcher/Switcher";
import SmallTitle from "../../components/Title/SmallTitle";
import McBarChart from "../../components/McCharts/McBarChart";
import TableYoY from "../../components/TableYoY/TableYoY";
import { _variablesTwoOranges } from "../../styles/_variables";
import McYoYChange from "../../components/McCharts/McYoYChange";
import FilterBar from "../../components/FilterBox/FilterBar";
import LayoutCardFilter from "../../components/Layout/LayoutCardFilter";
import Download from "../../components/Button/Download";

const PerformanceHome = () => {
  // global
  const {
    merchantName,
    sidebarExpanded,
    timePeriod,
    setTimePeriod,
    dashboardVersion,
  } = useContext(AccountContext);
  const [alert, setAlert] = useState({});
  // const [timePeriod, setTimePeriod] = useState([0, 11]);
  const [dateLabels, setDateLabels] = useState([]);

  // charts
  const [isLoadingKpis, setIsLoadingKpis] = useState(true);
  const [dataKpis, setDataKpis] = useState([]);
  const [isLoadingMonthlyPerformance, setIsLoadingMonthlyPerformance] =
    useState(true);
  const [dataMonthlyPerformance, setDataMonthlyPerformance] = useState([]);
  const [dataYoYVariation, setDataYoYVariation] = useState([]);
  const [isLoadingAverageTicket, setIsLoadingAverageTicket] = useState(true);
  const [dataAverageTicket, setDataAverageTicket] = useState([]);

  // dashboard version
  const dashboardVersion1 = dashboardVersion === 1 ? true : false;
  const dashboardVersion2 = dashboardVersion === 2 ? true : false;

  // others
  const [dataGuide, setDataGuide] = useState({
    x: "month",
    barsData: [
      { dataName: "trxCard", friendlyName: "Card present" },
      { dataName: "trxNoCard", friendlyName: "Card not present" },
    ],
  });
  const [dataGuideYoY, setDataGuideYoY] = useState("transactions");
  const [maxData, setMaxData] = useState(0);
  // product type
  const [productType, setProductType] = useState("");
  // get dates for timeperiod
  useEffect(() => {
    let body = {
      merchant_name: merchantName,
    };

    FetchService.post("/api/dashboard/dates/performance", body)
      .then((res) => {
        setDateLabels(generateLastTwelveMonths(res.data.max_date_customer));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  }, []);

  // get charts data based on timeperiod
  useEffect(() => {
    isLoadingAll();

    // wait some time for other changes
    const timeOut = setTimeout(() => {
      if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
        let body = assembleBodyWithProductType(
          merchantName,
          dateLabels[timePeriod[0]],
          dateLabels[timePeriod[1]],
          null,
          productType
        );

        getKpis(body);
        getMonthlyPerformance(body);
        getAverageTicket(body);
      }
    }, 1500);

    return () => clearTimeout(timeOut);
  }, [timePeriod, dateLabels, productType]);

  const isLoadingAll = () => {
    setIsLoadingKpis(true);
    setIsLoadingMonthlyPerformance(true);
    setIsLoadingAverageTicket(true);
  };

  const getKpis = (body) => {
    setIsLoadingKpis(true);

    FetchService.post(
      "/api/dashboard/performance/summary/overall-performance",
      body
    )
      .then((res) => {
        let temp = [];
        temp.push(
          formatNumberFull(res.data.sum_trx ? res.data.sum_trx / 1000 : 0)
        );
        temp.push(
          formatNumberFull(res.data.volume ? res.data.volume / 1000 : 0)
        );
        setDataKpis(temp);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingKpis(false));
  };

  const getMonthlyPerformance = (body) => {
    setIsLoadingMonthlyPerformance(true);

    FetchService.post(
      "/api/dashboard/performance/summary/monthly-performance",
      body
    )
      .then((res) => {
        let tempMonthly = res.data.map((el) => {
          return {
            month: el.yearmonth ? el.yearmonth : "N/A",
            trxCard: el.current_freq_count_trx_card_present
              ? el.current_freq_count_trx_card_present / 1000
              : 0,
            trxNoCard: el.current_freq_count_trx_card_not_present
              ? el.current_freq_count_trx_card_not_present / 1000
              : 0,
            volCard: el.current_freq_count_amount_card_present
              ? el.current_freq_count_amount_card_present / 1000
              : 0,
            volNoCard: el.current_freq_count_amount_card_not_present
              ? el.current_freq_count_amount_card_not_present / 1000
              : 0,
          };
        });
        setDataMonthlyPerformance(tempMonthly);

        // find max for better chart visualization
        setMaxData(findMax(tempMonthly));

        let tempYoY = res.data.map((el) => {
          return {
            month: el.yearmonth ? el.yearmonth : "N/A",
            trxCard: el.year_on_year_trx_card_present
              ? el.year_on_year_trx_card_present
              : 0,
            trxNoCard: el.year_on_year_trx_card_not_present
              ? el.year_on_year_trx_card_not_present
              : 0,
            volCard: el.year_on_year_amount_card_present
              ? el.year_on_year_amount_card_present
              : 0,
            volNoCard: el.year_on_year_amount_card_not_present
              ? el.year_on_year_amount_card_not_present
              : 0,
          };
        });
        setDataYoYVariation(tempYoY);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingMonthlyPerformance(false));
  };

  const getAverageTicket = (body) => {
    setIsLoadingAverageTicket(true);

    FetchService.post("/api/dashboard/performance/summary/average-ticket", body)
      .then((res) => {
        let temp = [];
        temp.push(
          formatNumberFull(
            res.data.avg_ticket_card_present
              ? res.data.avg_ticket_card_present
              : 0
          )
        );
        temp.push(
          formatNumberFull(
            res.data.avg_ticket_card_not_present
              ? res.data.avg_ticket_card_not_present
              : 0
          )
        );
        setDataAverageTicket(temp);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingAverageTicket(false));
  };

  const changeDataGuide = (switchBtn) => {
    if (switchBtn === "transactions") {
      setDataGuide({
        x: "month",
        barsData: [
          { dataName: "trxCard", friendlyName: "Card present" },
          { dataName: "trxNoCard", friendlyName: "Card not present" },
        ],
      });
    } else if (switchBtn === "volumes") {
      setDataGuide({
        x: "month",
        barsData: [
          { dataName: "volCard", friendlyName: "Card present" },
          { dataName: "volNoCard", friendlyName: "Card not present" },
        ],
      });
    } else {
      setDataGuide({});
    }

    setDataGuideYoY(switchBtn);
  };

  const handleSelectedProductType = (e) => {
    setProductType(e);
  };

  return (
    <LayoutPageContainer
      title={"Performance"}
      pages={[
        {
          title: "Summary",
          link: "/performance/home",
          active: true,
        },
        { title: "Geography", link: "/performance/geography" },
        { title: "Benchmark", link: "/performance/benchmark" },
      ]}
      alert={alert}
      setAlert={setAlert}
    >
      <Refreshing
        conditions={[
          isLoadingKpis,
          isLoadingMonthlyPerformance,
          isLoadingAverageTicket,
        ]}
        marginLeft={sidebarExpanded}
      />
      <TimePeriod
        dateLabels={dateLabels}
        timePeriod={timePeriod}
        setTimePeriod={setTimePeriod}
      />
      {dashboardVersion2 && (
        <FilterBar>
          <LayoutCardFilter
            onChange={handleSelectedProductType}
            tooltipAcquirer=""
            options={[
              { value: "", text: "All products" },
              { value: "CR", text: "Credit" },
              { value: "DB", text: "Debit" },
              /*             { value: "PP", text: "Prepaid" },
               */
            ]}
          />
        </FilterBar>
      )}
      <hr />
      <SmallTitle>Overall performance</SmallTitle>
      <KpiRow
        kpis={[
          {
            leftContent:
              formatNumberLocale(dataKpis[0] ? dataKpis[0] : "0") + " K",
            righContent: "Transactions",
          },
          {
            leftContent:
              formatNumberLocale(dataKpis[1] ? dataKpis[1] : "0") + " K€",
            righContent: "Volumes",
          },
        ]}
      />
      <hr />
      <SmallTitleFlex
        title={"Monthly performance"}
        tooltip={
          "Number of transactions and volumes (spend) by channel: card present (physical in-store) and card not present (online)."
        }
      />
      <div className="d-flex justify-content-between mr-4">
        <Switcher
          pages={[
            {
              title: "Transactions",
              onClick: () => {
                changeDataGuide("transactions");
              },
            },
            {
              title: "Volumes",
              onClick: () => {
                changeDataGuide("volumes");
              },
            },
          ]}
        />
        <div className="mr-4">
          <Download
            section="PerformanceSummary"
            chartName="MonthlyPerformance"
            data={dataMonthlyPerformance}
            graphType="barChart"
            productType={productType}
            label={"Time Period"}
            field1={"Transactions Card Present"}
            value1={"trxCard"}
            field2={"Transactions Card Not Present"}
            value2={"trxNoCard"}
            field3={"Volume Card Present"}
            value3={"volCard"}
            field4={"Volume Card Not Present"}
            value4={"volNoCard"}
            iconSize="sm"
            className="h-8 w-8"
          />
        </div>
      </div>
      <div className="mb-5">
        <McBarChart
          data={dataMonthlyPerformance}
          dataGuide={dataGuide}
          labels={[
            dataGuideYoY == "transactions"
              ? "Transactions (K)"
              : "Volumes (K€)",
          ]}
          twoYs={false}
          maxData={maxData}
          tooltipMeasureUnit={dataGuideYoY == "transactions" ? " K" : " K€"}
        />
      </div>
      <div className="d-flex justify-content-between mr-4">
        <SmallTitleFlex
          title={"YoY Change"}
          tooltip={
            "YoY Change represents the monthly performance of a given month vs the same month of the previous year."
          }
        />
        <div className="mr-4">
          <Download
            section="PerformanceSummary"
            chartName="YoY Change"
            data={dataYoYVariation}
            graphType="barChart"
            productType={productType}
            label={"Time Period"}
            field1={"Var% Transactions Card Present"}
            value1={"trxCard"}
            field2={"Var% Transactions Card Not Present"}
            value2={"trxNoCard"}
            field3={"Var% Volume Card Present"}
            value3={"volCard"}
            field4={"Var% Volume Card Not Present"}
            value4={"volNoCard"}
            iconSize="sm"
            className="h-8 w-8"
          />
        </div>
      </div>
      {/* <TableYoY data={dataYoYVariation} dataGuide={dataGuideYoY} /> */}
      <McYoYChange data={dataYoYVariation} />
      <hr />
      <SmallTitleFlex
        title={"Average ticket"}
        tooltip={
          "Average ticket is calculated as: volumes (spend) divided by transactions."
        }
      />
      <KpiRow
        kpis={[
          {
            leftContent:
              formatNumberLocale(
                dataAverageTicket[0] ? dataAverageTicket[0] : "0"
              ) + " €",
            righContent: (
              <div>
                <b>Average ticket</b>
                <br />
                Card present
              </div>
            ),
          },
          {
            leftContent:
              formatNumberLocale(
                dataAverageTicket[1] ? dataAverageTicket[1] : "0"
              ) + " €",
            righContent: (
              <div>
                <b>Average ticket</b>
                <br />
                Card not present
              </div>
            ),
            color: _variablesTwoOranges.primaryOrangeLight,
          },
        ]}
      />
    </LayoutPageContainer>
  );
};

export default PerformanceHome;
