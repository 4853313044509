import { Treemap, ResponsiveContainer, Tooltip, Cell } from "recharts";
import { formatNumberLocale } from "../../services/UtilsService";
import { _variablesTwelve } from "../../styles/_variables";

const McTreemapChart = ({
  data,
  listDeclineReasons,
  showAbsoluteValue = true,
}) => {
  const COLORS = Object.values(_variablesTwelve);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip-container p-2">
          {payload.map((p, index) => {
            return (
              <div key={index + p.value + Math.random()}>
                <div
                  style={{
                    color: p.payload.fill,
                  }}
                  className="custom-tooltip-text"
                >
                  <b>{`${p.payload.name}: `}</b>
                  <br />
                  {`${p.payload.percent}% `}
                  {showAbsoluteValue ? `(${formatNumberLocale(p.value)})` : ""}
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  const CustomizedContent = ({
    root,
    depth,
    x,
    y,
    width,
    height,
    index,
    payload,
    colors,
    rank,
    name,
  }) => {
    const fontSize = width / 14;
    const fontSizeMin = 8;
    const fontSizeMax = 16;

    const maxChars = Math.floor(width / (fontSize / 2));

    const truncatedName =
      name?.length > maxChars ? `${name.substring(0, maxChars - 3)}...` : name;

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            fill: COLORS[
              listDeclineReasons && listDeclineReasons.length > 0
                ? (listDeclineReasons.findIndex((el) => el === name) >= 0
                    ? listDeclineReasons.findIndex((el) => el === name)
                    : 0) % COLORS.length
                : index % COLORS.length
            ],
            stroke: "#fff",
            strokeWidth: 2,
            strokeOpacity: 1,
          }}
        />
        {index < 3 || (width > String(name).length * 8 && height > 12) ? (
          <text
            x={x + width / 2}
            y={y + height / 2 + fontSize / 2}
            textAnchor="middle"
            fill="#fff"
            fontSize={
              fontSize > fontSizeMin && fontSize < fontSizeMax
                ? fontSize
                : fontSize > fontSizeMax
                ? fontSizeMax
                : fontSizeMin
            }
          >
            {truncatedName}
          </text>
        ) : null}
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={500}>
      {data.length > 0 ? (
        <Treemap
          width="100%"
          height="100%"
          data={data}
          dataKey="size"
          stroke="#fff"
          isAnimationActive={false}
          content={<CustomizedContent />}
        >
          <Tooltip content={<CustomTooltip />} />
        </Treemap>
      ) : (
        <></>
      )}
    </ResponsiveContainer>
  );
};

export default McTreemapChart;
