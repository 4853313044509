import "./Footer.css";

const Footer = ({ type }) => {
  return (
    <div className="footer-container px-2 py-1 mt-5">
      Data refers to Mastercard data only. Transaction level data is used and
      not card level.
    </div>
  );
};

export default Footer;
