import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Label,
  LabelList,
  Legend,
  ReferenceLine, // Add ReferenceLine component
} from "recharts";
import {
  customTooltipLayout,
  formatNumberFull,
  formatNumberRoundLocale,
} from "../../services/UtilsService";
import {
  _variablesFourGrey,
  _variablesThreeOrangesRate,
  _variablesTwoOranges,
  _variablesTwelve,
} from "../../styles/_variables";

const McBarChartVertical = ({
  data,
  dataGuide,
  labels,
  height = 600,
  palette = _variablesFourGrey,
  legend = false,
  isPerformance = false,
  background = true,
  tooltipMeasureUnit = "",
  avg,
  hideNamesIssuers = false,
  hideNamesAcquirers = false,
  isPercentage = false,
}) => {
  const COLORS = Object.values(palette);

  let issuerIndex = 1;
  let acquirerIndex = 1;

  // Prepare data with custom labels
  const preparedData = data.map((item) => {
    let label = item[dataGuide.y];

    if (hideNamesIssuers || hideNamesAcquirers) {
      if (!label.includes("All") && !label.includes("Others")) {
        label = hideNamesIssuers
          ? `Issuer ${issuerIndex++}`
          : `Acquirer ${acquirerIndex++}`;
      }
    }

    return { ...item, customLabel: label };
  });

  const tickFormatter = (value, index) => {
    if (hideNamesIssuers) {
      return `Issuer${index + 1}`;
    }

    const limit = 15;
    const item = preparedData.find((item) => item[dataGuide.y] === value);
    const newValue = item ? item.customLabel : value;

    if (newValue.length < limit) return newValue;
    return `${newValue.substring(0, limit).replace(/ /g, "\u00A0")}...`;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const item = preparedData.find((item) => item[dataGuide.y] === label);
      const tooltipLabel = item ? item.customLabel : label;

      return customTooltipLayout(
        active,
        payload,
        tooltipLabel,
        tooltipMeasureUnit
      );
    }

    return null;
  };

  const renderCustomizedLabel = ({ x, y, width, height, value }) => {
    let xPosition = 30 + x + width;

    return (
      <g>
        <text
          x={xPosition > 550 ? 550 : xPosition}
          y={y + height / 2}
          fill="#141413"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {value != null ? isPercentage ? formatNumberFull(value) + "%" : formatNumberFull(value) : ""}
        </text>
      </g>
    );
  };

  const verticalLineValue = avg ? avg : null;

  return (
    <>
      {!isPerformance ? (
        <div className="d-flex justify-content-center align-items-center mb-2">
          {legend ? (
            <>
              <div
                className="square me-1"
                style={{
                  backgroundColor: _variablesTwelve.primaryTeal,
                }}
              ></div>
              <div className="me-2">Portfolio Average</div>
            </>
          ) : (
            <>
              <div style={{ height: "24px" }}></div>
            </>
          )}
        </div>
      ) : (
        <></>
      )}

      <ResponsiveContainer width="100%" height={height}>
        <BarChart
          layout="vertical"
          width="100%"
          height="100%"
          data={data}
          margin={{
            top: 25,
            right: 60,
            left: 80,
            bottom: 30,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" tickFormatter={formatNumberRoundLocale}>
            {labels ? (
              labels[0] && (
                <Label value={labels[0]} offset={-40} position={"top"} />
              )
            ) : (
              <></>
            )}
          </XAxis>
          <YAxis
            type="category"
            dataKey={dataGuide.y}
            tickFormatter={tickFormatter}
            fontSize={10}
          ></YAxis>
          <Tooltip content={<CustomTooltip />} />
          {isPerformance ? <Legend verticalAlign="top" height={60} /> : <></>}

          {dataGuide.barsData.map((bar, index) => {
            return (
              <Bar
                dataKey={bar.dataName}
                name={bar.friendlyName}
                fill={COLORS[index % COLORS.length]}
                background={background && index === 0 && { fill: COLORS[2] }}
                stackId="a"
                key={`bar-${index}-${Math.random()}`}
                animationBegin={1000}
              >
                {data.map((entry, index) =>
                  "avg" in entry ? (
                    <Cell
                      cursor="pointer"
                      fill={COLORS[0]}
                      key={`cell-${index}-${Math.random()}`}
                    />
                  ) : (
                    <div key={`cell-${index}-${Math.random()}`}></div>
                  )
                )}
                {!isPerformance ? (
                  <LabelList
                    dataKey={bar.dataName}
                    content={renderCustomizedLabel}
                    position="right"
                    fill={COLORS[3]}
                  />
                ) : (
                  <></>
                )}
              </Bar>
            );
          })}

          {/* Add vertical line */}
          {verticalLineValue && (
            <ReferenceLine
              x={verticalLineValue}
              stroke={_variablesTwelve.primaryTeal}
              strokeWidth={2}
              label={{
                position: "top",
                value: `Avg: ${verticalLineValue.toFixed(1)}`,
                offset: 10,
              }}
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default McBarChartVertical;
