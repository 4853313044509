import { PieChart, Pie, Cell, Tooltip, LabelList } from "recharts";
import { formatNumberFull } from "../../services/UtilsService";
import McDonutSideText from "./McDonutSideText";
import McDonutSideBars from "./McDonutSideBars";
import McDonutSideLegend from "./McDonutSideLegend";

import _variables, { _variablesTwoGrey } from "../../styles/_variables";

const McDonutChartSingle = ({
  data,
  side,
  sideTextRows,
  sideBars,
  sideBarsTitle,
  palette = _variablesTwoGrey,
  tooltipMeasureUnit = "",
}) => {
  const COLORS = Object.values(palette);

  const CustomTooltip = ({ active, payload, label }) => {
    if (
      active &&
      payload &&
      payload.length &&
      payload[0].name !== "Challenge failure rate"
    ) {
      return (
        <div className="custom-tooltip-container p-2">
          {payload.map((p, index) => {
            return (
              <div key={index + p.value + Math.random()}>
                <div
                  style={{
                    color:
                      p.payload.fill === _variablesTwoGrey.chartGrey
                        ? _variables.textBlack
                        : p.payload.fill,
                  }}
                  className="custom-tooltip-text"
                >{`${p.name}: ${formatNumberFull(
                  p.value
                )}${tooltipMeasureUnit}`}</div>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  const threshold = 5;

  return (
    <div className="row">
      <div className="col-12 col-xl-5">
        <PieChart width={200} height={200}>
          <Pie
            data={data}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={50}
            outerRadius={100}
            startAngle={90}
            endAngle={-270}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
            <LabelList
              data={data}
              dataKey="value"
              position="inside"
              fill="white"
              formatter={(value) => {
                if (value < threshold) {
                  return "";
                } else {
                  return value.toFixed(1) + "%";
                }
              }}
            />
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </div>
      <div className="col-12 col-xl-7 mt-2 mt-xl-0 d-flex pe-3">
        {side === "text" ? (
          <McDonutSideText rows={sideTextRows} />
        ) : side === "bars" ? (
          <McDonutSideBars bars={sideBars} title={sideBarsTitle} />
        ) : side === "legend" ? (
          <McDonutSideLegend data={data} palette={palette} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default McDonutChartSingle;
