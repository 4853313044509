import "./Tab.css";

const Tab = ({ title, active, onClick }) => {
  return (
    <div
      className={
        "tab-container text-center py-2 mt-2 mt-lg-0 cursor-pointer " +
        (active ? "active" : "")
      }
      onClick={onClick}
    >
      <b>{title}</b>
    </div>
  );
};

export default Tab;
