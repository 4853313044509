import { PulseLoader, BounceLoader, BarLoader } from "react-spinners";
import _variables from "../../styles/_variables";

// https://www.npmjs.com/package/react-spinners
const Loader = ({ color = _variables.primaryOrange, type = "PulseLoader" }) => {
  return type === "PulseLoader" ? (
    <PulseLoader color={color} speedMultiplier={0.6} />
  ) : type === "BounceLoader" ? (
    <BounceLoader color={color} />
  ) : type === "BarLoader" ? (
    <BarLoader color={color} />
  ) : (
    <></>
  );
};

export default Loader;
