import axios from "axios";

const FetchService = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
      "Content-Type": "application/json",
      "X-XSS-Protection": "X-XSS-Protection: 1; mode=block",
      "X-Frame-Options": "SAMEORIGIN",
      "Strict-Transport-Security": "max-age=10000000",
      "Content-Security-Policy":
        "default-src 'self'; img-src https://*; child-src 'none';",
      "Referrer-Policy": "no-referrer",
      "X-Content-Type-Options": "nosniff",
    },
  };

  let instance = axios.create(defaultOptions);

  return instance;
};

export default FetchService();
