import LayoutTwoColumns from "./LayoutTwoColumns";
import MultiSelect from "../Select/MultiSelect";
import TooltipInfo from "../TooltipInfo/TooltipInfo";

const LayoutGeographyFilter = ({
  options,
  tooltipOverall,
  tooltipCountry,
  selected,
  onChange,
}) => {
  return (
    <LayoutTwoColumns
      leftContent={
        <div className="justify-content-center align-items-end h-100 d-none d-lg-flex">
          <div className="me-2">
            <b>Overall performance</b>
          </div>
          <TooltipInfo content={tooltipOverall} />
        </div>
      }
      rightContent={
        <>
          <div className="mb-lg-3">
            <MultiSelect
              select={{
                placeholder: "Select country",
                options: options,
                isSearchable: true,
                isMulti: true,
                isClearable: true,
                value: selected,
                onChange: onChange,
              }}
            />
          </div>
          <div className="justify-content-center align-items-center d-none d-lg-flex">
            <div className="me-2">
              <b>Country performance</b>
            </div>
            <TooltipInfo content={tooltipCountry} />
          </div>
        </>
      }
      type={"geography"}
    />
  );
};

export default LayoutGeographyFilter;
