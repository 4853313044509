export const AcquirersTerms = () => (
  <>
    <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="Generator" content="Microsoft Word 15 (filtered)" />
    <style
      dangerouslySetInnerHTML={{
        __html:
          '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:Wingdings;\n\tpanose-1:5 0 0 0 0 0 0 0 0 0;}\n@font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Calibri;\n\tpanose-1:2 15 5 2 2 2 4 3 2 4;}\n@font-face\n\t{font-family:"Open Sans";\n\tpanose-1:2 11 6 6 3 5 4 2 2 4;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:8.0pt;\n\tmargin-left:0in;\n\tline-height:107%;\n\tfont-size:11.0pt;\n\tfont-family:"Calibri",sans-serif;}\nspan.msoIns\n\t{mso-style-name:"";\n\ttext-decoration:underline;\n\tcolor:teal;}\n.MsoChpDefault\n\t{font-family:"Calibri",sans-serif;}\n.MsoPapDefault\n\t{margin-bottom:8.0pt;\n\tline-height:107%;}\n /* Page Definitions */\n @page WordSection1\n\t{size:595.3pt 841.9pt;\n\tmargin:70.85pt 56.7pt 56.7pt 56.7pt;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0in;}\nul\n\t{margin-bottom:0in;}\n-->\n',
      }}
    />
    <div className="WordSection1" style={{ padding: "50px" }}>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "12.0pt",
          textAlign: "justify",
          lineHeight: "24.0pt",
          background: "#F8F7F5",
        }}
      >
        <span
          style={{
            fontSize: "18.0pt",
            fontFamily: '"Open Sans",sans-serif',
            color: "black",
          }}
        >
          Terms &amp; Conditions
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <b>
          <span
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Times New Roman",serif',
            }}
          >
            MERCHANT INSIGHTS MASTERCARD PLATFORM TERMS AND CONDITIONS FOR
            ACQUIRERS
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          THESE MERCHANT INSIGHTS MASTERCARD PLATFORM TERMS AND CONDITIONS (the
          “Terms and Conditions”) govern the use by you and your company, on
          behalf of which you have the authority to enter into these Terms and
          Conditions (“Client”) of the Merchant Insights Mastercard (“Platform”)
          provided by Mastercard Europe SA (“Mastercard”) through a web-based
          platform. This Platform is hosted by BID Company Srl on behalf of
          Mastercard.{" "}
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          These Terms and Conditions shall govern Client’s access to and use of
          the Platform, including any software, data, reports, performance model
          or other information or programs accessed, downloaded or created
          through use of the Platform (“Program”) solely for the internal
          business purposes of the Client’s business location(s) (the
          “Registered Location”). Client’s use of the Platform is also governed
          by a separate&nbsp;
        </span>
        <span lang="IT">
          <a href="https://www.mastercard.us/en-us/vision/corp-responsibility/commitment-to-privacy/privacy.html">
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Times New Roman",serif',
                color: "#007BFF",
              }}
            >
              Global Privacy Notice
            </span>
          </a>
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          , the terms of which are located at the bottom of the Platform.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          By using this Platform, setting up access, or purchasing and/or using
          the Program, Client signifies its acceptance of these Terms and
          Conditions.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          Mastercard has the right to revise these Terms and Conditions, at any
          time by posting the revised Terms and Conditions on the Platform and
          providing Client with notice of such revised Terms and Conditions. All
          changes are effective immediately when Mastercard posts them to the
          Platform. Client’s continued use of the Platform, the Platform, the
          Program and any of the reports, after any changes to these Terms and
          Conditions have been posted, shall be deemed Client’s acceptance of
          those revisions.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          The Platform will allow Client to view insights and analysis on
          authenticated e-commerce performance. These Terms and Conditions will
          apply to Client’s use of the Platform and reports at all times, during
          subscription periods, trial periods or otherwise.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <b>
          <span
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Times New Roman",serif',
            }}
          >
            1. Limited License and Restrictions on Use
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          A. Mastercard hereby grants to authorized user(s) of Client a
          personal, limited, non-exclusive right to use and access the Program,
          including the Platform, the underlying data and information contained
          in the Platform, and any Mastercard Technologies (as defined below),
          solely in the country of the Registered Location for its internal
          business purposes and in compliance with all applicable laws and
          reputable business practices, without the right to assign, transfer or
          sublicense the Platform and/or reports, the underlying data and
          information contained in the Platform and/or reports, and any
          Mastercard Technologies in any way, and any such attempt to assign,
          transfer or sublicense will be null and void. Client shall not create
          any derivative works of or from the Platform and/or reports. Client
          agrees not to reverse engineer, decompile or disassemble any report or
          Mastercard Technologies, whether directly or indirectly, save as
          expressly permitted under applicable law, and Client may not rent,
          lease, lend, resell, or host for or on behalf of third parties any
          report, instance or sample.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          B. Client shall not combine any data from the Platform and/or reports
          with any content (i) in any manner that states or suggests that
          Mastercard or any report is endorsing, sponsoring or affiliated with
          such content or related products or services, (ii) making any
          recommendations or providing advice as to any securities or securities
          markets or (iii) to create a commercially available product, index or
          similar item, if the Platform and/or report or Mastercard is mentioned
          publicly as a source for any data included therein. In addition,
          Client acknowledges and agrees that (i) use of the Platform and/or
          report is at Client’s sole determination and risk, (ii) Mastercard
          does not provide investment or financial advice or recommendations
          regarding securities, (iii) the Platform and/or report is not
          investment advice, and (iv) Client should not rely upon the Platform
          and/or report in making investment or other financial decisions.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <b>
          <span
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Times New Roman",serif',
            }}
          >
            2. Client Responsibilities and Indemnification
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          A. Client represents and warrants to Mastercard that it is authorized
          to agree to these Terms and Conditions and to receive, access,
          download and use the data for the Registered Location, and all such
          data and Client’s use of such data will not violate applicable law or
          any third party rights.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          B. Client shall indemnify, defend and hold harmless Mastercard, its
          employees, officers, agents, representatives, affiliates, and
          contractors from and against any claims, demands, loss, damage or
          expense (including reasonable attorneys’ fees) with respect to any and
          all claims arising out of or relating to: (i) Client’s breach of these
          Terms and Conditions or any representation, warranty or covenant
          herein; or (ii) Client’s use of the Program or reports.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          C. Client shall solely be responsible for the management of and access
          to the Platform through the Program and shall safeguard and maintain
          confidential login information, including user IDs, passwords, and
          other secure identification mechanisms, whether provided or created
          through direct user account creation, email, or instructions provided
          to administrators, sales agents, and customer service representatives.
          Client shall not disclose or share its login information with any
          other person. In addition, Client shall be responsible for all user
          activity within the Program related to Client’s use of the Platform.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <b>
          <span
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Times New Roman",serif',
            }}
          >
            3. Use of the Platform
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          The Platform includes copyrighted and copyrightable materials, and
          materials protected by intellectual property rights, including,
          without limitation, the Mastercard trademark, logo, design, text,
          graphics, forms and any other applicable materials, including the
          selection and arrangement of such elements, and is owned by Mastercard
          or its licensors. In addition, the entire Platform is copyrighted as a
          collective work under applicable copyright laws. Client shall not use,
          frame or utilize framing techniques to enclose the Platform, or any
          individual element or materials within the Platform or create a link
          to the Platform accessing such elements or materials, including
          without limitation, Mastercard’s trademarks, logos or other
          proprietary information (including any reports, the content of any
          text, or the layout and design of any page or form contained on a
          page) without Mastercard’s prior written consent.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          In addition, Client shall not:
        </span>
      </p>
      <ul type="disc">
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", lineHeight: "normal" }}
        >
          <span
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Times New Roman",serif',
            }}
          >
            use any circumvention tools, metatags or any other “hidden text”
            utilizing a Mastercard name, trademark, URL, or product name without
            Mastercard’s prior written consent;
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", lineHeight: "normal" }}
        >
          <span
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Times New Roman",serif',
            }}
          >
            robotically or otherwise automatically harvest, scrape, extract,
            copy, access or collect any information or data from the Platform;
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", lineHeight: "normal" }}
        >
          <span
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Times New Roman",serif',
            }}
          >
            use the Platform to impersonate any person or entity, or otherwise
            misrepresent its affiliation with a person or entity;
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", lineHeight: "normal" }}
        >
          <span
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Times New Roman",serif',
            }}
          >
            use the Platform for any commercial purpose or the benefit of any
            third party or any manner not permitted by the licenses granted
            herein;
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", lineHeight: "normal" }}
        >
          <span
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Times New Roman",serif',
            }}
          >
            use the Platform, Platform or reports for fraudulent purposes; or
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{ textAlign: "justify", lineHeight: "normal" }}
        >
          <span
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Times New Roman",serif',
            }}
          >
            interfere with or disrupt the Platform, Platform or servers or
            networks connected to or operated with the Platform.
          </span>
        </li>
      </ul>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          MASTERCARD RETAINS THE RIGHT TO DENY ACCESS TO THE WEBSITE TO ANYONE
          WITHOUT NOTICE AT ITS COMPLETE DISCRETION FOR ANY REASON, INCLUDING
          FOR VIOLATION OF ANY OF THESE TERMS AND CONDITIONS, INCLUDING ANY USE
          RIGHTS.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <b>
          <span
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Times New Roman",serif',
            }}
          >
            4. Payment, Fees, and Taxes.
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          Where applicable, unless otherwise agreed to with Mastercard, Client
          shall pay the subscription fee (“Fee”) in advance (i.e., prior to
          receiving access to the Platform) on a monthly, quarterly, or annual
          basis (as selected by Client during the registration process) for the
          subscription as agreed in writing with Mastercard. Client will be
          responsible for any taxes that may be levied on the provision of
          products or services except for taxes levied on Mastercard’s income.
          Where applicable, Mastercard reserves the right to suspend access to
          the Platform or terminate Client’s subscription in the event Client
          does not pay the Fee for its subscription when it becomes due.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <b>
          <span
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Times New Roman",serif',
            }}
          >
            5. Ownership
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          Except for the limited license granted above, the parties acknowledge
          and agree that Mastercard owns all right, title and interest in and to
          the Program, including&nbsp;the Platform, Platform, reports, the
          underlying data and information contained in the reports, Mastercard’s
          trademarks, logos and product names, and any Mastercard Technologies
          contained therein. “Mastercard&nbsp;Technologies” means the
          proprietary capabilities, technologies, logic, methodologies, source
          code, know-how, intellectual property, data and/or algorithms that
          Mastercard uses to perform its services or provide reports under these
          Terms and Conditions. Client will not directly or indirectly, or
          publicly or otherwise question or contest the validity and/or value
          of, or disparage the Platform, Platform, reports, Mastercard
          Technologies or Mastercard’s rights therein.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <b>
          <span
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Times New Roman",serif',
            }}
          >
            6. Limitations of Liability; Disclaimer
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          A. Nothing in these Terms and Conditions shall limit or exclude either
          party's liability: (a) to the extent it is caused by fraud, dishonesty
          or deceit; (b) for death or personal injury caused by its (or its
          agents') negligence; or (c) which may not otherwise be limited or
          excluded under applicable law.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          B. Except as set out in clause 6A and to the extent permitted by
          applicable law, the maximum aggregate liability of Mastercard arising
          under these Terms and Conditions is limited to the greater of the
          amount Client is required to pay for the subscription giving rise to
          that liability during the prior 12 months or Five Hundred Euro ({"{"}
          currencySymbol{"}"} 500.00 EUR). These limitations apply regardless of
          whether the liability is based on breach of contract, tort (including
          without limit negligence), strict liability, breach of warranties,
          failure of essential purpose, or any other legal theory.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          C. NOTWITHSTANDING ANYTHING TO THE CONTRARY HEREIN (EXCEPT AS SET OUT
          IN CLAUSE 6A), TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
          SHALL MASTERCARD BE LIABLE TO CLIENT UNDER ANY LEGAL THEORY, INCLUDING
          WITHOUT LIMITATION, TORT (INCLUDING WITHOUT LIMIT NEGLIGENCE),
          CONTRACT, OR STRICT LIABILITY, FOR ANY SPECIAL, INDIRECT, INCIDENTAL,
          CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES, FOR LOSS OF PROFITS,
          GOODWILL, OR ECONOMIC LOSS, REGARDLESS OF WHETHER MASTERCARD KNEW OR
          SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          D. THE PLATFORM, THE REPORTS AND CONTENT ARE INTENDED SOLELY AS A
          RESEARCH TOOL FOR INFORMATIONAL PURPOSES AND NOT AS INVESTMENT ADVICE
          OR RECOMMENDATIONS FOR ANY PARTICULAR ACTION OR INVESTMENT AND SHOULD
          NOT BE RELIED UPON, IN WHOLE OR IN PART, AS THE BASIS FOR
          DECISION-MAKING OR INVESTMENT PURPOSES. THE PLATFORM AND REPORTS ARE
          PROVIDED “AS-IS,” AND MASTERCARD MAKES NO WARRANTIES, EXPRESS OR
          IMPLIED, CONCERNING THE PLATFORM AND REPORTS. WITHOUT LIMITATION,
          MASTERCARD HEREBY EXCLUDES AND DISCLAIMS ALL EXPRESS OR IMPLIED
          WARRANTIES AND CONDITIONS TO THE EXTENT PERMITTED BY LAW, INCLUDING
          BUT NOT LIMITED TO, ANY IMPLIED WARRANTY, CONDITION OR TERM OF
          MERCHANTABILITY, SATISFACTORY QUALITY, ACCURACY, COURSE OF DEALING,
          NON-INFRINGEMENT, FITNESS FOR A PARTICULAR PURPOSE OR ANY WARRANTY
          THAT THE DATA WILL BE ERROR-FREE.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <b>
          <span
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Times New Roman",serif',
            }}
          >
            7. Confidentiality
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          A. “Confidential Information” means any information, Platform,
          reports, insights, Mastercard Technologies, data, plans, materials,
          processes, methodologies and concepts, in whatever form embodied
          (e.g., oral, written, electronic) owned by Mastercard, no matter how
          or by what party such information, materials, or concepts were
          transmitted, where such information is transmitted or collected in the
          course of the performance of these Terms and Conditions. Confidential
          Information shall not include any information which: (i) is already in
          the public domain at the time of disclosure (through a source other
          than Client); (ii) enters the public domain after disclosure through
          no fault of Client; (iii) is already known to Client at the time of
          disclosure (as evidenced by written records); (iv) was independently
          developed by Client without use of or reference to any Confidential
          Information (as evidenced by written records); or (v) is subsequently
          disclosed to Client by third parties having no obligation of
          confidentiality to Mastercard.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          B. During the term of a subscription and for seven (7) years
          thereafter, Client shall: (i) use Confidential Information only in
          connection with these Terms and Conditions; (ii) not copy any
          Confidential Information; (iii) not disclose Confidential Information
          to any third party; and (iv) limit dissemination of Confidential
          Information to employees with a “need to know”.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          C. Privacy. Mastercard respects your privacy and the&nbsp;
        </span>
        <span lang="IT">
          <a href="https://www.mastercard.us/en-us/vision/corp-responsibility/commitment-to-privacy/privacy.html">
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Times New Roman",serif',
                color: "#007BFF",
              }}
            >
              Global Privacy Notice
            </span>
          </a>
        </span>
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          &nbsp;explains our privacy practices in detail.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <b>
          <span
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Times New Roman",serif',
            }}
          >
            8. Force Majeure
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          Mastercard shall not be liable for loss or damage or be deemed to be
          in default under these Terms and Conditions if its failure to perform
          its obligations results from or is attributable to any act of God,
          natural disaster, fire, strike, embargo, war, threat of terrorism,
          insurrection, strike, riot or other cause or circumstance beyond
          Mastercard’s reasonable control.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <b>
          <span
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Times New Roman",serif',
            }}
          >
            9. Links to Third-Party Websites
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          This Platform may link directly or indirectly to external websites
          operated by third parties. The availability or content of such third
          party websites are not controlled by Mastercard and Mastercard has no
          control over these third party websites. In particular, Mastercard
          does not adopt the contents of such third party websites as its own
          and does not assume any responsibility or liability for the contents
          or safety of such third party websites.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <b>
          <span
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Times New Roman",serif',
            }}
          >
            10. Miscellaneous
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          No waiver by Mastercard of any terms within these Terms and Conditions
          shall be effective unless expressly made in a signed writing. If any
          provision of these Terms and Conditions is held by a court of
          competent jurisdiction to be unenforceable or invalid in any respect,
          this shall not affect any other provision, and these Terms and
          Conditions shall be automatically construed as if such unenforceable
          or invalid provisions had never been a part of these Terms and
          Conditions. All representations and warranties, and all commitments
          (i) to indemnify, defend, hold harmless, or (ii) relating to
          confidentiality, limitations on liability, rights and obligations upon
          termination, and jurisdiction, and any other provision by its nature
          that is meant to survive, shall survive any termination of these Terms
          and Conditions. These Terms and Conditions may not be assigned,
          transferred or sublicensed by Client and any attempted assignment,
          transfer or sublicense by Client shall be null and void. These Terms
          and Conditions and the respective rights and obligations of the
          parties hereto (and any non-contractual obligations arising therefrom)
          shall be governed by the laws Belgium without reference to its
          conflict-of-laws or similar provisions. The courts located in
          Brussels, Belgium shall have the exclusive jurisdiction over any
          actions or disputes related to these Terms and Conditions.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "9.0pt",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Times New Roman",serif' }}
        >
          Except as provided herein, these Terms and Conditions, and any other
          contract for the Platform, constitute the entire agreement between
          Client and Mastercard pertaining to their subject matter. Neither
          party shall have any remedy in respect of any untrue statement made by
          the other upon which that party relied in entering into these Terms
          and Conditions (unless such untrue statement was made fraudulently)
          and that party's only remedies shall be for breach of contract as
          provided in these Terms and Conditions.
        </span>
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        &nbsp;
      </p>
    </div>
  </>
);
