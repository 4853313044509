import Input from "./Input";
import "./Omnisearch.css";

const Omnisearch = ({
  styleClass = "",
  id = `omnisearch${Math.random()}`,
  placeholder = "Search",
  label = "Search",
  value,
  onChange = () => {},
  onClick = () => {},
}) => {
  return (
    <div className={"input-group " + styleClass}>
      <span className="input-group-text icon-group">
        <i className="bi bi-search"></i>
      </span>
      <Input
        type="text"
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        label={label}
        onClick={onClick}
        styleClassInput={"bl-0"}
      />
    </div>
  );
};

export default Omnisearch;
