import Select from "../Select/Select";
import TooltipInfo from "../TooltipInfo/TooltipInfo";

const LayoutFilter = ({
  options,
  tooltip,
  onChange,
  filterName,
  filterPlaceholder,
}) => {
  return (
    <div>
      <div className="d-flex align-items-center justify-content-start gap-1">
        <div>
          <b style={{ whiteSpace: "nowrap" }}>{filterName}</b>
        </div>
        {tooltip && <TooltipInfo content={tooltip} />}
        <Select
          options={options}
          onChange={onChange}
          placeholder={filterPlaceholder}
        />
      </div>
    </div>
  );
};

export default LayoutFilter;
