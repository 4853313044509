import Kpi from "./Kpi";
import KpiProgressChart from "./KpiProgressChart";
import { _variablesTwoOranges } from "../../styles/_variables";

const KpiColumn = ({ kpis }) => {
  return (
    <div>
      {kpis ? (
        kpis.map((kpi, index) => {
          return (
            <div
              className="mb-3 mx-auto w-80"
              key={index + "kpi" + Math.random()}
            >
              <Kpi
                leftContent={kpi.leftContent}
                rightContent={kpi.righContent}
                color={kpi.color}
                autofit={kpi.autofit}
              />
            </div>
          );
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export default KpiColumn;
