import LayoutTwoColumns from "./LayoutTwoColumns";
import Select from "../Select/Select";
import TooltipInfo from "../TooltipInfo/TooltipInfo";

const LayoutBenchmarkFilter = ({
  options,
  tooltipMerchant,
  tooltipPeer,
  onChange,
}) => {
  return (
    <LayoutTwoColumns
      leftContent={
        <div className="justify-content-center align-items-end h-100 d-none d-lg-flex">
          <div className="me-2">
            <b>Merchant</b>
          </div>
          <TooltipInfo content={tooltipMerchant} />
        </div>
      }
      rightContent={
        <>
          <div className="mb-lg-3">
            <Select
              options={options}
              onChange={onChange}
              placeholder="All countries"
              styleClass="w-100"
            />
          </div>
          <div className="justify-content-center align-items-center d-none d-lg-flex">
            <div className="me-2">
              <b>Peer</b>
            </div>
            <TooltipInfo content={tooltipPeer} />
          </div>
        </>
      }
      type={"benchmark"}
    />
  );
};

export default LayoutBenchmarkFilter;
