import { useState } from "react";
import "./Select.css";

const StatefulSelect = ({
  onChange = () => {},
  options = [
    { value: "1", text: "Option 1" },
    { value: "2", text: "Option 2" },
  ],
  styleClass = "",
  placeholder = "Select",
}) => {
  const [selectedValue, setSelectedValue] = useState("");
  return (
    <select
      className={"form-select select-width " + styleClass}
      onChange={(e) => {
        onChange(e.target.value);
        setSelectedValue(e.target.value);
      }}
      value={selectedValue}
    >
      <option value="0" disabled hidden>
        {placeholder}
      </option>
      {options.map((opt, index) => {
        return (
          <option value={opt.value} key={index + "selectoption"}>
            {opt.text}
          </option>
        );
      })}
    </select>
  );
};

export default StatefulSelect;
