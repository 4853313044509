import { useState } from "react";
import "./Switcher.css";

const Switcher = ({
  pages = [
    { title: "Switch 1", onClick: () => {} },
    { title: "Switch 2", onClick: () => {} },
  ],
  styleClass = "",
  isShared = false,
  sharedState,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const switchActiveTab = (index, onClick) => {
    setActiveTab(index);
    onClick();
  };
  // for components that share the same filter state; the onClick function for each tab should always update the state in parent component so that active tab can be updated
  if (isShared && sharedState) {
    return (
      <div
        className={
          "switcher-container d-flex justify-content-around align-items-center mb-3 " +
          styleClass
        }
      >
        {pages.map((tab, index) => (
          <div
            key={index}
            className={
              "switcher-button " + (sharedState === tab.value ? "active" : "")
            }
            onClick={() => {
              tab.onClick();
            }}
          >
            {tab.title}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div
      className={
        "switcher-container d-flex justify-content-around align-items-center mb-3 " +
        styleClass
      }
    >
      {pages.map((tab, index) => (
        <div
          key={index}
          className={"switcher-button " + (activeTab === index ? "active" : "")}
          onClick={() => switchActiveTab(index, tab.onClick)}
        >
          {tab.title}
        </div>
      ))}
    </div>
  );
};

export default Switcher;
