import Select from "../Select/Select";
import TooltipInfo from "../TooltipInfo/TooltipInfo";

const LayoutIssuersSingleFilter = ({
  options,
  tooltipMerchant,
  tooltipAcquirer,
  onChange,
  hideNames = false,
}) => {
  // If hideNames is true, replace the names with "Acquirer1", "Acquirer2", etc.
  let acquirerIndex = 1;
  const customOptions = hideNames
    ? options.map((option) => {
        let newText = option.text;
        if (!option.text.includes("All") && !option.text.includes("Others")) {
          newText = `Acquirer ${acquirerIndex}`;
          acquirerIndex++;
        }
        return {
          ...option,
          text: newText,
        };
      })
    : options;

  return (
    <div>
      <div className="d-flex align-items-center justify-content-center gap-1">
        <b>Acquirer</b>
        {/* <TooltipInfo content={tooltipAcquirer} /> */}
        <Select
          options={customOptions}
          onChange={onChange}
          placeholder="All Acquirers"
          styleClass="w-100"
        />
      </div>
    </div>
  );
};

export default LayoutIssuersSingleFilter;
