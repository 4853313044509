import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  LabelList,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { formatNumberFull } from "../../services/UtilsService";
import {
  _variablesTwoOranges,
  _variablesTrafficLight,
} from "../../styles/_variables";

export default function McBarChartStacked({
  data,
  dataGuide,
  labels,
  height = 600,
  tooltipMeasureUnit = "",
  marginLeft = false,
  marginRight = false,
  threshold_percentage = 0.15,
}) {
  const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
          fontSize={10}
        >
          {formatNumberFull(payload.value)} bps
        </text>
      </g>
    );
  };

  const CustomizedAxisTickSmall = ({ x, y, stroke, payload }) => {
    return (
      <g>
        <text x={x} y={y} dy={4} textAnchor="end" fill="#666" fontSize={12}>
          {payload.value}
        </text>
      </g>
    );
  };

  // custom tooltip for chart
  const customTooltipLayout = (
    active,
    payload,
    label,
    tooltipMeasureUnit,
    additionalData = undefined,
    autoFormatData = true
  ) => {
    return (
      <div className="custom-tooltip-container p-2">
        <div className="mb-1">{label}</div>
        {payload.map((p, index) => {
          return (
            <div key={index + p.value + Math.random()}>
              <div
                style={{
                  color:
                    p.payload.avg === false
                      ? _variablesTwoOranges.primaryOrangeLight
                      : p.color,
                }}
                className="custom-tooltip-text"
              >
                {additionalData && additionalData.length > 0 ? (
                  <div className="mb-1">
                    <b>{p.name}: </b>
                    {p.value}
                  </div>
                ) : (
                  <>
                    {p.name}: {p.value}
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return customTooltipLayout(active, payload, label, tooltipMeasureUnit);
    }

    return null;
  };

  const renderCustomizedLabel = ({ x, y, width, height, value }) => {
    let xPosition = 30 + x + width;

    return (
      <g>
        <text
          x={xPosition > 400 ? 400 : xPosition}
          y={y + height / 2}
          fill="#141413"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {formatNumberFull(value)}
        </text>
      </g>
    );
  };

  const maxBarValue = Math.max(
    ...dataGuide.barsData.map((bar) =>
      Math.max(...data.map((item) => item[bar.firstBar] + item[bar.secondBar]))
    )
  );
  const threshold = maxBarValue * threshold_percentage;

  return (
    <ResponsiveContainer width="80%" height={height}>
      <BarChart
        width="100%"
        height="100%"
        data={data}
        layout="vertical"
        margin={{
          top: 0,
          right: marginRight ? 90 : 30,
          left: marginLeft ? 60 : 0,
          bottom: 60,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          scale="sqrt"
          interval={0}
          ticks={[0, 5, 20, 50]}
          tick={<CustomizedAxisTick />}
        >
          {labels ? (
            labels[0] && (
              <Label value={labels[0]} offset={-40} position={"top"} />
            )
          ) : (
            <></>
          )}
        </XAxis>
        <YAxis
          type="category"
          dataKey={dataGuide.y}
          tick={<CustomizedAxisTickSmall />}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend verticalAlign="top" height={60} />

        {dataGuide.barsData.map((bar, index) => {
          return (
            <>
              <Bar
                dataKey="rate_3ds"
                stackId="a"
                fill={_variablesTwoOranges.primaryOrange}
                name="3DS"
              >
                <LabelList
                  dataKey={"rate_3ds"}
                  position={"inside"}
                  fill={"#ffffff"}
                  formatter={(value) => {
                    if (value < threshold || value === 0) {
                      return "";
                    } else {
                      return value.toFixed(1);
                    }
                  }}
                />
              </Bar>
              <Bar
                dataKey="rate_non_3ds"
                stackId="a"
                fill={_variablesTwoOranges.primaryOrangeLight}
                name="Non 3DS"
              >
                <LabelList
                  dataKey="rate_non_3ds"
                  position={"inside"}
                  fill={"#ffffff"}
                  formatter={(value) => {
                    if (value < threshold || value === 0) {
                      return "";
                    } else {
                      return value.toFixed(1);
                    }
                  }}
                />
              </Bar>
            </>
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
}
