import { DownloadIcon } from "lucide-react";
import Button from "../../components/Button/Button";
import saveAs from "file-saver";
import {
  convertToCSV,
  convertToCSVBarChart,
  convertToCSVDeclineReasons,
  convertToCSVPerformance,
  convertToCSVYoY,
  convertToCSVDecline,
  convertToCSVPie,
} from "../../services/ExportService";
import { format } from "date-fns";
import * as XLSX from "xlsx";

const Download = ({
  data,
  chartName,
  section,
  graphType,
  productType,
  timeRange,
  acquirer,
  issuer,
  subchannels,
  ticketRange,
  tokenization,
  program,
  country,
  measure,
  cardSplit,
  label,
  field1,
  value1,
  field2,
  value2,
  field3,
  value3,
  field4,
  value4,
  field5,
  value5,
  field6,
  value6,
  fields,
  disabled,
}) => {
  const downloadCSV = (data) => {
    let csvData;
    switch (graphType) {
      case "barChart":
        csvData = convertToCSVBarChart(
          data,
          productType,
          timeRange,
          country,
          acquirer,
          label,
          field1,
          value1,
          field2,
          value2,
          field3,
          value3,
          field4,
          value4,
          field5,
          value5,
          field6,
          value6
        );
        break;
      case "declineReasons":
        csvData = convertToCSVDeclineReasons(
          data,
          productType,
          timeRange,
          label,
          subchannels,
          ticketRange,
          tokenization,
          program,
          fields
        );
        break;
      case "performance":
        csvData = convertToCSVPerformance(
          data,
          productType,
          timeRange,
          country,
          fields
        );
        break;
      case "YoY":
        csvData = convertToCSVYoY(
          data,
          productType,
          timeRange,
          label,
          country,
          measure,
          cardSplit,
          fields
        );
        break;
      case "decline":
        csvData = convertToCSVDecline(data, label, timeRange, fields);
        break;
      case "pie":
        csvData = convertToCSVPie(
          data,
          label,
          timeRange,
          productType,
          country,
          acquirer,
          issuer,
          measure,
          fields,
        );
        break;
      default:
        csvData = convertToCSV(data);
    }

    const worksheet = XLSX.utils.aoa_to_sheet(csvData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    const currentTime = format(new Date(), "yyyy-MM-dd_HH-mm");
    const filename = `${section}_${chartName}_${currentTime}.xlsx`;
    saveAs(blob, filename);
  };

  return (
    <Button
      text=""
      className="ml-auto"
      style={{ minWidth: "60px", height: "40px" }}
      size="icon"
      variant="outline"
      onClick={() => downloadCSV(data)}
      disabled={disabled}
    >
      <DownloadIcon className="sm h-4 w-4" />
    </Button>
  );
};

export default Download;
