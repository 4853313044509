import LayoutTwoColumns from "./LayoutTwoColumns";
import Select from "../Select/Select";
import TooltipInfo from "../TooltipInfo/TooltipInfo";

const LayoutAcquirerFilter = ({
  options,
  tooltipMerchant,
  tooltipAcquirer,
  onChange,
  hideNames = false,
}) => {
  // If hideNames is true, replace the names with "Acquirer1", "Acquirer2", etc.
  let acquirerIndex = 1;
  const customOptions = hideNames
    ? options.map((option) => {
        let newText = option.text;
        if (!option.text.includes("All") && !option.text.includes("Others")) {
          newText = `Acquirer ${acquirerIndex}`;
          acquirerIndex++;
        }
        return {
          ...option,
          text: newText,
        };
      })
    : options;

  return (
    <LayoutTwoColumns
      leftContent={
        <div className="justify-content-center align-items-end h-100 d-none d-lg-flex">
          <div className="me-2">
            <b>Merchant data overall</b>
          </div>
          <TooltipInfo content={tooltipMerchant} />
        </div>
      }
      rightContent={
        <>
          <div className="mb-lg-3">
            <Select
              options={customOptions}
              onChange={onChange}
              placeholder="Select acquirer"
              styleClass="w-100"
            />
          </div>
          <div className="justify-content-center align-items-center d-none d-lg-flex">
            <div className="me-2">
              <b>Merchant data for selected acquirer</b>
            </div>
            <TooltipInfo content={tooltipAcquirer} />
          </div>
        </>
      }
      type={"acquirer"}
    />
  );
};

export default LayoutAcquirerFilter;
