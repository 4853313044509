import "./FilterBox.css";

const FilterBox = ({ children }) => {
  return (
    <div className="filter-container px-3 py-2 mb-3 d-flex justify-content-start align-items-center">
      <div className="me-4">
        <b>Filters</b>
      </div>
      <div className="d-flex justify-content-start align-items-center">
        {children}
      </div>
    </div>
  );
};

export default FilterBox;
