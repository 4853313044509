import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import _variables from "../../styles/_variables";

// https://github.com/jedwatson/react-select
const InputSelect = ({
  select = {
    placeholder: "Select",
    options: [
      {
        value: 1,
        label: "Option 1",
      },
      {
        value: 2,
        label: "Option 2",
      },
    ],
    isSearchable: true,
    isMulti: true,
    isClearable: true,
    value: null,
    onChange: () => {},
  },
  creatable = false,
}) => {
  const commonProps = {
    placeholder: select.placeholder,
    value: select.value,
    onChange: select.onChange,
    options: select.options,
    isSearchable: select.isSearchable,
    isMulti: select.isMulti,
    isClearable: select.isClearable,
    styles: {
      control: (provided, state) => ({
        ...provided,
        minHeight: "58px",
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled
          ? undefined
          : state.isSelected
          ? _variables.primaryOrangeLight
          : state.isFocused
          ? _variables.sidebarGrey
          : undefined,
      }),
    },
    theme: (theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary: _variables.lineGrey, //border on focus + background selected option
        primary75: _variables.textBlack, //?
        primary50: _variables.sidebarGrey, //flash when option is selected
        primary25: _variables.sidebarGrey, //hover on option
        danger: _variables.white, //hover on pill 'x' icon
        dangerLight: _variables.primaryOrangeLight, //hover on pill 'x' icon background
        neutral5: _variables.textBlack, //?
        neutral10: _variables.primaryOrangeLight, //pills background
        neutral20: _variables.lineGrey, //border when not on focus + icons when not on focus
        neutral30: _variables.lineGrey, //border on hover
        neutral40: _variables.textBlack, //icons on hover
        neutral60: _variables.textBlack, //icons on focus
        neutral70: _variables.textBlack, //?
        neutral80: _variables.textBlack, //all text + hover on icons on focus
        neutral90: _variables.textBlack, //?
      },
    }),
  };

  return creatable ? (
    <CreatableSelect isMulti {...commonProps} />
  ) : (
    <Select {...commonProps} />
  );
};

export default InputSelect;
