import { useState, useEffect, useContext } from "react";
import { AccountContext } from "../../services/AccountService";
import FetchService from "../../services/FetchService";
import {
  convertMonthLabelForApi,
  generateLastTwelveMonths,
  formatErrorForAlert,
  assembleBody,
} from "../../services/UtilsService";
import Container from "../../components/Container/Container";
import Title from "../../components/Title/Title";
import SmallTitle from "../../components/Title/SmallTitle";
import TimePeriod from "../../components/TimePeriod/TimePeriod";
import Refreshing from "../../components/Refreshing/Refreshing";
import Alert from "../../components/Alert/Alert";
import KpiRow from "../../components/Kpi/KpiRow";
import Switcher from "../../components/Switcher/Switcher";
import FilterBox from "../../components/FilterBox/FilterBox";
import Select from "../../components/Select/Select";
import LayoutTwoColumns from "../../components/Layout/LayoutTwoColumns";
import Table from "../../components/Table/Table";
import McBarChart from "../../components/McCharts/McBarChart";
import McBarChartVerticalDouble from "../../components/McCharts/McBarChartVerticalDouble";
import { _variablesThreeOrangesRate } from "../../styles/_variables";

const Analytics = () => {
  // global
  const { merchantName, sidebarExpanded } = useContext(AccountContext);
  const [alert, setAlert] = useState({});
  const [timePeriod, setTimePeriod] = useState([0, 11]);
  const [dateLabels, setDateLabels] = useState([]);

  // others
  const [merchants, setMerchants] = useState([
    { value: "0", text: "All merchants" },
  ]);
  const [selectedOption, setSelectedOption] = useState("0");

  // charts
  const [dataTotalUsers, setDataTotalUsers] = useState([]);
  const [isLoadingtotalUsers, setIsLoadingTotalUsers] = useState(true);
  const [dataUserUtilization, setDataUserUtilization] = useState([]);
  const [isLoadingUserUtilization, setIsLoadingUserUtilization] =
    useState(true);
  const [dataMerchantUtilization, setDataMerchantUtilization] = useState([]);
  const [isLoadingMerchantUtilization, setIsLoadingMerchantUtilization] =
    useState(true);
  const [dataTop5, setDataTop5] = useState([]);
  const [isLoadingTop5, setIsLoadingTop5] = useState(true);
  const [dataAvgAccess, setDataAvgAccess] = useState([]);
  const [isLoadingAvgAccess, setIsLoadingAvgAccess] = useState(true);

  // others
  const [switchedData, setSwitchedData] = useState([]);
  const [switchedDataGuide, setSwitchedDataGuide] = useState({
    x: "yearmonth",
    barsData: [{ dataName: "total_users", friendlyName: "Active users" }],
    rateData: {
      dataName: "rate",
      friendlyName: "Utilization rate",
    },
  });
  const [switchedLabels, setSwitchedLabels] = useState([
    "Users (#)",
    "Rate (%)",
  ]);
  const [switchedLastSelected, setSwitchedLastSelected] = useState("users");

  // get dates for timeperiod
  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear().toString().padStart(4, "0");
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setDateLabels(generateLastTwelveMonths(formattedDate));
  }, []);

  // get charts data based on timeperiod
  useEffect(() => {
    isLoadingAll();

    // wait some time for other changes
    const timeOut = setTimeout(() => {
      if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
        let body = assembleBody(
          merchantName,
          dateLabels[timePeriod[0]],
          dateLabels[timePeriod[1]]
        );

        getMerchants();

        getTotalUsers(body);
        getUserUtilization(body);
        getMerchantUtilization(body);
        getTop5(body);
        getAvgAccess(body);
      }
    }, 1500);

    return () => clearTimeout(timeOut);
  }, [timePeriod, dateLabels]);

  // update chart
  useEffect(() => {
    switchData(switchedLastSelected);
  }, [dataUserUtilization, dataMerchantUtilization]);

  const isLoadingAll = () => {
    setIsLoadingTotalUsers(true);
    setIsLoadingUserUtilization(true);
    setIsLoadingMerchantUtilization(true);
    setIsLoadingTop5(true);
    setIsLoadingAvgAccess(true);
  };

  const handleSelectedOption = (e) => {
    setSelectedOption(e);

    let body = {
      start_date: convertMonthLabelForApi(dateLabels[timePeriod[0]]),
      end_date: convertMonthLabelForApi(dateLabels[timePeriod[1]]),
    };

    if (e !== 0 && e !== "0") {
      body.merchant_name = e;
    }

    getUserUtilization(body);
    getMerchantUtilization(body);
  };

  const getMerchants = () => {
    FetchService.get("/api/admin/merchants")
      .then((res) => {
        let tempFormatted = [{ value: "0", text: "All merchants" }];
        res.data.forEach((el) => {
          if (el.active) {
            tempFormatted = [
              ...tempFormatted,
              {
                value: el.name,
                text: el.name,
              },
            ];
          }
        });
        setMerchants(tempFormatted);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  };

  const getTotalUsers = (body) => {
    setIsLoadingTotalUsers(true);

    FetchService.post("/api/analytics/total-users-merchants", body)
      .then((res) => {
        let temp = {};
        temp.merchants = res.data.find((el) => el.label === "merchants").value;
        temp.users = res.data.find((el) => el.label === "users").value;
        setDataTotalUsers(temp);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingTotalUsers(false));
  };

  const getUserUtilization = (body) => {
    setIsLoadingUserUtilization(true);

    FetchService.post("/api/analytics/users-utilization", body)
      .then((res) => {
        setDataUserUtilization(res.data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingUserUtilization(false));
  };

  const getMerchantUtilization = (body) => {
    setIsLoadingMerchantUtilization(true);

    FetchService.post("/api/analytics/merchants-utilization", body)
      .then((res) => {
        setDataMerchantUtilization(res.data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingMerchantUtilization(false));
  };

  const getTop5 = (body) => {
    setIsLoadingTop5(true);

    FetchService.post("/api/analytics/top-5-merchants", body)
      .then((res) => {
        setDataTop5(res.data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingTop5(false));
  };

  const getAvgAccess = (body) => {
    setIsLoadingAvgAccess(true);

    FetchService.post("/api/analytics/avg-access-by-merchants", body)
      .then((res) => {
        setDataAvgAccess(res.data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingAvgAccess(false));
  };

  const switchData = (type) => {
    setSwitchedLastSelected(type);
    setSwitchedData(
      type === "users"
        ? dataUserUtilization
        : type === "merchants"
        ? dataMerchantUtilization
        : []
    );
    setSwitchedDataGuide(
      type === "users"
        ? {
            x: "yearmonth",
            barsData: [
              { dataName: "total_users", friendlyName: "Active users" },
            ],
            rateData: {
              dataName: "rate",
              friendlyName: "Utilization rate",
            },
          }
        : type === "merchants"
        ? {
            x: "yearmonth",
            barsData: [
              { dataName: "total_merchant", friendlyName: "Active merchants" },
            ],
            rateData: {
              dataName: "rate",
              friendlyName: "Utilization rate",
            },
          }
        : []
    );
    setSwitchedLabels(
      type === "users"
        ? ["Users (#)", "Rate (%)"]
        : type === "merchants"
        ? ["Merchants (#)", "Rate (%)"]
        : []
    );
  };

  return (
    <Container>
      <Title>Analytics</Title>

      <Refreshing
        conditions={[
          isLoadingtotalUsers,
          isLoadingUserUtilization,
          isLoadingMerchantUtilization,
          isLoadingTop5,
          isLoadingAvgAccess,
        ]}
        marginLeft={sidebarExpanded}
      />

      <Alert
        styleClass={""}
        alertText={alert.message}
        styleColor={alert.messageType}
        onClick={setAlert}
        paddingLeft={sidebarExpanded}
      />

      <TimePeriod
        dateLabels={dateLabels}
        timePeriod={timePeriod}
        setTimePeriod={setTimePeriod}
      />

      <hr />

      <SmallTitle>Overall utilization</SmallTitle>
      <KpiRow
        kpis={[
          {
            leftContent: dataTotalUsers.users ? dataTotalUsers.users : 0,
            righContent: "Total user onboarded",
          },
          {
            leftContent: dataTotalUsers.merchants
              ? dataTotalUsers.merchants
              : 0,
            righContent: "Total merchants onboarded",
          },
        ]}
      />

      <hr />

      <SmallTitle>Utilization trend</SmallTitle>

      <div className="d-flex justify-content-between align-items-center">
        <div>
          <Switcher
            pages={[
              {
                title: "Users",
                onClick: () => {
                  switchData("users");
                },
              },
              {
                title: "Merchants",
                onClick: () => {
                  switchData("merchants");
                },
              },
            ]}
          />
        </div>

        <div>
          <FilterBox>
            <Select
              options={merchants}
              onChange={handleSelectedOption}
              placeholder="All merchants"
            />
          </FilterBox>
        </div>
      </div>

      <McBarChart
        data={switchedData.length > 0 ? switchedData : dataUserUtilization}
        dataGuide={switchedDataGuide}
        labels={switchedLabels}
        palette={_variablesThreeOrangesRate}
        autoFormatData={false}
      />

      <hr />

      <LayoutTwoColumns
        leftContent={
          <>
            <SmallTitle>Top 5 merchants by accesses</SmallTitle>
            <McBarChartVerticalDouble
              background={false}
              data={dataTop5}
              dataGuide={{
                y: "name",
                barsData: [
                  { dataName: "total_access", friendlyName: "Accesses" },
                  {
                    dataName: "total_active_users",
                    friendlyName: "Active users",
                  },
                ],
              }}
            />
          </>
        }
        rightContent={
          <>
            <SmallTitle>Average access per user</SmallTitle>
            <Table
              data={dataAvgAccess}
              header={["", "Average access per user"]}
            />
          </>
        }
      />
    </Container>
  );
};

export default Analytics;
