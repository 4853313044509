import Select from "../Select/Select";
import TooltipInfo from "../TooltipInfo/TooltipInfo";

const LayoutCardFilter = ({
  options,
  tooltipMerchant,
  tooltipAcquirer,
  onChange,
}) => {
  return (
    <div>
      <div className="d-flex align-items-center justify-content-start gap-3">
        <div className="d-flex gap-1">
          <b style={{whiteSpace: "nowrap"}}>Product Type</b>
        {/* <TooltipInfo content={tooltipAcquirer} /> */}
        </div>
        <Select
          options={options}
          onChange={onChange}
          placeholder="All products"
        />
      </div>
    </div>
  );
};

export default LayoutCardFilter;
