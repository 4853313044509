import { useState, useEffect, useContext } from "react";
import { AccountContext } from "../../services/AccountService";
import FetchService from "../../services/FetchService";
import {
  generateLastTwelveMonths,
  formatNumberFull,
  formatNumberRound,
  formatErrorForAlert,
  sortTicketRanges,
  assembleBody,
  titleCase,
  compareAndAddDummy,
  mergeData,
  padArray,
  ukMerchants,
} from "../../services/UtilsService";
import TimePeriod from "../../components/TimePeriod/TimePeriod";
import Refreshing from "../../components/Refreshing/Refreshing";
import LayoutTwoColumns from "../../components/Layout/LayoutTwoColumns";
import SmallTitleFlex from "../../components/Title/SmallTitleFlex";
import LayoutPageContainer from "../../components/Layout/LayoutPageContainer";
import FilterBoxSticky from "../../components/FilterBox/FilterBoxSticky";
import LayoutAcquirersFilter from "../../components/Layout/LayoutAcquirersFilter";
import KpiProgressChart from "../../components/Kpi/KpiProgressChart";
import McDonutChartDouble from "../../components/McCharts/McDonutChartDouble";
import McDonutChartSingle from "../../components/McCharts/McDonutChartSingle";
import McBarChart from "../../components/McCharts/McBarChart";
import McTreemapChart from "../../components/McCharts/McTreemapChart";
import KpiColumn from "../../components/Kpi/KpiColumn";
import Title from "../../components/Title/Title";
import McDonutSideLegend from "../../components/McCharts/McDonutSideLegend";
import McProgressChartBlock from "../../components/McCharts/McProgressChartBlock";
import {
  _variablesTwoOranges,
  _variablesThreeOrangesRate,
  _variablesSix,
} from "../../styles/_variables";
import Modal from "../../components/Modal/Modal";
import TableAG from "../../components/Table/TableAG";
import Button from "../../components/Button/Button";
import SmallTitle from "../../components/Title/SmallTitle";
import Download from "../../components/Button/Download";

const colsDef = [
  { field: "auth_method", headerName: "Authentication Method", flex: 1 },
  {
    headerName: "Challenge Code",
    marryChildren: true,
    children: [
      {
        field: "percent_zerofour",
        headerName: "04",
        flex: 1,
      },
      { field: "percent_zerofive", headerName: "05", flex: 1 },
    ],
  },
  {
    field: "total_trx",
    headerName: "Total Transaction Count",
    flex: 1,
    valueFormatter: (params) => Number(params.value).toLocaleString("en-US"),
  },
];

const AuthenticationAcquirers = () => {
  // general
  const {
    merchantName,
    sidebarExpanded,
    acquirerName,
    isMultiAcquirer,
    timePeriod,
    setTimePeriod,
    dashboardVersion,
    userEmail,
    currency,
  } = useContext(AccountContext);
  const [alert, setAlert] = useState({});
  // const [timePeriod, setTimePeriod] = useState([0, 11]);
  const [dateLabels, setDateLabels] = useState([]);

  // acquirers
  const [acquirers, setAcquirers] = useState([]);
  const [selectedAcquirers, setSelectedAcquirers] = useState(null);

  // charts
  const [isLoadingKpis, setIsLoadingKpis] = useState(true);
  const [dataKpis, setDataKpis] = useState([]);
  const [isLoadingFrictionless, setIsLoadingFrictionless] = useState(true);
  const [dataFrictionless, setDataFrictionless] = useState([]);
  const [sideTextRowsFrictionless, setSideTextRowsFrictionless] = useState([]);
  const [isLoadingChallengeSuccess, setIsLoadingChallengeSuccess] =
    useState(true);
  const [dataChallengeSuccess, setDataChallengeSuccess] = useState([]);
  const [sideTextRowsChallengeSuccess, setSideTextRowsChallengeSuccess] =
    useState([]);
  const [isLoadingTransactionsDevice, setIsLoadingTransactionsDevice] =
    useState(true);
  const [dataTransactionsDevice, setDataTransactionsDevice] = useState([]);
  const [sideBarsTransactionsDevice, setSideBarsTransactionsDevice] = useState(
    []
  );
  const [
    isLoadingTransactionsTicketRange,
    setIsLoadingTransactionsTicketRange,
  ] = useState(true);
  const [dataTransactionsTicketRange, setDataTransactionsTicketRange] =
    useState([]);
  const [isLoadingDecline, setIsLoadingDecline] = useState(true);
  const [dataDecline, setDataDecline] = useState([]);
  const [listDeclineReasons, setListDeclineReasons] = useState([]);

  // dashboard version
  const dashboardVersion1 = dashboardVersion === 1 ? true : false;
  const dashboardVersion2 = dashboardVersion === 2 ? true : false;
  const currencySymbol = currency === 826 ? "£" : "€";

  // charts countries
  const [dataKpisAcquirers, setDataKpisAcquirers] = useState([]);
  const [dataFrictionlessAcquirers, setDataFrictionlessAcquirers] = useState(
    []
  );
  const [
    sideTextRowsFrictionlessAcquirers,
    setSideTextRowsFrictionlessAcquirers,
  ] = useState([]);
  const [dataChallengeSuccessAcquirers, setDataChallengeSuccessAcquirers] =
    useState([]);
  const [
    sideTextRowsChallengeSuccessAcquirers,
    setSideTextRowsChallengeSuccessAcquirers,
  ] = useState([]);
  const [dataTransactionsDeviceAcquirers, setDataTransactionsDeviceAcquirers] =
    useState([]);
  const [
    sideBarsTransactionsDeviceAcquirers,
    setSideBarsTransactionsDeviceAcquirers,
  ] = useState([]);
  const [
    dataTransactionsTicketRangeAcquirers,
    setDataTransactionsTicketRangeAcquirers,
  ] = useState([]);
  const [dataDeclineAcquirers, setDataDeclineAcquirers] = useState([]);

  // 3ds2 versions
  const [isLoading3ds2Versions, setIsLoading3ds2Versions] = useState(true);
  const [data3ds2Versions, setData3ds2Versions] = useState([]);
  const [data3ds2VersionsBarChart, setData3ds2VersionsBarChart] = useState([]);
  const [data3ds2VersionsAcquirers, setData3ds2VersionsAcquirers] = useState(
    []
  );
  const [
    data3ds2VersionsBarChartAcquirers,
    setData3ds2VersionsBarChartAcquirers,
  ] = useState([]);

  // auth methods
  const [isLoadingAuthMethods, setIsLoadingAuthMethods] = useState(true);
  const [dataAuthMethods, setDataAuthMethods] = useState([]);
  const [dataTopFiveAuthMethods, setDataTopFiveAuthMethods] = useState([]);
  const [dataAuthMethodsAcquirers, setDataAuthMethodsAcquirers] = useState([]);
  const [dataTopFiveAuthMethodsAcquirers, setDataTopFiveAuthMethodsAcquirers] =
    useState([]);
  // challenge code
  const [dataChallengeCode, setDataChallengeCode] = useState([]);
  const [dataChallengeCodeAcquirers, setDataChallengeCodeAcquirers] = useState(
    []
  );
  const [isLoadingChallengeCode, setIsLoadingChallengeCode] = useState();
  const [dataChallengeCodeForAuthMethod, setDataChallengeCodeForAuthMethod] =
    useState([]);
  const [
    dataChallengeCodeForAuthMethodAcquirers,
    setDataChallengeCodeForAuthMethodAcquirers,
  ] = useState([]);
  const [
    isLoadingChallengeCodeForAuthMethod,
    setIsLoadingChallengeCodeForAuthMethod,
  ] = useState();
  //api response
  const [apiDataAuthMethods, setApiDataAuthMethods] = useState([]);
  const [apiDataAuthMethodsAcquirers, setApiDataAuthMethodsAcquirers] =
    useState([]);
  //merged data
  const [mergedDataTicketRange, setMergedDataTicketRange] = useState([]);
  const [mergedDataDeclineReasons, setMergedDataDeclineReasons] = useState([]);
  const [mergedDataAuthMethods, setMergedDataAuthMethods] = useState([]);

  // get dates for timeperiod
  useEffect(() => {
    let body = {
      merchant_name: merchantName,
    };

    FetchService.post("/api/dashboard/dates/authe", body)
      .then((res) => {
        setDateLabels(generateLastTwelveMonths(res.data.max_date_customer));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  }, []);

  // get charts data based on timeperiod
  useEffect(() => {
    isLoadingAll();

    // wait some time for other changes
    const timeOut = setTimeout(() => {
      if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
        let body = assembleBody(
          merchantName,
          dateLabels[timePeriod[0]],
          dateLabels[timePeriod[1]],
          "",
          undefined,
          false,
          currency
        );
        body.product_type = "";

        // get acquirers for select
        getAcquirers(body);

        getKpis(body);
        getFrictionless(body);
        getChallengeSuccess(body);
        getTransactionsDevice(body);
        getTransactionsTicketRange(body);
        getDecline(body);
        get3ds2Versions(body);
        getAuthMethods(body);
        getChallengeCode(body);
        getChallengeCodeForAuthMethod(body);
      }
    }, 1500);

    return () => clearTimeout(timeOut);
  }, [timePeriod, dateLabels]);

  // get charts data based on acquirer
  useEffect(() => {
    if (selectedAcquirers) {
      let body = assembleBodyAcquirer();

      getKpis(body, true);
      getFrictionless(body, true);
      getChallengeSuccess(body, true);
      getTransactionsDevice(body, true);
      getTransactionsTicketRange(body, true);
      getDecline(body, true);
      get3ds2Versions(body, true);
      getAuthMethods(body, true);
      getChallengeCode(body, true);
      getChallengeCodeForAuthMethod(body, true);
    }
  }, [timePeriod, dateLabels, selectedAcquirers, acquirers]);

  const isLoadingAll = () => {
    setIsLoadingKpis(true);
    setIsLoadingFrictionless(true);
    setIsLoadingChallengeSuccess(true);
    setIsLoadingTransactionsDevice(true);
    setIsLoadingTransactionsTicketRange(true);
    setIsLoadingDecline(true);
    setIsLoading3ds2Versions(true);
    setIsLoadingAuthMethods(true);
    setIsLoadingChallengeCode(true);
    setIsLoadingChallengeCodeForAuthMethod(true);
  };

  const getAcquirers = (body) => {
    FetchService.post("/api/dashboard/acquirers/authe", body)
      .then((res) => {
        let tempAcquirers = res.data.acquirers.sort((a, b) =>
          a.localeCompare(b)
        );
        tempAcquirers = tempAcquirers.map((el) => {
          return {
            value: el,
            text: el,
          };
        });

        setAcquirers(tempAcquirers);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  };

  const assembleBodyAcquirer = () => {
    let body = assembleBody(
      merchantName,
      dateLabels[timePeriod[0]],
      dateLabels[timePeriod[1]],
      "",
      undefined,
      false,
      currency
    );
    body.product_type = "";

    if (selectedAcquirers !== 0 && selectedAcquirers !== "0") {
      body.acquirer_name = selectedAcquirers;
    }

    return body;
  };

  const handleSelectAcquirer = (e) => {
    setSelectedAcquirers(e);
  };

  const getKpis = (body, isAcquirers) => {
    setIsLoadingKpis(true);

    FetchService.post(`/api/dashboard/authe/acquirer/performance`, body)
      .then((res) => {
        isAcquirers ? setDataKpisAcquirers(res.data) : setDataKpis(res.data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingKpis(false));
  };

  const getFrictionless = (body, isAcquirers) => {
    setIsLoadingFrictionless(true);

    FetchService.post(
      `/api/dashboard/authe/acquirer/percentage-frictionless-penetration`,
      body
    )
      .then((res) => {
        let frictionless = res.data.frictionless ? res.data.frictionless : 0;
        let acquirer = res.data.acquirer_exemption
          ? res.data.acquirer_exemption
          : 0;
        let tempData = [
          [
            { name: "Frictionless transactions", value: frictionless },
            { name: "Challenge transactions", value: 100 - frictionless },
          ],
          [
            { name: "With Acquirer exemptions", value: acquirer },
            { name: "Without Acquirer exemptions", value: 100 - acquirer },
          ],
        ];
        let tempRows = [
          {
            leftContent: formatNumberFull(frictionless) + "%",
            rightContent: "Percentage of frictionless transactions",
            color: _variablesThreeOrangesRate.primaryOrange,
          },
          {
            leftContent: formatNumberFull(acquirer) + "%",
            rightContent: "Of which Acquirer exemptions",
            color: _variablesThreeOrangesRate.primaryOrangeLight,
          },
        ];

        if (isAcquirers) {
          setDataFrictionlessAcquirers(tempData);
          setSideTextRowsFrictionlessAcquirers(tempRows);
        } else {
          setDataFrictionless(tempData);
          setSideTextRowsFrictionless(tempRows);
        }
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingFrictionless(false));
  };

  const getChallengeSuccess = (body, isAcquirers) => {
    setIsLoadingChallengeSuccess(true);

    FetchService.post(
      `/api/dashboard/authe/acquirer/challenge-success-rate`,
      body
    )
      .then((res) => {
        let success = res.data.data ? res.data.data : 0;
        let tempData = [
          { name: "Challenge success rate", value: success },
          { name: "Challenge failure rate", value: 100 - success },
        ];
        let tempRows = [
          {
            leftContent: formatNumberFull(success) + "%",
            rightContent: "Challenge success rate",
            color: _variablesThreeOrangesRate.primaryOrange,
          },
        ];

        if (isAcquirers) {
          setDataChallengeSuccessAcquirers(tempData);
          setSideTextRowsChallengeSuccessAcquirers(tempRows);
        } else {
          setDataChallengeSuccess(tempData);
          setSideTextRowsChallengeSuccess(tempRows);
        }
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingChallengeSuccess(false));
  };

  const getTransactionsDevice = (body, isAcquirers) => {
    setIsLoadingTransactionsDevice(true);

    FetchService.post(
      `/api/dashboard/authe/acquirer/transactions-per-device`,
      body
    )
      .then((res) => {
        let tempData = [];
        let tempBars = [];

        let web = res.data.find((el) => el.label === "Browser-based");
        tempData.push({
          name: "Web",
          value: web ? web.value : "0",
        });
        tempBars.push({
          value: web ? web.auth_rate : "0",
          color: _variablesThreeOrangesRate.primaryOrange,
          text: "Web",
        });

        let app = res.data.find((el) => el.label === "App-based");
        tempData.push({
          name: "App",
          value: app ? app.value : "0",
        });
        tempBars.push({
          value: app ? app.auth_rate : "0",
          color: _variablesThreeOrangesRate.primaryOrangeLight,
          text: "App",
        });

        if (isAcquirers) {
          setDataTransactionsDeviceAcquirers(tempData);
          setSideBarsTransactionsDeviceAcquirers(tempBars);
        } else {
          setDataTransactionsDevice(tempData);
          setSideBarsTransactionsDevice(tempBars);
        }
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingTransactionsDevice(false));
  };

  const getTransactionsTicketRange = (body, isAcquirers) => {
    setIsLoadingTransactionsTicketRange(true);

    FetchService.post(
      `/api/dashboard/authe/acquirer/transactions-by-ticket-range`,
      body
    )
      .then((res) => {
        res.data.forEach((el) => {
          el.label = el.label + ` ${currencySymbol}`;
        });

        isAcquirers
          ? setDataTransactionsTicketRangeAcquirers(
              sortTicketRanges(res.data, currencySymbol)
            )
          : setDataTransactionsTicketRange(
              sortTicketRanges(res.data, currencySymbol)
            );
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingTransactionsTicketRange(false));
  };

  const getDecline = (body, isAcquirers) => {
    setIsLoadingDecline(true);

    FetchService.post(`/api/dashboard/authe/acquirer/decline-reasons`, body)
      .then((res) => {
        let temp = [];
        res.data.forEach((el) => {
          temp.push({
            name: el.label ? el.label : "Unknown",
            size: el.value ? el.value : 0,
            percent: formatNumberFull(el.percent ? el.percent : 0),
          });
        });

        let tempReasons = listDeclineReasons;
        res.data.forEach((reason) => {
          if (!tempReasons.includes(reason.label)) {
            tempReasons.push(reason.label);
          }
        });
        setListDeclineReasons(tempReasons);

        isAcquirers ? setDataDeclineAcquirers(temp) : setDataDecline(temp);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingDecline(false));
  };

  const getAuthMethods = (body, isAcquirers) => {
    setIsLoadingAuthMethods(true);
    FetchService.post(
      "/api/dashboard/authe/acquirer/authentication-methods",
      body
    )
      .then((res) => {
        let cleanRes = res.data;
        isAcquirers
          ? setApiDataAuthMethodsAcquirers(cleanRes)
          : setApiDataAuthMethods(cleanRes);

        let tempAuthMethods = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.trx_share ? el.trx_share : 0,
          };
        });
        let tempTopFiveAuthMethods = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.success_rate ? el.success_rate : 0,
          };
        });

        if (isAcquirers) {
          setDataAuthMethodsAcquirers(tempAuthMethods);
          setDataTopFiveAuthMethodsAcquirers(tempTopFiveAuthMethods);
        } else {
          setDataAuthMethods(tempAuthMethods);
          setDataTopFiveAuthMethods(tempTopFiveAuthMethods);
        }
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingAuthMethods(false));
  };

  const getChallengeCode = (body, isAcquirers) => {
    setIsLoadingChallengeCode(true);
    FetchService.post("/api/dashboard/authe/acquirer/challenge-code", body)
      .then((res) => {
        let tempData = res.data.map((el) => {
          return {
            label: el.label ? el.label : "",
            value: el.percent ? el.percent : 0,
          };
        });
        isAcquirers
          ? setDataChallengeCodeAcquirers(tempData)
          : setDataChallengeCode(tempData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingChallengeCode(false));
  };

  const getChallengeCodeForAuthMethod = (body, isAcquirers) => {
    setIsLoadingChallengeCodeForAuthMethod(true);
    FetchService.post(
      "/api/dashboard/authe/acquirer/challenge-code-for-auth-method",
      body
    )
      .then((res) => {
        let tempData = res.data;
        let groupedData = [];

        // group records with the same auth method to display them in the table
        for (let i = 0; i < tempData.length - 2; i += 1) {
          if (tempData[i].auth_method === tempData[i + 1].auth_method) {
            let percent_zerofour = "-";
            let percent_zerofive = "-";

            if (res.data[i].challenge_desc.substring(0, 2) === "04") {
              percent_zerofour = formatNumberRound(res.data[i].percent) + "%";
              percent_zerofive =
                formatNumberRound(res.data[i + 1].percent) + "%";
            } else if (res.data[i].challenge_desc.substring(0, 2) === "05") {
              percent_zerofour =
                formatNumberRound(res.data[i + 1].percent) + "%";
              percent_zerofive = formatNumberRound(res.data[i].percent) + "%";
            }
            groupedData.push({
              auth_method: res.data[i].auth_method,
              percent_zerofour: percent_zerofour,
              percent_zerofive: percent_zerofive,
              total_trx: formatNumberRound(res.data[i].total_trx),
            });
            // skip next record since you grouped it with the current one
            i += 1;
          }
          // in case the record has no matching 04 type or 05 type record
          else if (res.data[i].challenge_desc.substring(0, 2) === "04") {
            groupedData.push({
              auth_method: res.data[i].auth_method,
              percent_zerofour: formatNumberRound(res.data[i].percent) + "%",
              percent_zerofive: "-",
              total_trx: formatNumberRound(res.data[i].total_trx),
            });
          } else {
            groupedData.push({
              auth_method: res.data[i].auth_method,
              percent_zerofour: "-",
              percent_zerofive: formatNumberRound(res.data[i].percent) + "%",
              total_trx: formatNumberRound(res.data[i].total_trx),
            });
          }
        }

        groupedData.sort((a, b) => b.total_trx - a.total_trx);

        isAcquirers
          ? setDataChallengeCodeForAuthMethodAcquirers(groupedData)
          : setDataChallengeCodeForAuthMethod(groupedData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingChallengeCodeForAuthMethod(false));
  };

  const get3ds2Versions = (body, isAcquirers) => {
    setIsLoading3ds2Versions(true);
    FetchService.post("/api/dashboard/authe/acquirer/3ds2-versions", body)
      .then((res) => {
        let cleanRes = res.data.sort((a, b) =>
          a.label > b.label ? 1 : a.label < b.label ? -1 : 0
        );

        let temp3ds2Versions = cleanRes
          .filter((el) => el.trx_share >= 0.1)
          .map((el) => {
            return {
              name: el.label ? el.label : "",
              value: el.trx_share ? el.trx_share : 0,
            };
          });

        let temp3ds2VersionsBarChart = cleanRes
          .filter((el) => el.success_rate >= 0.1)
          .map((el) => {
            return {
              name: el.label ? el.label : "",
              value: el.success_rate ? el.success_rate : 0,
            };
          });

        if (isAcquirers) {
          setData3ds2VersionsAcquirers(temp3ds2Versions);
          setData3ds2VersionsBarChartAcquirers(temp3ds2VersionsBarChart);
        } else {
          setData3ds2Versions(temp3ds2Versions);
          setData3ds2VersionsBarChart(temp3ds2VersionsBarChart);
        }
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoading3ds2Versions(false));
  };

  let pages = [
    {
      title: "Overview",
      link: "/authentication/home",
    },
    { title: "Geography", link: "/authentication/geography" },
    { title: "Benchmark", link: "/authentication/benchmark" },
    {
      title: "Acquirers",
      link: "/authentication/acquirers",
      active: true,
    },
    dashboardVersion2 && { title: "Issuers", link: "/authentication/issuers" },
  ];

  if (!isMultiAcquirer && !acquirerName) {
    pages = pages.filter((el) => el.title !== "Acquirers");
  }

  useEffect(() => {
    setMergedDataTicketRange(
      mergeData(
        dataTransactionsTicketRange,
        dataTransactionsTicketRangeAcquirers,
        [
          { source: "value", target: "value_merchant" },
          { source: "auth_rate", target: "auth_rate_merchant" },
        ],
        [
          { source: "value", target: "value_acquirer" },
          { source: "auth_rate", target: "auth_rate_acquirer" },
        ],
        "label"
      )
    );
  }, [dataTransactionsTicketRange, dataTransactionsTicketRangeAcquirers]);

  useEffect(() => {
    setMergedDataDeclineReasons(
      mergeData(
        dataDecline,
        dataDeclineAcquirers,
        [
          { source: "size", target: "size_merchant" },
          { source: "percent", target: "percent_merchant" },
        ],
        [
          { source: "size", target: "size_acquirer" },
          { source: "percent", target: "percent_acquirer" },
        ],
        "name"
      )
    );
  }, [dataDecline, dataDeclineAcquirers]);

  useEffect(() => {
    setMergedDataAuthMethods(
      mergeData(
        apiDataAuthMethods,
        apiDataAuthMethodsAcquirers,
        [
          { source: "trx_share", target: "trx_share_merchant" },
          { source: "success_rate", target: "success_rate_merchant" },
        ],
        [
          { source: "trx_share", target: "trx_share_acquirer" },
          { source: "success_rate", target: "success_rate_acquirer" },
        ],
        "label"
      )
    );
  }, [apiDataAuthMethods, apiDataAuthMethodsAcquirers]);

  return (
    <LayoutPageContainer
      title={"Authentication"}
      pages={pages}
      footer="authe"
      alert={alert}
      setAlert={setAlert}
    >
      <Refreshing
        conditions={[
          isLoadingKpis,
          isLoadingFrictionless,
          isLoadingChallengeSuccess,
          isLoadingTransactionsDevice,
          isLoadingTransactionsTicketRange,
          isLoadingDecline,
          isLoading3ds2Versions,
          isLoadingAuthMethods,
          isLoadingChallengeCode,
          isLoadingChallengeCodeForAuthMethod,
        ]}
        marginLeft={sidebarExpanded}
      />

      <TimePeriod
        dateLabels={dateLabels}
        timePeriod={timePeriod}
        setTimePeriod={setTimePeriod}
      />

      <hr />
      <FilterBoxSticky>
        <LayoutAcquirersFilter
          tooltipMerchant={
            "Merchant performance includes the overall performances.<br /><br />" +
            "Authentication rate is calculated as: number of successful authentications divided by total transactions.<br /><br />" +
            "Average ticket is calculated as: volumes (spend) divided by transactions."
          }
          tooltipAcquirer={
            "Acquirer performance indicates the performances of selected Acquirer.<br /><br />" +
            "Authentication rate is calculated as: number of successful authentication divided by total transactions.<br /><br />" +
            "Average ticket is calculated as: volumes (spend) divided by transactions."
          }
          options={acquirers}
          onChange={handleSelectAcquirer}
          hideNames={userEmail === "demo@mastercard.it"}
        />
      </FilterBoxSticky>

      <LayoutTwoColumns
        split
        leftContent={
          <>
            <Title styleClass="d-block d-lg-none mb-3">Merchant</Title>
            <KpiColumn
              kpis={[
                {
                  leftContent: (
                    <KpiProgressChart
                      bar={{
                        value: dataKpis.auth_rate,
                        color: _variablesTwoOranges.primaryOrange,
                      }}
                    />
                  ),
                  righContent: "Authentication rate",
                  autofit: false,
                },
              ]}
            />
            {!ukMerchants.includes(merchantName) && (
              <KpiColumn
                kpis={[
                  {
                    leftContent:
                      (dataKpis.avg_ticket_ok
                        ? formatNumberFull(dataKpis.avg_ticket_ok)
                        : "0") + ` ${currencySymbol}`,
                    righContent: (
                      <div>
                        <b>Average ticket</b>
                        <br />
                        Authentication success
                      </div>
                    ),
                    color: _variablesTwoOranges.primaryOrange,
                  },
                  {
                    leftContent:
                      (dataKpis.avg_ticket_ko
                        ? formatNumberFull(dataKpis.avg_ticket_ko)
                        : "0") + ` ${currencySymbol}`,
                    righContent: (
                      <div>
                        <b>Average ticket</b>
                        <br />
                        Authentication failed
                      </div>
                    ),
                    color: _variablesTwoOranges.primaryOrangeLight,
                  },
                ]}
              />
            )}

            <hr />

            <div className="mb-5">
              <SmallTitleFlex
                title={"Frictionless penetration"}
                tooltip={
                  "Frictionless transactions do not request any action from client to be authenticated, because flagged as safe.<br /><br />" +
                  "Frictionless penetration measures the incidence of frictionless transactions on total ones, and which of them are flagged as safe by the Acquirer."
                }
              />
              <McDonutChartDouble
                data={dataFrictionless}
                sideTextRows={sideTextRowsFrictionless}
                tooltipMeasureUnit="%"
              />
            </div>

            <div className="mb-5">
              <SmallTitleFlex
                title={"Challenge success"}
                tooltip={
                  "Challenge transactions request an action from client to be authenticated, via Strong Customer Authentication (SCA).<br /><br />" +
                  "Challenge success rate measures the number of successful challenge authentication transactions on total number of challenged transactions."
                }
              />
              <div style={{ marginTop: "4rem" }}>
                <McProgressChartBlock
                  data={padArray(
                    dataChallengeSuccess,
                    dataChallengeSuccessAcquirers
                  )}
                  palette={_variablesTwoOranges}
                />
              </div>
            </div>

            {dashboardVersion2 && (
              <>
                <div className="mb-5">
                  <SmallTitleFlex
                    title={"3DS Versions Share"}
                    tooltip={
                      "The shares of card not present transactions split by 3DS versions"
                    }
                  />
                  <div
                    style={{ display: "flex" }}
                    className="flex-lg-column flex-xl-row"
                  >
                    <McDonutChartSingle
                      data={data3ds2Versions}
                      palette={_variablesTwoOranges}
                      tooltipMeasureUnit="%"
                    />
                    <div
                      style={{
                        marginTop: "",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                    >
                      <b>Transaction Share</b>
                      <McDonutSideLegend
                        data={data3ds2Versions}
                        palette={_variablesTwoOranges}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <SmallTitleFlex
                    title={"3DS Versions Success Rate"}
                    tooltip={
                      "Success Rate is defined as: number of successfully authenticated transactions over total transactions. Success rate split by different 3DS versions"
                    }
                  />
                  <McProgressChartBlock
                    data={data3ds2VersionsBarChart}
                    palette={_variablesTwoOranges}
                  />
                </div>
              </>
            )}

            <div className="mb-5">
              <SmallTitleFlex
                title={"Transaction share per device"}
                tooltip={
                  "Card not present transactions split by device: Web or App Authentication rate is defined as number of successfully authenticated transactions over total transactions"
                }
              />
              <McDonutChartSingle
                data={dataTransactionsDevice}
                side={"bars"}
                sideBars={sideBarsTransactionsDevice}
                sideBarsTitle={"Authentication rate"}
                palette={_variablesTwoOranges}
                tooltipMeasureUnit="%"
              />
            </div>

            {!ukMerchants.includes(merchantName) && (
              <div className="mb-5">
                <SmallTitleFlex
                  title={"Transactions count by ticket range"}
                  tooltip={
                    "Percentage of transactions per each ticket range.<br /><br />" +
                    "The green line indicates the Authentication rate per each ticket range calculated as: number of successful authentications divided by total transactions."
                  }
                />
                <McBarChart
                  data={dataTransactionsTicketRange}
                  dataGuide={{
                    x: "label",
                    barsData: [
                      {
                        dataName: "value",
                        friendlyName:
                          "Percentage of transactions by ticket size",
                      },
                    ],
                    rateData: {
                      dataName: "auth_rate",
                      friendlyName: "Authentication rate",
                    },
                  }}
                  labels={["Percentage (%)", "Rate (%)"]}
                  palette={_variablesThreeOrangesRate}
                  tooltipMeasureUnit="%"
                  rotateTicks
                  labelsPosition="top"
                  labelColor="black"
                />
              </div>
            )}

            <SmallTitleFlex
              title={"Failure Reasons"}
              tooltip={
                "Incidence of each decline reason on total number of failed authentications (card not present channel only).<br /><br />" +
                "Area of each rectangle indicates the magnitude of each decline reason."
              }
            />

            <McTreemapChart
              data={dataDecline}
              listDeclineReasons={listDeclineReasons}
            />

            {dashboardVersion2 && (
              <>
                <hr style={{ marginBottom: "5.6rem" }} />
                <div>
                  <SmallTitleFlex
                    title={"Transaction share of authentication methods"}
                    tooltip={
                      "Transaction share by different authentication methods"
                    }
                  />
                  <div
                    style={{ display: "flex" }}
                    className="flex-lg-column flex-xl-row"
                  >
                    <McDonutChartSingle
                      data={dataAuthMethods}
                      palette={_variablesSix}
                      tooltipMeasureUnit="%"
                    />
                    <div
                      style={{
                        marginTop: "",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                    >
                      <McDonutSideLegend
                        data={dataAuthMethods}
                        palette={_variablesSix}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="mt-4"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <SmallTitleFlex
                    title={
                      "Authentication Rate for top 5 authentication methods"
                    }
                    tooltip={
                      "Top 5 authentication methods sorted in descending order of total authentications.<br /><br />" +
                      "Authentication rate is defined as number of successfully authenticated transactions over total number of transactions."
                    }
                  />
                  <McProgressChartBlock
                    data={dataTopFiveAuthMethods}
                    palette={_variablesSix}
                  />
                  <Button
                    text="Auth method performance by challenge code"
                    style={{
                      fontSize: "12pt",
                      padding: "0.5rem",
                      marginTop: "2rem",
                      width: "15rem",
                      //marginLeft:"6rem",
                    }}
                    dataBsToggle="modal"
                    dataBsTarget={"authMethodModal"}
                  />
                </div>
              </>
            )}

            {dashboardVersion2 && (
              <Modal
                dataBsTarget="authMethodModal"
                title={<b>Top 5 Authentication Methods by Challenge Code</b>}
                modalBody={
                  <div
                    style={{
                      width: "100%",
                      height: "29rem",
                      display: "flex",
                      gap: "8rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2rem",
                      }}
                    >
                      <McDonutChartSingle
                        data={dataChallengeCode}
                        palette={_variablesTwoOranges}
                        tooltipMeasureUnit="%"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <b>Transaction Failed due to ACS Timeout</b>
                        <McDonutSideLegend
                          data={dataChallengeCode.map((el) => {
                            return { name: el.label, value: el.value };
                          })}
                          palette={_variablesTwoOranges}
                        />
                      </div>
                    </div>
                    <TableAG
                      colDefs={colsDef}
                      rowData={dataChallengeCodeForAuthMethod.slice(0, 5)}
                    />
                  </div>
                }
              >
                <Download
                  section="AutheAcquirers"
                  chartName="Authentication Methods by Challenge Code"
                  data={dataChallengeCodeForAuthMethod}
                  graphType="pie"
                  label={"Authentication Method"}
                  acquirer={selectedAcquirers}
                  timeRange={
                    dateLabels[timePeriod[0]] +
                    " - " +
                    dateLabels[timePeriod[1]]
                  }
                  fields={[
                    { field: "Percent 05", value: "percent_zerofive" },
                    { field: "Percent 04", value: "percent_zerofour" },
                    { field: "Total", value: "total_trx" },
                  ]}
                  iconSize="sm"
                  className="h-8 w-8"
                />
              </Modal>
            )}
          </>
        }
        rightContent={
          <>
            <Title styleClass="d-block d-lg-none mb-3">Peer</Title>
            <KpiColumn
              kpis={[
                {
                  leftContent: (
                    <KpiProgressChart
                      bar={{
                        value: dataKpisAcquirers.auth_rate,
                        color: _variablesTwoOranges.primaryOrange,
                      }}
                    />
                  ),
                  righContent: "Authentication rate",
                  autofit: false,
                },
              ]}
            />
            {!ukMerchants.includes(merchantName) && (
              <KpiColumn
                kpis={[
                  {
                    leftContent:
                      (dataKpisAcquirers.avg_ticket_ok
                        ? formatNumberFull(dataKpisAcquirers.avg_ticket_ok)
                        : "0") + ` ${currencySymbol}`,
                    righContent: (
                      <div>
                        <b>Average ticket</b>
                        <br />
                        Authentication success
                      </div>
                    ),
                    color: _variablesTwoOranges.primaryOrange,
                  },
                  {
                    leftContent:
                      (dataKpisAcquirers.avg_ticket_ko
                        ? formatNumberFull(dataKpisAcquirers.avg_ticket_ko)
                        : "0") + ` ${currencySymbol}`,
                    righContent: (
                      <div>
                        <b>Average ticket</b>
                        <br />
                        Authentication failed
                      </div>
                    ),
                    color: _variablesTwoOranges.primaryOrangeLight,
                  },
                ]}
              />
            )}

            <hr />

            <div className="mb-5">
              <SmallTitleFlex
                title={"Frictionless penetration"}
                tooltip={
                  "Frictionless transactions do not request any action from client to be authenticated, because flagged as safe.<br /><br />" +
                  "Frictionless penetration measures the incidence of frictionless transactions on total ones, and which of them are flagged as safe by the Acquirer."
                }
              />
              <McDonutChartDouble
                data={dataFrictionlessAcquirers}
                sideTextRows={sideTextRowsFrictionlessAcquirers}
                tooltipMeasureUnit="%"
              />
            </div>

            <div className="mb-5">
              <SmallTitleFlex
                title={"Challenge success"}
                tooltip={
                  "Challenge transactions request an action from client to be authenticated, via Strong Customer Authentication (SCA).<br /><br />" +
                  "Challenge success rate measures the number of successful challenge authentication transactions on total number of challenged transactions."
                }
              />
              <div style={{ marginTop: "4rem" }}>
                <McProgressChartBlock
                  data={padArray(
                    dataChallengeSuccessAcquirers,
                    dataChallengeSuccess
                  )}
                  palette={_variablesTwoOranges}
                />
              </div>
            </div>

            {dashboardVersion2 && (
              <>
                <div className="mb-5">
                  <SmallTitleFlex
                    title={"3DS Versions Share"}
                    tooltip={
                      "The shares of card not present transactions split by 3DS versions"
                    }
                  />
                  <div
                    style={{ display: "flex" }}
                    className="flex-lg-column flex-xl-row"
                  >
                    <McDonutChartSingle
                      data={data3ds2VersionsAcquirers}
                      palette={_variablesTwoOranges}
                      tooltipMeasureUnit="%"
                    />
                    <div
                      style={{
                        marginTop: "",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                    >
                      {data3ds2VersionsAcquirers.length > 0 && (
                        <b>Transaction Share</b>
                      )}
                      <McDonutSideLegend
                        data={data3ds2VersionsAcquirers}
                        palette={_variablesTwoOranges}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <SmallTitleFlex
                    title={"3DS Versions Success Rate"}
                    tooltip={
                      "Success Rate is defined as: number of successfully authenticated transactions over total transactions. Success rate split by different 3DS versions"
                    }
                  />
                  <McProgressChartBlock
                    data={padArray(
                      data3ds2VersionsBarChartAcquirers,
                      data3ds2VersionsBarChart
                    )}
                    palette={_variablesTwoOranges}
                  />
                </div>
              </>
            )}

            <div className="mb-5">
              <SmallTitleFlex
                title={"Transaction share per device"}
                tooltip={
                  "Card not present transactions split by device: Web or App Authentication rate is defined as number of successfully authenticated transactions over total transactions"
                }
              />
              <McDonutChartSingle
                data={dataTransactionsDeviceAcquirers}
                side={"bars"}
                sideBars={sideBarsTransactionsDeviceAcquirers}
                sideBarsTitle={"Authentication rate"}
                palette={_variablesTwoOranges}
                tooltipMeasureUnit="%"
              />
            </div>

            {!ukMerchants.includes(merchantName) && (
              <div className="mb-5">
                <div className="d-flex justify-content-between">
                  <SmallTitleFlex
                    title={"Transactions count by ticket range"}
                    tooltip={
                      "Percentage of transactions per each ticket range.<br /><br />" +
                      "The green line indicates the Authentication rate per each ticket range calculated as: number of successful authentications divided by total transactions."
                    }
                  />
                  <Download
                    disabled={selectedAcquirers === null}
                    section="AutheAcquirers"
                    chartName="Transactions count by ticket range"
                    data={mergedDataTicketRange}
                    graphType="barChart"
                    label={"Ticket Range"}
                    acquirer={selectedAcquirers}
                    timeRange={
                      dateLabels[timePeriod[0]] +
                      " - " +
                      dateLabels[timePeriod[1]]
                    }
                    field1={"Merchant Value (%)"}
                    field2={`Acquirer Value (%)`}
                    field3={"Merchant Auth Rate (%)"}
                    field4={`Acquirer Auth Rate (%)`}
                    value1={"value_merchant"}
                    value2={"value_acquirer"}
                    value3={"auth_rate_merchant"}
                    value4={"auth_rate_acquirer"}
                    iconSize="sm"
                    className="h-8 w-8"
                  />
                </div>
                <McBarChart
                  data={dataTransactionsTicketRangeAcquirers}
                  dataGuide={{
                    x: "label",
                    barsData: [
                      {
                        dataName: "value",
                        friendlyName:
                          "Percentage of transactions by ticket size",
                      },
                    ],
                    rateData: {
                      dataName: "auth_rate",
                      friendlyName: "Authentication rate",
                    },
                  }}
                  labels={["Percentage (%)", "Rate (%)"]}
                  palette={_variablesThreeOrangesRate}
                  tooltipMeasureUnit="%"
                  rotateTicks
                  labelsPosition="top"
                  labelColor="black"
                />
              </div>
            )}

            <div className="d-flex justify-content-between">
              <SmallTitleFlex
                title={"Failure Reasons"}
                tooltip={
                  "Incidence of each decline reason on total number of failed authentications (card not present channel only).<br /><br />" +
                  "Area of each rectangle indicates the magnitude of each decline reason."
                }
              />
              <Download
                disabled={selectedAcquirers === null}
                section="AutheAcquirers"
                chartName="FailureReasons"
                data={mergedDataDeclineReasons}
                graphType="pie"
                label={"Failure Reason"}
                acquirer={selectedAcquirers}
                timeRange={
                  dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
                }
                fields={[
                  { field: "Size Merchant", value: "size_merchant" },
                  { field: "Size Acquirer", value: "size_acquirer" },
                  { field: "Percent Merchant (%)", value: "percent_merchant" },
                  { field: "Percent Acquirer (%)", value: "percent_acquirer" },
                ]}
                iconSize="sm"
                className="h-8 w-8"
              />
            </div>
            <McTreemapChart
              data={dataDeclineAcquirers}
              listDeclineReasons={listDeclineReasons}
            />

            {dashboardVersion2 && (
              <>
                <hr style={{ marginBottom: "2rem" }} />
                <div>
                  <div
                    className="d-flex justify-content-end"
                    style={{ marginBottom: "1rem" }}
                  >
                    <Download
                      disabled={selectedAcquirers === null}
                      section="AutheAcquirers"
                      chartName="AuthMethod"
                      data={mergedDataAuthMethods}
                      graphType="pie"
                      label={"Auth Method"}
                      acquirer={selectedAcquirers}
                      timeRange={
                        dateLabels[timePeriod[0]] +
                        " - " +
                        dateLabels[timePeriod[1]]
                      }
                      fields={[
                        {
                          field: "Transaction Share Merchant (%)",
                          value: "trx_share_merchant",
                        },
                        {
                          field: "Transaction Share Acquirer (%)",
                          value: "trx_share_acquirer",
                        },
                        {
                          field: "Success Rate Merchant",
                          value: "success_rate_merchant",
                        },
                        {
                          field: "Success Rate Acquirer",
                          value: "success_rate_acquirer",
                        },
                      ]}
                      iconSize="sm"
                      className="h-8 w-8"
                    />
                  </div>
                  <SmallTitleFlex
                    title={"Transaction share of authentication methods"}
                    tooltip={
                      "Transaction share by different authentication methods"
                    }
                  />
                  <div
                    style={{ display: "flex" }}
                    className="flex-lg-column flex-xl-row"
                  >
                    <McDonutChartSingle
                      data={compareAndAddDummy(
                        dataAuthMethodsAcquirers,
                        dataAuthMethods,
                        "name",
                        "value"
                      )}
                      palette={_variablesSix}
                      tooltipMeasureUnit="%"
                    />
                    <div
                      style={{
                        marginTop: "",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                    >
                      {dataAuthMethodsAcquirers.length > 0 && (
                        <McDonutSideLegend
                          data={compareAndAddDummy(
                            dataAuthMethodsAcquirers,
                            dataAuthMethods,
                            "name",
                            "value"
                          )}
                          palette={_variablesSix}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="mt-4"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <SmallTitleFlex
                    title={
                      "Authentication Rate for top 5 authentication methods"
                    }
                    tooltip={
                      "Top 5 authentication methods sorted in descending order of total authentications.<br /><br />" +
                      "Authentication rate is defined as number of successfully authenticated transactions over total number of transactions."
                    }
                  />
                  <McProgressChartBlock
                    data={
                      selectedAcquirers
                        ? compareAndAddDummy(
                            dataTopFiveAuthMethodsAcquirers,
                            dataTopFiveAuthMethods,
                            "name",
                            "value"
                          )
                        : padArray(
                            dataTopFiveAuthMethodsAcquirers,
                            dataTopFiveAuthMethods
                          )
                    }
                    palette={_variablesSix}
                  />
                  {dataChallengeCodeAcquirers.length > 0 ? (
                    <Button
                      text="Auth method performance by challenge code"
                      style={{
                        fontSize: "12pt",
                        padding: "0.5rem",
                        marginTop: "2rem",
                        width: "15rem",
                        //marginLeft:"6rem",
                      }}
                      dataBsToggle="modal"
                      dataBsTarget={"authMethodModalAcquirers"}
                    />
                  ) : (
                    <div
                      style={{
                        fontSize: "12pt",
                        padding: "0.5rem",
                        marginTop: "2rem",
                        width: "15rem",
                        height: "4.15rem",
                        //marginLeft:"6rem",
                      }}
                    ></div>
                  )}
                </div>
              </>
            )}

            {dashboardVersion2 && (
              <Modal
                dataBsTarget="authMethodModalAcquirers"
                title={<b>Top 5 Authentication Methods by Challenge Code</b>}
                modalBody={
                  <div
                    style={{
                      width: "100%",
                      height: "29rem",
                      display: "flex",
                      gap: "8rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2rem",
                      }}
                    >
                      <McDonutChartSingle
                        data={dataChallengeCodeAcquirers}
                        palette={_variablesTwoOranges}
                        tooltipMeasureUnit="%"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <b>Transaction Failed due to ACS Timeout</b>
                        <McDonutSideLegend
                          data={dataChallengeCodeAcquirers.map((el) => {
                            return { name: el.label, value: el.value };
                          })}
                          palette={_variablesTwoOranges}
                        />
                      </div>
                    </div>
                    <TableAG
                      colDefs={colsDef}
                      rowData={dataChallengeCodeForAuthMethodAcquirers.slice(
                        0,
                        5
                      )}
                    />
                  </div>
                }
              >
                <Download
                  section="AutheAcquirers"
                  chartName="Authentication Methods by Challenge Code"
                  data={dataChallengeCodeForAuthMethodAcquirers}
                  graphType="pie"
                  label={"Authentication Method"}
                  acquirer={selectedAcquirers}
                  timeRange={
                    dateLabels[timePeriod[0]] +
                    " - " +
                    dateLabels[timePeriod[1]]
                  }
                  fields={[
                    { field: "Percent 05", value: "percent_zerofive" },
                    { field: "Percent 04", value: "percent_zerofour" },
                    { field: "Total", value: "total_trx" },
                  ]}
                  iconSize="sm"
                  className="h-8 w-8"
                />
              </Modal>
            )}
          </>
        }
      />
    </LayoutPageContainer>
  );
};

export default AuthenticationAcquirers;
