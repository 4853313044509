import { useEffect, useRef } from "react";
import Input from "../../components/Input/Input";
import InputPassword from "../../components/Input/InputPassword";
import Button from "../../components/Button/Button";
import Loadable from "../../components/Loader/Loadable";

const DefaultLogin = ({
  loginEmail,
  setLoginEmail,
  loginPassword,
  setLoginPassword,
  isLoadingButton,
  handleLogin,
  setIsForgotPassword,
}) => {
  const btnLogin = useRef();

  // listener for enter key
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        btnLogin.current.click();
      }
    };

    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <>
      <Input
        styleClass="mb-2"
        type="email"
        id="loginEmail"
        placeholder="name@example.com"
        value={loginEmail}
        onChange={(e) => {
          setLoginEmail(e.target.value);
        }}
        label={"Email"}
      />

      <InputPassword
        styleClass="mb-1"
        type="password"
        id="loginPassword"
        placeholder="password"
        value={loginPassword}
        onChange={(e) => {
          setLoginPassword(e.target.value);
        }}
        label="Password"
      />

      <div className="text-end mb-4">
        <div
          className="font-medium white-link"
          onClick={() => {
            setIsForgotPassword(true);
          }}
        >
          Forgot your password?
        </div>
      </div>

      <div className="container-btn">
        <Loadable isLoading={isLoadingButton}>
          <Button
            text="Login"
            onClick={() => {
              handleLogin();
            }}
            innerRef={btnLogin}
          />
        </Loadable>
      </div>
    </>
  );
};

export default DefaultLogin;
