import { Tooltip } from "react-tooltip";
import "./TooltipInfo.css";

const TooltipInfo = ({
  id = `tooltip${Math.random()}`,
  content = "Info",
  place = "right",
}) => {
  return (
    <>
      <div
        data-tooltip-id={id}
        data-tooltip-html={content}
        data-tooltip-place={place}
        data-tooltip-delay-hide={200}
        data-tooltip-variant="light"
      >
        <i className="bi bi-info-circle"></i>
      </div>
      <Tooltip id={id} noArrow className="tooltip-container" />
    </>
  );
};

export default TooltipInfo;
