import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";

const Container = ({ children, footer = "default" }) => {
  return (
    <>
      <Sidebar />
      <div className="container p-4 pb-5">
        {children}
        {footer ? <Footer type={footer} /> : <></>}
      </div>
    </>
  );
};

export default Container;
