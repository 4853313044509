import { formatNumberFull } from "../../services/UtilsService";
import McProgressChart from "../McCharts/McProgressChart";
import _variables from "../../styles/_variables";

const KpiProgressChart = ({
  bar = { value: 0, color: _variables.primaryOrange },
}) => {
  return (
    <div className="kpi-left-content-progress">
      <div className="kpi-left-content-progress-number">
        {formatNumberFull(bar.value)}%
      </div>
      <McProgressChart bar={bar} noText />
    </div>
  );
};

export default KpiProgressChart;
