import "./Button.css";

const Button = ({
  style = { minWidth: "160px" },
  styleColor = "btn-primary-orange",
  styleClass = "",
  icon,
  text = "Submit",
  onClick = () => {},
  dataBsToggle = "",
  dataBsTarget = "",
  dataBsDismiss = "",
  innerRef,
  disabled = false,
  children,
}) => {
  return (
    <button
      className={"btn " + styleColor + " " + styleClass}
      style={style}
      onClick={onClick}
      data-bs-toggle={dataBsToggle}
      data-bs-target={dataBsTarget.length > 0 ? "#" + dataBsTarget : ""}
      data-bs-dismiss={dataBsDismiss}
      ref={innerRef}
      disabled={disabled}
    >
      {icon && <span className="me-1">{icon}</span>}
      {text}
      {children}
    </button>
  );
};

export default Button;
