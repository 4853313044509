import "./TermsAndConditions.css";
import { MerchantsTerms } from "./MerchantsTerms";
import { AcquirersTerms } from "./AcquirersTerms";

const TermsAndConditionsPublic = () => {
  return (
    <div className="text-white mb-50 mt-50 mx-50">
      <div style={{ backgroundColor: "#333333", padding: "50px" }}>
        <MerchantsTerms />
        <AcquirersTerms />
      </div>
    </div>
  );
};

export default TermsAndConditionsPublic;
