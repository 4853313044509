import "./Layout.css";

const LayoutTwoColumns = ({ leftContent, rightContent, split, type }) => {
  return (
    <div className="row">
      <div
        className={"col-12 col-lg-6 " + (type || split ? "split-left-col" : "")}
      >
        {leftContent}
      </div>
      <div
        className={
          "col-12 col-lg-6 mt-lg-0 " +
          (type ? "" : "mt-5 ") +
          (split ? "split " : "") +
          (type || split ? "split-right-col" : "")
        }
      >
        {rightContent}
      </div>
    </div>
  );
};

export default LayoutTwoColumns;
