import { useNavigate } from "react-router-dom";
import Tab from "./Tab";

const TabSwitcher = ({ pages }) => {
  const navigate = useNavigate();

  const switchActiveTab = (index) => {
    navigate(pages[index].link);
  };

  return (
    <>
      <div className="row mb-5">
        {pages
          .filter((el) => Object.keys(el).length > 0)
          .map((page, index) => {
            return (
              <div
                className={
                  "col-12 col-lg" 
                }
                key={index + "tab"}
              >
                <Tab
                  title={page.title.toUpperCase()}
                  active={page.active}
                  onClick={() => switchActiveTab(index)}
                />
              </div>
            );
          })}
      </div>
    </>
  );
};

TabSwitcher.defaultProps = {
  pages: [
    { title: "Tab 1", link: "/one" },
    { title: "Tab 2", link: "/two" },
    { title: "Tab 3", link: "/three" },
  ],
};

export default TabSwitcher;
