import "./Select.css";

const Select = ({
  onChange = () => {},
  options = [
    { value: "1", text: "Option 1" },
    { value: "2", text: "Option 2" },
  ],
  styleClass = "",
  placeholder = "Select",

}) => {
  return (
    <select
      className={"form-select select-width " + styleClass}
      onChange={(e) => onChange(e.target.value)}
      defaultValue={0}
    >
      <option value="0" disabled hidden>
        {placeholder} 
      </option>
      {options.map((opt, index) => {
        return (
          <option value={opt.value} key={index + "selectoption"}>
            {opt.text}
          </option>
        );
      })}
    </select>
  );
};

export default Select;
