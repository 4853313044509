import { useEffect, useRef } from "react";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Loadable from "../../components/Loader/Loadable";

const ForgotPassword = ({
  loginEmail,
  setLoginEmail,
  setIsForgotPassword,
  isLoadingButton,
  handleForgotPassword,
}) => {
  const btnLogin = useRef();

  // listener for enter key
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        btnLogin.current.click();
      }
    };

    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <>
      <Input
        styleClass="mb-2"
        type="email"
        id="loginEmail"
        placeholder="name@example.com"
        value={loginEmail}
        onChange={(e) => {
          setLoginEmail(e.target.value);
        }}
        label={"Email"}
      />

      <div className="text-end mb-4">
        <div
          className="font-medium white-link"
          onClick={() => {
            setIsForgotPassword(false);
          }}
        >
          Nevermind, I remembered
        </div>
      </div>

      <div className="container-btn">
        <Loadable isLoading={isLoadingButton}>
          <Button
            text="Send mail"
            onClick={() => {
              handleForgotPassword();
            }}
            innerRef={btnLogin}
          />
        </Loadable>
      </div>
    </>
  );
};

export default ForgotPassword;
