import Loader from "../../components/Loader/Loader";

const Loadable = ({
  children,
  isLoading = false,
  color,
  type,
  styleClass = "",
}) => {
  return isLoading ? (
    <div className={styleClass}>
      <Loader type={type} color={color} />
    </div>
  ) : (
    <>{children}</>
  );
};

export default Loadable;
