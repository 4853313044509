import { useState, useEffect, useContext } from "react";
import { AccountContext } from "../../services/AccountService";
import FetchService from "../../services/FetchService";
import { formatErrorForAlert } from "../../services/UtilsService";
import Container from "../../components/Container/Container";
import Title from "../../components/Title/Title";
import Input from "../../components/Input/Input";
import Select from "../../components/Select/Select";
import Button from "../../components/Button/Button";
import Table from "../../components/Table/Table";
import Alert from "../../components/Alert/Alert";
import Loadable from "../../components/Loader/Loadable";

const AddUser = () => {
  const { sidebarExpanded } = useContext(AccountContext);
  const [inviteEmail, setInviteEmail] = useState("");
  const [alert, setAlert] = useState({});
  const [users, setUsers] = useState([]);
  const [usersFormatted, setUsersFormatted] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [acquirers, setAcquirers] = useState([]);
  const [acquirersFilter, setAcquirersFilter] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [selectedAcquirer, setSelectedAcquirer] = useState(null);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  // get all data on load
  useEffect(() => {
    getUsers();
    getMerchants();
    getAcquirers();
  }, []);

  // format users for table
  useEffect(() => {
    const formatData = () => {
      let tempFormatted = [];
      users.forEach((el) => {
        if (el.active && el.merchant) {
          tempFormatted = [
            ...tempFormatted,
            {
              email: el.email,
              merchant: el.merchant || "-",
              acquirer: el.acquirer || "-",
              button: (
                <div className="text-end">
                  <Button
                    style={{ minWidth: "0px" }}
                    text={<i className="bi bi-trash-fill"></i>}
                    onClick={() => {
                      deleteUser(el.id);
                    }}
                  />
                </div>
              ),
            },
          ];
        }
      });
      setUsersFormatted(tempFormatted);
    };

    formatData();
  }, [users]);

  const handleSelectMerchant = (e) => {
    setSelectedMerchant(e);

    let acquirersFiltered = acquirers.filter((el) => el.merchant_name === e);
    let newOptions = [{ value: 0, text: "-" }];
    if (acquirersFiltered) {
      newOptions.push(
        ...acquirersFiltered.map((el) => {
          return { value: el.acquirer_name, text: el.acquirer_name };
        })
      );
    }

    setAcquirersFilter(newOptions);
  };

  const getUsers = () => {
    FetchService.get("/api/admin/users")
      .then((res) => {
        setUsers(
          res.data.sort((a, b) => {
            const merchantA = a.merchant || "";
            const merchantB = b.merchant || "";
            return merchantA.localeCompare(merchantB);
          })
        );
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  };

  const getMerchants = () => {
    FetchService.get("/api/admin/merchants")
      .then((res) => {
        let tempFormatted = [];
        res.data.forEach((el) => {
          if (el.active) {
            tempFormatted = [
              ...tempFormatted,
              {
                value: el.name,
                text: el.name,
              },
            ];
          }
        });
        setMerchants(tempFormatted);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  };

  const getAcquirers = () => {
    FetchService.post("/api/dashboard/acquirers/", { merchant_name: "" })
      .then((res) => {
        let tempAcquirers = res.data.sort((a, b) =>
          a.acquirer_name.localeCompare(b.acquirer_name)
        );

        setAcquirers(tempAcquirers);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  };

  const addUser = () => {
    setIsLoadingButton(true);

    let body = {
      email: inviteEmail,
      merchant_name: selectedMerchant,
      acquirer_name:
        selectedAcquirer && selectedAcquirer !== 0 && selectedAcquirer !== "0"
          ? selectedAcquirer
          : "",
    };

    FetchService.post("/api/admin/users", body)
      .then(() => {
        setAlert({
          message: "User added succcessfully",
          messageType: "success",
        });
        getUsers();
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => {
        setIsLoadingButton(false);
      });
  };

  const deleteUser = (userId) => {
    if (
      window.confirm(
        `Are you sure you wanna delete ${
          users.find((u) => u.id === userId).email
        }?`
      )
    ) {
      FetchService.put(`/api/admin/users/${userId}/deactivate`)
        .then(() => {
          setAlert({
            message: "User deleted successfully",
            messageType: "success",
          });
          setUsers(users.filter((u) => u.id !== userId));
        })
        .catch((err) => {
          setAlert({ message: formatErrorForAlert(err), messageType: "error" });
        });
    }
  };

  return (
    <Container footer={false}>
      <Title>Add User</Title>

      <Alert
        styleClass={""}
        alertText={alert.message}
        styleColor={alert.messageType}
        onClick={setAlert}
        paddingLeft={sidebarExpanded}
      />
      <div className="row mb-5">
        <div className="col-12 col-lg-4">
          <Input
            styleClass="mb-2 mb-lg-0"
            type="email"
            id="inviteEmail"
            placeholder="name@example.com"
            value={inviteEmail}
            onChange={(e) => {
              setInviteEmail(e.target.value);
            }}
            label={"Email"}
          />
        </div>
        <div className="col-6 col-lg-3">
          <Select
            options={merchants}
            onChange={handleSelectMerchant}
            placeholder="Select merchant"
            styleClass="w-100"
          />
        </div>
        <div className="col-6 col-lg-3">
          <Select
            options={acquirersFilter}
            onChange={setSelectedAcquirer}
            placeholder="Select acquirer"
            styleClass="w-100"
          />
        </div>
        <div className="col-lg-2 mt-2 mt-lg-0 text-end align-self-center">
          <Loadable isLoading={isLoadingButton}>
            <Button
              text="Add"
              onClick={() => {
                addUser();
              }}
            />
          </Loadable>
        </div>
      </div>

      <Title>Active Users</Title>
      <Table
        data={usersFormatted}
        header={["Email", "Merchant", "Acquirer", ""]}
      />
    </Container>
  );
};

export default AddUser;
