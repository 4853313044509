import { ReactFitty } from "react-fitty";
import { _variablesTwoOranges } from "../../styles/_variables";
import "./Kpi.css";

const KpiBig = ({
  bottomContent = "0",
  topContent,
  color = _variablesTwoOranges.primaryOrange,
  autofit = true,
  styleClass = "",
}) => {
  return (
    <div
      className={
        "kpi-container h-100 d-flex flex-column justify-content-center align-items-center " +
        styleClass
      }
    >
      <div className="text-center mb-2">
        <b>{topContent}</b>
      </div>
      <div className="kpi-left-content" style={{ color: color }}>
        {autofit ? (
          <ReactFitty minSize={16} maxSize={40}>
            {bottomContent}
          </ReactFitty>
        ) : (
          <>{bottomContent}</>
        )}
      </div>
    </div>
  );
};

export default KpiBig;
