import { useState, useEffect, useContext } from "react";
import { AccountContext } from "../../services/AccountService";
import FetchService from "../../services/FetchService";
import {
  generateLastTwelveMonths,
  convertMonthLabelForApi,
  formatErrorForAlert,
  titleCase,
  convertCountryToCode,
  assembleBody,
  assembleBodyWithProductType,
} from "../../services/UtilsService";
import LayoutPageContainer from "../../components/Layout/LayoutPageContainer";
import TimePeriod from "../../components/TimePeriod/TimePeriod";
import Refreshing from "../../components/Refreshing/Refreshing";
import SmallTitleFlex from "../../components/Title/SmallTitleFlex";
import FilterBox from "../../components/FilterBox/FilterBox";
import Select from "../../components/Select/Select";
import LayoutTwoColumns from "../../components/Layout/LayoutTwoColumns";
import McBarChartVertical from "../../components/McCharts/McBarChartVertical";
import TableDeepDive from "../../components/TableDeepDive/TableDeepDive";
import {
  _variablesTwoOranges,
  _variablesOnlyOrangeLight,
  _variablesThreeGrey,
  _variablesOnlyOrangeLightGrey,
} from "../../styles/_variables";
import FilterBar from "../../components/FilterBox/FilterBar";
import LayoutCardFilter from "../../components/Layout/LayoutCardFilter";
import Download from "../../components/Button/Download";

const PerformanceGeography = () => {
  // global
  const {
    merchantName,
    sidebarExpanded,
    timePeriod,
    setTimePeriod,
    dashboardVersion,
  } = useContext(AccountContext);
  const [alert, setAlert] = useState({});
  // const [timePeriod, setTimePeriod] = useState([0, 11]);
  const [dateLabels, setDateLabels] = useState([]);

  // charts
  const [isLoadingTopFifteen, setIsLoadingTopFifteen] = useState(true);
  const [dataTopFifteenTransactions, setDataTopFifteenTransactions] = useState(
    []
  );
  const [dataTopFifteenVolume, setDataTopFifteenVolume] = useState([]);
  const [dataTopFifteenAvg, setDataTopFifteenAvg] = useState([]);

  // dashboard version
  const dashboardVersion1 = dashboardVersion === 1 ? true : false;
  const dashboardVersion2 = dashboardVersion === 2 ? true : false;

  // others
  const selectOptions = [
    { value: "0", text: "Card present and Card not present" },
    { value: "Y", text: "Card present" },
    { value: "N", text: "Card not present" },
  ];
  const [selectedOption, setSelectedOption] = useState("0");
  const [dataGuideTransactions, setDataGuideTransactions] = useState({
    y: "country",
    barsData: [
      { dataName: "valueCard", friendlyName: "Card present" },
      { dataName: "valueNoCard", friendlyName: "Card not present" },
    ],
  });
  const [dataGuideVolume, setDataGuideVolume] = useState({
    y: "country",
    barsData: [
      { dataName: "valueCard", friendlyName: "Card present" },
      { dataName: "valueNoCard", friendlyName: "Card not present" },
    ],
  });
  // product type
  const [productType, setProductType] = useState("");

  // get dates for timeperiod
  useEffect(() => {
    let body = {
      merchant_name: merchantName,
    };

    FetchService.post("/api/dashboard/dates/performance", body)
      .then((res) => {
        setDateLabels(generateLastTwelveMonths(res.data.max_date_customer));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  }, []);

  // get charts data based on timeperiod
  useEffect(() => {
    isLoadingAll();

    // wait some time for other changes
    const timeOut = setTimeout(() => {
      if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
        let body = assembleBodyWithProductType(
          merchantName,
          dateLabels[timePeriod[0]],
          dateLabels[timePeriod[1]],
          null,
          productType
        );

        getTopFifteen(body);
      }
    }, 1500);

    return () => clearTimeout(timeOut);
  }, [timePeriod, dateLabels, productType]);

  const isLoadingAll = () => {
    setIsLoadingTopFifteen(true);
  };

  const getTopFifteen = (body) => {
    setIsLoadingTopFifteen(true);

    FetchService.post(
      "/api/dashboard/performance/geography/top-15-countries-by-transactions",
      body
    )
      .then((res) => {
        let tempTransactions = [];
        let tempVolume = [];
        let tempAvg = [];

        res.data.forEach((el) => {
          tempTransactions.push({
            country: el.country ? titleCase(el.country) : "",
            valueCard: el.freq_count_trx_card_present
              ? el.freq_count_trx_card_present / 1000
              : 0,
            valueNoCard: el.freq_count_trx_card_not_present
              ? el.freq_count_trx_card_not_present / 1000
              : 0,
          });
          tempVolume.push({
            country: el.country ? titleCase(el.country) : "",
            valueCard: el.freq_count_amount_card_present
              ? el.freq_count_amount_card_present / 1000
              : 0,
            valueNoCard: el.freq_count_amount_card_not_present
              ? el.freq_count_amount_card_not_present / 1000
              : 0,
          });

          tempAvg.push({
            country: el.country ? convertCountryToCode(el.country) : "",
            countryFullName: el.country ? titleCase(el.country) : "",
            avg: el.avg_ticket ? el.avg_ticket : 0,
          });
        });

        tempTransactions.sort((a, b) =>
          a.valueCard + a.valueNoCard < b.valueCard + b.valueNoCard
            ? 1
            : a.valueCard + a.valueNoCard > b.valueCard + b.valueNoCard
            ? -1
            : 0
        );

        tempVolume.sort((a, b) =>
          a.valueCard + a.valueNoCard < b.valueCard + b.valueNoCard
            ? 1
            : a.valueCard + a.valueNoCard > b.valueCard + b.valueNoCard
            ? -1
            : 0
        );

        tempAvg.sort((a, b) => a.country.localeCompare(b.country));

        setDataTopFifteenTransactions(tempTransactions);
        setDataTopFifteenVolume(tempVolume);
        setDataTopFifteenAvg(tempAvg);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingTopFifteen(false));
  };

  const handleSelectedOption = (e) => {
    setSelectedOption(e);
    changeDataGuide(e);
  };

  const changeDataGuide = (selected) => {
    let temp = {};
    if (selected === "0") {
      temp = {
        y: "country",
        barsData: [
          { dataName: "valueCard", friendlyName: "Card present" },
          { dataName: "valueNoCard", friendlyName: "Card not present" },
        ],
      };

      setDataTopFifteenTransactions(
        dataTopFifteenTransactions.sort((a, b) =>
          a.valueCard + a.valueNoCard < b.valueCard + b.valueNoCard
            ? 1
            : a.valueCard + a.valueNoCard > b.valueCard + b.valueNoCard
            ? -1
            : 0
        )
      );
      setDataTopFifteenVolume(
        dataTopFifteenVolume.sort((a, b) =>
          a.valueCard + a.valueNoCard < b.valueCard + b.valueNoCard
            ? 1
            : a.valueCard + a.valueNoCard > b.valueCard + b.valueNoCard
            ? -1
            : 0
        )
      );
    } else if (selected === "Y") {
      temp = {
        y: "country",
        barsData: [{ dataName: "valueCard", friendlyName: "Card present" }],
      };

      setDataTopFifteenTransactions(
        dataTopFifteenTransactions.sort((a, b) =>
          a.valueCard < b.valueCard ? 1 : a.valueCard > b.valueCard ? -1 : 0
        )
      );
      setDataTopFifteenVolume(
        dataTopFifteenVolume.sort((a, b) =>
          a.valueCard < b.valueCard ? 1 : a.valueCard > b.valueCard ? -1 : 0
        )
      );
    } else if (selected === "N") {
      temp = {
        y: "country",
        barsData: [
          { dataName: "valueNoCard", friendlyName: "Card not present" },
        ],
      };

      setDataTopFifteenTransactions(
        dataTopFifteenTransactions.sort((a, b) =>
          a.valueNoCard < b.valueNoCard
            ? 1
            : a.valueNoCard > b.valueNoCard
            ? -1
            : 0
        )
      );
      setDataTopFifteenVolume(
        dataTopFifteenVolume.sort((a, b) =>
          a.valueNoCard < b.valueNoCard
            ? 1
            : a.valueNoCard > b.valueNoCard
            ? -1
            : 0
        )
      );
    } else {
      temp = {};
    }

    setDataGuideTransactions(temp);
    setDataGuideVolume(temp);
  };

  const handleSelectedProductType = (e) => {
    setProductType(e);
  };

  function mergeTopFifteen(array1, array2) {
    return array1.map((item1) => {
      const item2 = array2.find((item2) => item2.country === item1.country);
      return item2
        ? {
            ...item1,
            volumeCard: item2.valueCard,
            volumeNoCard: item2.valueNoCard,
          }
        : item1;
    });
  }

  return (
    <LayoutPageContainer
      title={"Performance"}
      pages={[
        {
          title: "Summary",
          link: "/performance/home",
        },
        { title: "Geography", link: "/performance/geography", active: true },
        { title: "Benchmark", link: "/performance/benchmark" },
      ]}
      alert={alert}
      setAlert={setAlert}
    >
      <Refreshing
        conditions={[isLoadingTopFifteen]}
        marginLeft={sidebarExpanded}
      />

      <TimePeriod
        dateLabels={dateLabels}
        timePeriod={timePeriod}
        setTimePeriod={setTimePeriod}
      />

      {dashboardVersion2 && (
        <FilterBar>
          <LayoutCardFilter
            onChange={handleSelectedProductType}
            tooltipAcquirer=""
            options={[
              { value: "", text: "All products" },
              { value: "CR", text: "Credit" },
              { value: "DB", text: "Debit" },
              /*             { value: "PP", text: "Prepaid" },
               */
            ]}
          />
        </FilterBar>
      )}

      <hr />
      <SmallTitleFlex
        title={"Top 15 countries by transactions"}
        tooltip={
          "Top 15 countries listed in descending order by the number of authorized transactions, based on the select channel(s). For each country, the graph on right shows the corresponding volumes (spend)."
        }
      />

      <FilterBox>
        <Select
          options={selectOptions}
          onChange={handleSelectedOption}
          placeholder="Card present and Card not present"
        />
      </FilterBox>

      <div className="mb-5">
        <div className="d-flex justify-content-end">
          <Download
            section="PerformanceGeography"
            chartName="Top 15 countries by transactions"
            data={mergeTopFifteen(
              dataTopFifteenTransactions,
              dataTopFifteenVolume
            )}
            graphType="barChart"
            productType={productType}
            timeRange={
              dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
            }
            label={"Country"}
            field1={"Transactions Card Present"}
            value1={"valueCard"}
            field2={"Transactions Card Not Present"}
            value2={"valueNoCard"}
            field3={"Volume Card Present"}
            value3={"volumeCard"}
            field4={"Volume Card Not Present"}
            value4={"volumeNoCard"}
            iconSize="sm"
            className="h-8 w-8"
          />
        </div>
        <LayoutTwoColumns
          leftContent={
            <>
              <div className="text-center mb-3">Transactions (K)</div>
              <McBarChartVertical
                background={false}
                data={dataTopFifteenTransactions}
                dataGuide={dataGuideTransactions}
                tooltipMeasureUnit=" K"
                palette={
                  selectedOption === "N"
                    ? _variablesOnlyOrangeLight
                    : _variablesTwoOranges
                }
                isPerformance
              />
            </>
          }
          rightContent={
            <>
              <div className="text-center mb-3">Volumes (K€)</div>
              <McBarChartVertical
                data={dataTopFifteenVolume}
                dataGuide={dataGuideVolume}
                tooltipMeasureUnit=" K€"
                palette={
                  selectedOption === "N"
                    ? _variablesOnlyOrangeLightGrey
                    : _variablesThreeGrey
                }
                isPerformance
              />
            </>
          }
        />
      </div>

      <TableDeepDive
        data={dataTopFifteenAvg}
        startDate={convertMonthLabelForApi(dateLabels[timePeriod[0]])}
        endDate={convertMonthLabelForApi(dateLabels[timePeriod[1]])}
        dateLabels={dateLabels}
        timePeriod={timePeriod}
        productType={productType}
      />
    </LayoutPageContainer>
  );
};

export default PerformanceGeography;
