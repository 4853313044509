import { useState, useEffect, useRef } from "react";
import InputPassword from "../../components/Input/InputPassword";
import Button from "../../components/Button/Button";
import Loadable from "../../components/Loader/Loadable";
import InputToggle from "../../components/Input/InputToggle";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import "./Login.css";

const FirstLogin = ({
  newPassword,
  setNewPassword,
  repeatPassword,
  setRepeatPassword,
  isLoadingButton,
  handleChangePassword,
  isReadingTnc,
  setIsReadingTnc,
}) => {
  const btnLogin = useRef();
  const [isRepeated, setIsRepeated] = useState(false);
  const [isLong, setIsLong] = useState(false);
  const [isLowercase, setIsLowercase] = useState(false);
  const [isUppercase, setIsUppercase] = useState(false);
  const [isNumber, setIsNumber] = useState(false);
  const [isSpecial, setIsSpecial] = useState(false);
  const [isNotIdentical, setIsNotIdentical] = useState(false);
  const [isNotConsecutive, setIsNotConsecutive] = useState(false);
  const [hasAcceptedTnC, setHasAcceptedTnC] = useState(false);

  // listener for enter key
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        btnLogin.current.click();
      }
    };

    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  // check password conditions while typing
  useEffect(() => {
    setIsRepeated(newPassword === repeatPassword && newPassword !== "");
    setIsLong(newPassword.length >= 12);
    setIsLowercase(newPassword.match(/[a-z]+/));
    setIsUppercase(newPassword.match(/[A-Z]+/));
    setIsNumber(newPassword.match(/[0-9]+/));
    setIsSpecial(newPassword.match(/[!@#$%^&*]+/));
    setIsNotIdentical(!newPassword.match(/(.)\1\1\1/) && newPassword !== "");
    setIsNotConsecutive(
      !newPassword.match(
        /(abcd|bcde|cdef|defg|efgh|fghi|ghij|hijk|ijkl|jklm|klmn|lmno|mnop|nopq|opqr|pqrs|qrst|rstu|stuv|tuvw|uvwx|vwxy|wxyz|0123|1234|2345|3456|4567|5678|6789)/
      ) && newPassword !== ""
    );
  }, [newPassword, repeatPassword]);

  return (
    <>
      {isReadingTnc ? (
        <TermsAndConditions setIsReadingTnc={setIsReadingTnc} />
      ) : (
        <>
          <InputPassword
            styleClass="mb-2"
            type="password"
            id="newPassword"
            placeholder="new password"
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
            label="New Password"
          />
          <InputPassword
            styleClass="mb-2"
            type="password"
            id="repeatPassword"
            placeholder="repeat password"
            value={repeatPassword}
            onChange={(e) => {
              setRepeatPassword(e.target.value);
            }}
            label="Repeat Password"
          />
          <hr className="my-3" />
          <div className="text-start">
            <ul className="condition-list">
              <li className={isRepeated ? "active" : ""}>
                Password repeated correctly
              </li>
              <li className={isLong ? "active" : ""}>
                12 characters or longer
              </li>
              <li className={isLowercase ? "active" : ""}>
                At least 1 lowercase character
              </li>
              <li className={isUppercase ? "active" : ""}>
                At least 1 uppercase character
              </li>
              <li className={isNumber ? "active" : ""}>At least 1 number</li>
              <li className={isSpecial ? "active" : ""}>
                At least 1 special character (e.g. !@#$%^&*){" "}
              </li>
              <li className={isNotIdentical ? "active" : ""}>
                No more than 3 identical characters in a row (e.g. 'aaaa',
                '1111'){" "}
              </li>
              <li className={isNotConsecutive ? "active" : ""}>
                No more than 3 consecutive characters in a row (e.g. 'abcd',
                '1234')
              </li>
            </ul>
          </div>
          <hr className="my-3" />

          <div className="d-flex justify-content-start align-items-center mb-3">
            <InputToggle
              id={"toggle-tnc"}
              checked={hasAcceptedTnC}
              onChange={() => {
                setHasAcceptedTnC(!hasAcceptedTnC);
              }}
              styleClass={"me-2"}
            />
            <label
              className="form-check-label text-tnc"
              htmlFor="togglechromecast"
            >
              I have read and agreed to the{" "}
              <span
                className="font-medium white-link"
                onClick={() => setIsReadingTnc(true)}
              >
                Terms and Conditions
              </span>
            </label>
          </div>

          <div className="container-btn mb-5">
            <Loadable isLoading={isLoadingButton}>
              <Button
                text="Set new password"
                onClick={() => {
                  handleChangePassword();
                }}
                innerRef={btnLogin}
                disabled={
                  !(
                    isRepeated &&
                    isLong &&
                    isLowercase &&
                    isUppercase &&
                    isNumber &&
                    isSpecial &&
                    isNotIdentical &&
                    isNotConsecutive &&
                    hasAcceptedTnC
                  )
                }
              />
            </Loadable>
          </div>
        </>
      )}
    </>
  );
};

export default FirstLogin;
