const Modal = ({
  dataBsTarget = "",
  title = "",
  modalBody = <></>,
  modalFooter,
  children,
}) => {
  return (
    <>
      <div
        className="modal fade"
        id={dataBsTarget}
        tabIndex="-1"
        aria-labelledby={dataBsTarget}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between align-items-center">
              <h5 className="modal-title mb-0">{title}</h5>
              <div className="d-flex align-items-center">
                {children}
                <button
                  type="button"
                  className="btn-close ml-2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </div>
            <div className="modal-body">{modalBody}</div>
            {modalFooter ? (
              <div className="modal-footer">{modalFooter}</div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
