import { useState, useEffect, useContext } from "react";
import { AccountContext } from "../../services/AccountService";
import FetchService from "../../services/FetchService";
import {
  generateLastTwelveMonths,
  formatNumberFull,
  formatErrorForAlert,
  titleCase,
  sortTicketRanges,
  sortSubchannelsList,
  assembleBody,
  assembleBodyWithProductType,
  formatNumberRound,
  compareAndAddDummy,
  mergeData,
  padArray,
} from "../../services/UtilsService";
import LayoutPageContainer from "../../components/Layout/LayoutPageContainer";
import TimePeriod from "../../components/TimePeriod/TimePeriod";
import Refreshing from "../../components/Refreshing/Refreshing";
import SmallTitleFlex from "../../components/Title/SmallTitleFlex";
import LayoutTwoColumns from "../../components/Layout/LayoutTwoColumns";
import FilterBoxSticky from "../../components/FilterBox/FilterBoxSticky";
import LayoutBenchmarkFilter from "../../components/Layout/LayoutBenchmarkFilter";
import KpiColumn from "../../components/Kpi/KpiColumn";
import KpiProgressChart from "../../components/Kpi/KpiProgressChart";
import McDonutChartSingle from "../../components/McCharts/McDonutChartSingle";
import McProgressChartBlock from "../../components/McCharts/McProgressChartBlock";
import McTreemapChart from "../../components/McCharts/McTreemapChart";
import SmallTitle from "../../components/Title/SmallTitle";
import Title from "../../components/Title/Title";
import Modal from "../../components/Modal/Modal";
import TableAG from "../../components/Table/TableAG";
import McDonutSideLegend from "../../components/McCharts/McDonutSideLegend";
import Button from "../../components/Button/Button";
import {
  _variablesFour,
  _variablesTwelve,
  _variablesThreeOrangesRate,
  _variablesSix,
  _variablesTwoOranges,
} from "../../styles/_variables";
import FilterBar from "../../components/FilterBox/FilterBar";
import LayoutCardFilter from "../../components/Layout/LayoutCardFilter";
import Download from "../../components/Button/Download";

const colDefs = [
  { field: "exemption", flex: 1 },
  {
    field: "transaction_share",
    headerName: "Transaction Share",
    flex: 1,
  },
  { field: "approval_rate", headerName: "Approval Rate", flex: 1 },
];

const colsDefMerchantAdviseCode = [
  { field: "label", headerName: "MAC", flex: 1 },
  { field: "value", headerName: "Transaction Share", flex: 1 },
];

const AuthorizationBenchmark = () => {
  // global
  const {
    merchantName,
    sidebarExpanded,
    acquirerName,
    isMultiAcquirer,
    timePeriod,
    setTimePeriod,
    dashboardVersion,
  } = useContext(AccountContext);
  const [alert, setAlert] = useState({});
  // const [timePeriod, setTimePeriod] = useState([0, 11]);
  const [dateLabels, setDateLabels] = useState([]);

  // geography & benchmark
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState("0");

  // dashboard version
  const dashboardVersion1 = dashboardVersion === 1 ? true : false;
  const dashboardVersion2 = dashboardVersion === 2 ? true : false;

  // charts
  const [isLoadingKpis, setIsLoadingKpis] = useState(true);
  const [dataKpis, setDataKpis] = useState([]);
  const [isLoadingBySubchannel, setIsLoadingBySubchannel] = useState(true);
  const [dataBySubchannel, setDataBySubchannel] = useState([]);
  const [rateBySubchannel, setRateBySubchannel] = useState([]);
  const [isLoadingByTicketRange, setIsLoadingByTicketRange] = useState(true);
  const [dataByTicketRange, setDataByTicketRange] = useState([]);
  const [rateByTicketRange, setRateByTicketRange] = useState([]);
  const [isLoadingByTokenPenetration, setIsLoadingByTokenPenetration] =
    useState(true);
  const [dataByTokenPenetration, setDataByTokenPenetration] = useState([]);
  const [rateByTokenPenetration, setRateByTokenPenetration] = useState([]);
  const [isLoadingByProgram, setIsLoadingByProgram] = useState(true);
  const [dataByProgram, setDataByProgram] = useState([]);
  const [rateByProgram, setRateByProgram] = useState([]);
  const [isLoadingDeclineReasons, setIsLoadingDeclineReasons] = useState(true);
  const [dataDeclineReasons, setDataDeclineReasons] = useState([]);
  const [listDeclineReasons, setListDeclineReasons] = useState([]);

  // charts countries
  const [dataKpisCountries, setDataKpisCountries] = useState([]);
  const [dataBySubchannelCountries, setDataBySubchannelCountries] = useState(
    []
  );
  const [rateBySubchannelCountries, setRateBySubchannelCountries] = useState(
    []
  );
  const [dataByTicketRangeCountries, setDataByTicketRangeCountries] = useState(
    []
  );
  const [rateByTicketRangeCountries, setRateByTicketRangeCountries] = useState(
    []
  );
  const [dataByTokenPenetrationCountries, setDataByTokenPenetrationCountries] =
    useState([]);
  const [rateByTokenPenetrationCountries, setRateByTokenPenetrationCountries] =
    useState([]);
  const [dataByProgramCountries, setDataByProgramCountries] = useState([]);
  const [rateByProgramCountries, setRateByProgramCountries] = useState([]);
  const [data3DSCompliance, setData3DSCompliance] = useState([]);
  const [rate3DSCompliance, setRate3DSCompliance] = useState([]);
  const [data3DSComplianceCountries, setData3DSComplianceCountries] = useState(
    []
  );
  const [rate3DSComplianceCountries, setRate3DSComplianceCountries] = useState(
    []
  );
  const [dataExemptionTypes, setDataExemptionTypes] = useState([]);
  const [dataExemptionTypesCountries, setDataExemptionTypesCountries] =
    useState([]);
  const [isLoadingExemptionTypes, setIsLoadingExemptionTypes] = useState(true);
  const [isLoading3DSCompliance, setIsLoading3DSCompliance] = useState(true);
  const [dataDeclineReasonsCountries, setDataDeclineReasonsCountries] =
    useState([]);
  // product type
  const [productType, setProductType] = useState("");
  // merchant advise code
  const [dataMerchantAdviseCodeDonut, setDataMerchantAdviseCodeDonut] =
    useState([]);
  const [
    isLoadingMerchantAdviseCodeDonut,
    setIsLoadingMerchantAdviseCodeDonut,
  ] = useState(true);
  const [dataMerchantAdviseCode, setDataMerchantAdviseCode] = useState([]);
  const [isLoadingMerchantAdviseCode, setIsLoadingMerchantAdviseCode] =
    useState(true);
  const [dataMerchantAdviseCodeCountries, setDataMerchantAdviseCodeCountries] =
    useState([]);
  const [
    dataMerchantAdviseCodeDonutCountries,
    setDataMerchantAdviseCodeDonutCountries,
  ] = useState([]);
  //api response
  const [apiDataSubchannel, setApiDataSubchannel] = useState([]);
  const [apiDataSubchannelCountries, setApiDataSubchannelCountries] = useState(
    []
  );
  const [apiDataTicketRange, setApiDataTicketRange] = useState([]);
  const [apiDataTicketRangeCountries, setApiDataTicketRangeCountries] =
    useState([]);
  const [apiData3DSCompliance, setApiData3DSCompliance] = useState([]);
  const [apiData3DSComplianceCountries, setApiData3DSComplianceCountries] =
    useState([]);
  //merged data
  const [mergedDataSubchannel, setMergedDataSubchannel] = useState([]);
  const [mergedDataTicketRange, setMergedDataTicketRange] = useState([]);
  const [mergedData3DSCompliance, setMergedData3DSCompliance] = useState([]);
  const [mergedDataDeclineReasons, setMergedDataDeclineReasons] = useState([]);

  // get dates for timeperiod
  useEffect(() => {
    let body = {
      merchant_name: merchantName,
    };

    FetchService.post("/api/dashboard/dates/autho", body)
      .then((res) => {
        setDateLabels(generateLastTwelveMonths(res.data.max_date_customer));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  }, []);

  // get charts data based on timeperiod
  useEffect(() => {
    isLoadingAll();

    // wait some time for other changes
    const timeOut = setTimeout(() => {
      if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
        let body = assembleBodyWithProductType(
          merchantName,
          dateLabels[timePeriod[0]],
          dateLabels[timePeriod[1]],
          acquirerName,
          productType
        );

        // get countries for select
        getCountries(body);

        getKpis(body, "geography");
        getBySubchannel(body, "geography");
        getByTicketRange(body, "geography");
        getByTokenPenetration(body, "geography");
        getByProgram(body, "geography");
        getDeclineReasons(body, "geography");
        get3DSCompliance(body, "geography");
        getExemptionTypes(body, "geography");
        getMerchantAdviseCode(body, "geography");
        getMerchantAdviseCodeDonut(body, "geography");
      }
    }, 1500);

    return () => clearTimeout(timeOut);
  }, [timePeriod, dateLabels, productType]);

  // get charts data based on country
  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (countries.length > 0) {
        let body = assembleBodyCountry();

        getKpis(body, "benchmark");
        getKpis(body, "geography");

        getBySubchannel(body, "benchmark");
        getBySubchannel(body, "geography");

        getByTicketRange(body, "benchmark");
        getByTicketRange(body, "geography");

        getByTokenPenetration(body, "benchmark");
        getByTokenPenetration(body, "geography");

        getByProgram(body, "benchmark");
        getByProgram(body, "geography");

        get3DSCompliance(body, "benchmark");
        get3DSCompliance(body, "geography");

        getExemptionTypes(body, "benchmark");
        getExemptionTypes(body, "geography");

        getDeclineReasons(body, "benchmark");
        getDeclineReasons(body, "geography");

        getMerchantAdviseCode(body, "benchmark");
        getMerchantAdviseCode(body, "geography");

        getMerchantAdviseCodeDonut(body, "benchmark");
        getMerchantAdviseCodeDonut(body, "geography");
      }
    }, 1500);

    return () => clearTimeout(timeOut);
  }, [timePeriod, dateLabels, selectedCountries, countries, productType]);

  const isLoadingAll = () => {
    setIsLoadingKpis(true);
    setIsLoadingBySubchannel(true);
    setIsLoadingByTicketRange(true);
    setIsLoadingByTokenPenetration(true);
    setIsLoadingByProgram(true);
    setIsLoadingDeclineReasons(true);
    setIsLoading3DSCompliance(true);
    setIsLoadingExemptionTypes(true);
    setIsLoadingMerchantAdviseCode(true);
    setIsLoadingMerchantAdviseCodeDonut(true);
  };

  const assembleBodyCountry = () => {
    let body = assembleBody(
      merchantName,
      dateLabels[timePeriod[0]],
      dateLabels[timePeriod[1]],
      acquirerName,
      undefined,
      productType
    );

    if (selectedCountries !== 0 && selectedCountries !== "0") {
      body.country = [selectedCountries];
    }

    return body;
  };

  const handleSelectCountry = (e) => {
    setSelectedCountries(e);
  };

  const getCountries = (body) => {
    body.only_euro = true;

    FetchService.post("/api/dashboard/geography/autho/top", body)
      .then((res) => {
        let tempCountries = res.data.countries.sort((a, b) =>
          a.localeCompare(b)
        );
        tempCountries = tempCountries
          .map((el) => {
            // For Google hide Other value
            if (merchantName === "GOOGLE" && el !== "UK") return undefined;
            return { value: el, text: titleCase(el) };
          })
          .filter((el) => el !== undefined);
        tempCountries = [{ value: 0, text: "All countries" }, ...tempCountries];

        setCountries(tempCountries);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  };

  const getKpis = (body, domain) => {
    setIsLoadingKpis(true);

    FetchService.post(`/api/dashboard/autho/${domain}/performance`, body)
      .then((res) => {
        domain === "benchmark"
          ? setDataKpisCountries(res.data)
          : setDataKpis(res.data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingKpis(false));
  };

  const getBySubchannel = (body, domain) => {
    setIsLoadingBySubchannel(true);

    FetchService.post(
      `/api/dashboard/autho/${domain}/transactions-by-subchannel-and-approval-rate`,
      body
    )
      .then((res) => {
        domain === "benchmark"
          ? setApiDataSubchannelCountries(res.data)
          : setApiDataSubchannel(res.data);
        let cleanRes = res.data.filter((el) => el.label !== "");
        cleanRes.sort((a, b) => a.label.localeCompare(b.label));

        let tempData = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.value ? el.value : 0,
          };
        });
        sortSubchannelsList(tempData);
        domain === "benchmark"
          ? setDataBySubchannelCountries(tempData)
          : setDataBySubchannel(tempData);

        let tempRate = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.approval_rate ? el.approval_rate : 0,
          };
        });
        sortSubchannelsList(tempRate);
        domain === "benchmark"
          ? setRateBySubchannelCountries(tempRate)
          : setRateBySubchannel(tempRate);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingBySubchannel(false));
  };

  const getByTicketRange = (body, domain) => {
    setIsLoadingByTicketRange(true);

    FetchService.post(
      `/api/dashboard/autho/${domain}/transactions-by-ticket-range-and-approval-rate`,
      body
    )
      .then((res) => {
        domain === "benchmark"
          ? setApiDataTicketRangeCountries(res.data)
          : setApiDataTicketRange(res.data);
        let tempData = res.data.map((el) => {
          return {
            name: el.label ? el.label + " €" : "",
            value: el.value ? el.value : 0,
          };
        });
        domain === "benchmark"
          ? setDataByTicketRangeCountries(sortTicketRanges(tempData, "€"))
          : setDataByTicketRange(sortTicketRanges(tempData, "€"));

        let tempRate = res.data.map((el) => {
          return {
            name: el.label ? el.label + " €" : "",
            value: el.approval_rate ? el.approval_rate : 0,
          };
        });
        domain === "benchmark"
          ? setRateByTicketRangeCountries(sortTicketRanges(tempRate, "€"))
          : setRateByTicketRange(sortTicketRanges(tempRate, "€"));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingByTicketRange(false));
  };

  const getByTokenPenetration = (body, domain) => {
    setIsLoadingByTokenPenetration(true);

    FetchService.post(
      `/api/dashboard/autho/${domain}/transactions-by-token-penetration-and-approval-rate`,
      body
    )
      .then((res) => {
        let cleanRes = res.data.sort((a, b) =>
          a.label < b.label ? 1 : a.label > b.label ? -1 : 0
        );

        let tempData = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.value ? el.value : 0,
          };
        });
        domain === "benchmark"
          ? setDataByTokenPenetrationCountries(tempData)
          : setDataByTokenPenetration(tempData);

        let tempRate = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.approval_rate ? el.approval_rate : 0,
          };
        });
        domain === "benchmark"
          ? setRateByTokenPenetrationCountries(tempRate)
          : setRateByTokenPenetration(tempRate);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingByTokenPenetration(false));
  };

  const getByProgram = (body, domain) => {
    setIsLoadingByProgram(true);

    FetchService.post(
      `/api/dashboard/autho/${domain}/transactions-by-program-and-approval-rate`,
      body
    )
      .then((res) => {
        let cleanRes = res.data.sort((a, b) =>
          a.label > b.label ? 1 : a.label < b.label ? -1 : 0
        );

        let tempData = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.value ? el.value : 0,
          };
        });
        domain === "benchmark"
          ? setDataByProgramCountries(tempData)
          : setDataByProgram(tempData);

        let tempRate = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.approval_rate ? el.approval_rate : 0,
          };
        });
        domain === "benchmark"
          ? setRateByProgramCountries(tempRate)
          : setRateByProgram(tempRate);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingByProgram(false));
  };

  const get3DSCompliance = (body, domain) => {
    setIsLoading3DSCompliance(true);
    FetchService.post(`/api/dashboard/autho/${domain}/3DS-compliance`, body)
      .then((res) => {
        domain === "benchmark"
          ? setApiData3DSComplianceCountries(res.data)
          : setApiData3DSCompliance(res.data);
        let cleanRes = res.data.sort((a, b) =>
          a.label > b.label ? 1 : a.label < b.label ? -1 : 0
        );
        let tempData = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.trx_share ? el.trx_share : 0,
          };
        });
        let tempRate = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.approval_rate ? el.approval_rate : 0,
          };
        });

        domain === "benchmark"
          ? setData3DSComplianceCountries(tempData)
          : setData3DSCompliance(tempData);

        domain === "benchmark"
          ? setRate3DSComplianceCountries(tempRate)
          : setRate3DSCompliance(tempRate);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoading3DSCompliance(false));
  };

  const getExemptionTypes = (body, domain) => {
    setIsLoadingExemptionTypes(true);
    FetchService.post(`/api/dashboard/autho/${domain}/exemption-types`, body)
      .then((res) => {
        let rowData = res.data.map((el) => {
          return {
            exemption: el.label ? el.label : "",
            transaction_share:
              formatNumberRound(Number(el.trx_share ? el.trx_share : 0)) + "%",
            approval_rate:
              formatNumberRound(
                Number(el.approval_rate ? el.approval_rate : 0)
              ) + "%",
          };
        });
        domain === "benchmark"
          ? setDataExemptionTypesCountries(rowData)
          : setDataExemptionTypes(rowData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingExemptionTypes(false));
  };

  const getDeclineReasons = (body, domain) => {
    setIsLoadingDeclineReasons(true);

    FetchService.post(`/api/dashboard/autho/${domain}/decline-reasons`, body)
      .then((res) => {
        let tempData = [];
        res.data.forEach((el) => {
          tempData.push({
            name: el.label ? el.label : "Unknown",
            size: el.value ? el.value : 0,
            percent: formatNumberFull(el.percent ? el.percent : 0),
          });
        });

        let tempReasons = listDeclineReasons;
        res.data.forEach((reason) => {
          if (!tempReasons.includes(reason.label)) {
            tempReasons.push(reason.label);
          }
        });
        setListDeclineReasons(tempReasons);

        domain === "benchmark"
          ? setDataDeclineReasonsCountries(tempData)
          : setDataDeclineReasons(tempData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingDeclineReasons(false));
  };

  // donut data
  const getMerchantAdviseCodeDonut = (body, domain) => {
    setIsLoadingMerchantAdviseCodeDonut(true);
    FetchService.post(
      `/api/dashboard/autho/${domain}/merchant-advice-code-total-percentage`,
      body
    )
      .then((res) => {
        let data = [];
        if (Object.keys(res.data).length !== 0) {
          data.push(
            {
              name: "Mac Present",
              value: res.data.mac_present ? res.data.mac_present : 0,
            },
            {
              name: "Mac Absent",
              value: res.data.mac_absent ? res.data.mac_absent : 0,
            }
          );
        }
        domain === "benchmark"
          ? setDataMerchantAdviseCodeDonutCountries(data)
          : setDataMerchantAdviseCodeDonut(data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingMerchantAdviseCodeDonut(false));
  };

  // table data
  const getMerchantAdviseCode = (body, domain) => {
    setIsLoadingMerchantAdviseCode(true);
    FetchService.post(
      `/api/dashboard/autho/${domain}/merchant-advice-code`,
      body
    )
      .then((res) => {
        let tempData = [];
        res.data.map((el) => {
          tempData.push({
            label: el.label ? el.label : "",
            value: formatNumberRound(el.percent ? el.percent : 0) + "%",
          });
        });

        domain === "benchmark"
          ? setDataMerchantAdviseCodeCountries(tempData)
          : setDataMerchantAdviseCode(tempData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingMerchantAdviseCode(false));
  };

  const handleSelectedProductType = (e) => {
    setProductType(e);
  };
  let pages = [
    {
      title: "Overview",
      link: "/authorization/home",
    },
    { title: "Geography", link: "/authorization/geography" },
    { title: "Benchmark", link: "/authorization/benchmark", active: true },
    { title: "Acquirers", link: "/authorization/acquirers" },
    dashboardVersion2 && { title: "Issuers", link: "/authorization/issuers" },
  ];

  if (!isMultiAcquirer && !acquirerName) {
    pages = pages.filter((el) => el.title !== "Acquirers");
  }

  useEffect(() => {
    const mergedDataSx = compareAndAddDummy(
      apiDataSubchannel,
      apiDataSubchannelCountries,
      "label",
      "value",
      "approval_rate"
    );
    const mergedDataDx = compareAndAddDummy(
      apiDataSubchannelCountries,
      mergedDataSx,
      "label",
      "value",
      "approval_rate"
    );

    setMergedDataSubchannel(
      mergeData(
        mergedDataSx,
        mergedDataDx,
        [
          { source: "value", target: "trx_share_all" },
          { source: "approval_rate", target: "approval_rate_all" },
        ],
        [
          { source: "value", target: "trx_share_country" },
          { source: "approval_rate", target: "approval_rate_country" },
        ],
        "label"
      )
    );
  }, [apiDataSubchannel, apiDataSubchannelCountries]);

  useEffect(() => {
    setMergedDataTicketRange(
      mergeData(
        apiDataTicketRange,
        compareAndAddDummy(
          apiDataTicketRangeCountries,
          apiDataTicketRange,
          "label"
        ),
        [
          { source: "value", target: "value_all" },
          { source: "approval_rate", target: "approval_rate_all" },
        ],
        [
          { source: "value", target: "value_country" },
          { source: "approval_rate", target: "approval_rate_country" },
        ],
        "label"
      )
    );
  }, [apiDataTicketRange, apiDataTicketRangeCountries]);

  useEffect(() => {
    const mergedDataSx = compareAndAddDummy(
      apiDataSubchannel,
      apiDataSubchannelCountries,
      "label",
      "trx_share",
      "approval_rate"
    );
    const mergedDataDx = compareAndAddDummy(
      apiDataSubchannelCountries,
      mergedDataSx,
      "label",
      "trx_share",
      "approval_rate"
    );
    setMergedData3DSCompliance(
      mergeData(
        mergedDataSx,
        mergedDataDx,
        [
          { source: "trx_share", target: "trx_share_all" },
          { source: "approval_rate", target: "approval_rate_all" },
        ],
        [
          { source: "trx_share", target: "trx_share_country" },
          { source: "approval_rate", target: "approval_rate_country" },
        ],
        "label"
      )
    );
  }, [apiData3DSCompliance, apiData3DSComplianceCountries]);

  useEffect(() => {
    setMergedDataDeclineReasons(
      mergeData(
        dataDeclineReasons,
        dataDeclineReasonsCountries,
        [{ source: "percent", target: "percent_all" }],
        [{ source: "percent", target: "percent_country" }],
        "name"
      )
    );
  }, [dataDeclineReasons, dataDeclineReasonsCountries]);

  return (
    <LayoutPageContainer
      title={"Authorization"}
      pages={pages}
      alert={alert}
      setAlert={setAlert}
    >
      <Refreshing
        conditions={[
          isLoadingKpis,
          isLoadingBySubchannel,
          isLoadingByTicketRange,
          isLoadingByTokenPenetration,
          isLoadingByProgram,
          isLoadingDeclineReasons,
          isLoading3DSCompliance,
          isLoadingExemptionTypes,
          isLoadingMerchantAdviseCode,
          isLoadingMerchantAdviseCodeDonut,
        ]}
        marginLeft={sidebarExpanded}
      />

      <TimePeriod
        dateLabels={dateLabels}
        timePeriod={timePeriod}
        setTimePeriod={setTimePeriod}
      />
      {dashboardVersion2 && (
        <FilterBar>
          <LayoutCardFilter
            onChange={handleSelectedProductType}
            tooltipAcquirer=""
            options={[
              { value: "", text: "All products" },
              { value: "CR", text: "Credit" },
              { value: "DB", text: "Debit" },
              /*             { value: "PP", text: "Prepaid" },
               */
            ]}
          />
        </FilterBar>
      )}
      <hr />

      <FilterBoxSticky>
        <LayoutBenchmarkFilter
          options={countries}
          tooltipMerchant={
            "Merchant performance includes the overall performances.<br /><br />" +
            "Approval rate is calculated as: number of approved transactions divided by total transactions.<br /><br />" +
            "Average ticket is calculated as: volumes (spend) divided by transactions."
          }
          tooltipPeer={
            "Peer performance includes all the merchants that have your same MCC in any given selected Country.<br /><br />" +
            "Approval rate is calculated as: number of approved transactions divided by total transactions.<br /><br />" +
            "Average ticket is calculated as: volumes (spend) divided by transactions."
          }
          selected={selectedCountries}
          onChange={handleSelectCountry}
        />
      </FilterBoxSticky>

      <LayoutTwoColumns
        split
        leftContent={
          <>
            <Title styleClass="d-block d-lg-none mb-3">Merchant</Title>
            <KpiColumn
              kpis={[
                /* {
                  leftContent:
                    (dataKpis.transaction_approved
                      ? formatNumberFull(dataKpis.transaction_approved / 1000)
                      : "0") + " K",
                  righContent: "Transactions approved",
                  color: _variablesTwoOranges.primaryOrange,
                },
                {
                  leftContent:
                    (dataKpis.transaction_denied
                      ? formatNumberFull(dataKpis.transaction_denied / 1000)
                      : "0") + " K",
                  righContent: "Transactions declined",
                  color: _variablesTwoOranges.primaryOrangeLight,
                }, */
                {
                  leftContent:
                    (dataKpis.ticket_approved
                      ? formatNumberFull(dataKpis.ticket_approved)
                      : "0") + " €",
                  righContent: "Average ticket approved",
                  color: _variablesTwoOranges.primaryOrange,
                },
                {
                  leftContent:
                    (dataKpis.ticket_denied
                      ? formatNumberFull(dataKpis.ticket_denied)
                      : "0") + " €",
                  righContent: "Average ticket declined",
                  color: _variablesTwoOranges.primaryOrangeLight,
                },
                {
                  leftContent: (
                    <KpiProgressChart
                      bar={{
                        value: dataKpis.approval_rate,
                        color: _variablesTwoOranges.primaryOrange,
                      }}
                    />
                  ),
                  righContent: "Approval rate",
                  autofit: false,
                },
              ]}
            />

            <hr />

            <div className="mb-4">
              <SmallTitleFlex
                title={"Transactions by subchannel"}
                tooltip={
                  "Card not present transactions split by subchannel incidence: Recurring, Ecommerce, MOTO or Other.<br /><br />" +
                  "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                }
              />

              <McDonutChartSingle
                data={dataBySubchannel}
                side={"legend"}
                palette={_variablesFour}
                tooltipMeasureUnit="%"
              />
            </div>

            <SmallTitleFlex
              title="Approval rate by subchannel"
              tooltip={
                "Approval rates by different subchannels: Ecommerce, MOTO, Recurring, Others Approval rate is defined as number of successfully approved transactions to total transactions"
              }
            ></SmallTitleFlex>
            <McProgressChartBlock
              data={padArray(rateBySubchannel, rateBySubchannelCountries)}
              palette={_variablesFour}
            />

            <hr style={{ marginBottom: "5.6rem" }} />

            <div className="mb-4">
              <SmallTitleFlex
                title={"Transaction count by ticket range"}
                tooltip={
                  "Card not present transactions split by ticket range incidence: 0€, 0.01-30€, 30.01-100€, 100.01-250€, 250.01-500€, or +500€.<br /><br />" +
                  "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                }
              />
              <McDonutChartSingle
                data={dataByTicketRange}
                side={"legend"}
                palette={_variablesSix}
                tooltipMeasureUnit="%"
              />
            </div>

            <SmallTitle>Approval rate by ticket range</SmallTitle>
            <McProgressChartBlock
              data={padArray(rateByTicketRange, rateByTicketRangeCountries)}
              palette={_variablesSix}
            />

            <hr />

            <div className="mb-4">
              <SmallTitleFlex
                title={"Transactions by token penetration"}
                tooltip={
                  "Card not present transactions split by tokenization incidence: tokenized or not tokenized.<br /><br />" +
                  "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                }
              />
              <McDonutChartSingle
                data={dataByTokenPenetration}
                side={"legend"}
                palette={_variablesTwoOranges}
                tooltipMeasureUnit="%"
              />
            </div>

            <SmallTitle>Approval rate by token penetration</SmallTitle>
            <McProgressChartBlock
              data={padArray(
                rateByTokenPenetration,
                rateByTokenPenetrationCountries
              )}
              palette={_variablesTwoOranges}
            />

            <hr />

            <div className="mb-4">
              <SmallTitleFlex
                title={"Transactions by program"}
                tooltip={
                  "Card not present transactions split by program incidence: 3DS or non 3DS.<br /><br />" +
                  "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                }
              />
              <McDonutChartSingle
                data={dataByProgram}
                side={"legend"}
                palette={_variablesTwoOranges}
                tooltipMeasureUnit="%"
              />
            </div>

            <SmallTitle>Approval rate by program</SmallTitle>
            <McProgressChartBlock
              data={padArray(rateByProgram, rateByProgramCountries)}
              palette={_variablesTwoOranges}
            />

            {dashboardVersion2 && (
              <>
                <hr />
                <div className="mb-4">
                  <>
                    <SmallTitleFlex
                      title={"3DS Exemptions Share"}
                      tooltip={
                        "Transaction share of various CNP compositions along with exemptions."
                      }
                    />
                    <div
                      style={{ display: "flex" }}
                      className="flex-lg-column flex-xl-row"
                    >
                      <McDonutChartSingle
                        data={compareAndAddDummy(
                          data3DSCompliance,
                          data3DSComplianceCountries,
                          "name"
                        )}
                        palette={_variablesSix}
                        tooltipMeasureUnit="%"
                      />
                      <div
                        style={{
                          marginTop: "",
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <b>Transaction Share</b>
                        <McDonutSideLegend
                          data={compareAndAddDummy(
                            data3DSCompliance,
                            data3DSComplianceCountries,
                            "name"
                          )}
                          palette={_variablesSix}
                        />
                      </div>
                    </div>

                    <Modal
                      dataBsTarget="exemptionsModal"
                      title={<b>Non-3DS Exemption Types</b>}
                      modalBody={
                        <div
                          style={{
                            width: "100%",
                            height: "30rem",
                            display: "flex",
                          }}
                        >
                          <TableAG
                            colDefs={colDefs}
                            rowData={dataExemptionTypes}
                          />
                        </div>
                      }
                    >
                      <Download
                        section="AutheBenchmark"
                        chartName="Non-3DS Exemption Types"
                        data={dataExemptionTypes}
                        graphType="pie"
                        label={"Exemption Type"}
                        productType={productType}
                        country={selectedCountries}
                        timeRange={
                          dateLabels[timePeriod[0]] +
                          " - " +
                          dateLabels[timePeriod[1]]
                        }
                        fields={[
                          {
                            field: "Transaction Share",
                            value: "transaction_share",
                          },
                          { field: "Approval Rate", value: "approval_rate" },
                        ]}
                        iconSize="sm"
                        className="h-8 w-8"
                      />
                    </Modal>
                  </>
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <SmallTitleFlex
                    title="Approval Rate by 3DS exemptions"
                    tooltip={
                      "Approval rate of various CNP compositions along with exemptions.<br /><br />" +
                      "Approval rate is defined as number of successfully approved transactions over total transactions"
                    }
                  />
                  <McProgressChartBlock
                    data={padArray(
                      compareAndAddDummy(
                        rate3DSCompliance,
                        rate3DSComplianceCountries,
                        "name"
                      ),
                      rate3DSComplianceCountries
                    )}
                    palette={_variablesSix}
                  />
                  <Button
                    text="Exemptions Details"
                    style={{
                      fontSize: "12pt",
                      padding: "0.5rem",
                      marginTop: "2rem",
                      width: "15rem",
                      //marginLeft:"6rem",
                    }}
                    dataBsToggle="modal"
                    dataBsTarget={"exemptionsModal"}
                  />
                </div>
                <hr />
                <div className="mb-4">
                  <>
                    <SmallTitleFlex
                      title={"Transaction share by merchant advice code (MAC)"}
                      tooltip={
                        "Share of declined transactions split by MAC Present or Absent status"
                      }
                    />
                    <div
                      style={{ display: "flex" }}
                      className="flex-lg-column flex-xl-row"
                    >
                      <McDonutChartSingle
                        data={dataMerchantAdviseCodeDonut}
                        palette={_variablesTwoOranges}
                        tooltipMeasureUnit="%"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <span>
                          <b>Transaction Share </b>
                          (within declined transactions)
                        </span>
                        <McDonutSideLegend
                          data={dataMerchantAdviseCodeDonut.map((el) => {
                            return { name: el.name, value: el.value };
                          })}
                          palette={_variablesTwoOranges}
                        />
                      </div>
                    </div>
                    <Modal
                      dataBsTarget="authMethodModal"
                      title={<b>Deepdive of MAC transaction share</b>}
                      modalBody={
                        <div
                          style={{
                            width: "100%",
                            height: "30rem",
                            display: "flex",
                          }}
                        >
                          <TableAG
                            colDefs={colsDefMerchantAdviseCode}
                            rowData={dataMerchantAdviseCode}
                          />
                        </div>
                      }
                    >
                      <Download
                        section="AutheBenchmark"
                        chartName="MAC"
                        data={dataMerchantAdviseCode}
                        productType={productType}
                        country={selectedCountries}
                        graphType="pie"
                        label={"MAC"}
                        timeRange={
                          dateLabels[timePeriod[0]] +
                          " - " +
                          dateLabels[timePeriod[1]]
                        }
                        fields={[
                          { field: "Transaction Share", value: "value" },
                        ]}
                        iconSize="sm"
                        className="h-8 w-8"
                      />
                    </Modal>
                    <div
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <Button
                        text="Details"
                        style={{
                          fontSize: "12pt",
                          padding: "0.5rem",
                          marginTop: "3rem",
                          width: "15rem",
                          // marginLeft: "auto",
                        }}
                        dataBsToggle="modal"
                        dataBsTarget={"authMethodModal"}
                      />
                    </div>
                  </>
                </div>
              </>
            )}
            <hr />
            <SmallTitleFlex
              title={"Decline reasons"}
              tooltip={
                "Incidence of each decline reason on total number of declined transactions (card not present channel only). " +
                "Area of each rectangle indicates the magnitude of each decline reason."
              }
            />
            <McTreemapChart
              data={dataDeclineReasons}
              listDeclineReasons={listDeclineReasons}
              showAbsoluteValue={false}
            />
          </>
        }
        rightContent={
          <>
            <Title styleClass="d-block d-lg-none mb-3">Peer</Title>
            <KpiColumn
              kpis={[
                /* {
                  leftContent:
                    (dataKpisCountries.transaction_approved
                      ? formatNumberFull(
                          dataKpisCountries.transaction_approved / 1000
                        )
                      : "0") + " K",
                  righContent: "Transactions approved",
                  color: _variablesTwoOranges.primaryOrange,
                },
                {
                  leftContent:
                    (dataKpisCountries.transaction_denied
                      ? formatNumberFull(
                          dataKpisCountries.transaction_denied / 1000
                        )
                      : "0") + " K",
                  righContent: "Transactions declined",
                  color: _variablesTwoOranges.primaryOrangeLight,
                }, */
                {
                  leftContent:
                    (dataKpisCountries.ticket_approved
                      ? formatNumberFull(dataKpisCountries.ticket_approved)
                      : "0") + " €",
                  righContent: "Average ticket approved",
                  color: _variablesTwoOranges.primaryOrange,
                },
                {
                  leftContent:
                    (dataKpisCountries.ticket_denied
                      ? formatNumberFull(dataKpisCountries.ticket_denied)
                      : "0") + " €",
                  righContent: "Average ticket declined",
                  color: _variablesTwoOranges.primaryOrangeLight,
                },
                {
                  leftContent: (
                    <KpiProgressChart
                      bar={{
                        value: dataKpisCountries.approval_rate,
                        color: _variablesTwoOranges.primaryOrange,
                      }}
                    />
                  ),
                  righContent: "Approval rate",
                  autofit: false,
                },
              ]}
            />

            <hr />

            <div className="mb-4">
              <div className="d-flex justify-content-between">
                <SmallTitleFlex
                  title={"Transactions by subchannel"}
                  tooltip={
                    "Card not present transactions split by subchannel incidence: Recurring, Ecommerce, MOTO or Other.<br /><br />" +
                    "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                  }
                />
                <Download
                  section="AutheBenchmark"
                  chartName="Subchannel"
                  data={mergedDataSubchannel}
                  graphType="pie"
                  timeRange={
                    dateLabels[timePeriod[0]] +
                    " - " +
                    dateLabels[timePeriod[1]]
                  }
                  label={"Subchannel"}
                  productType={productType}
                  country={selectedCountries}
                  fields={[
                    { field: "Overall Share (%)", value: "trx_share_all" },
                    { field: "Country Share (%)", value: "trx_share_country" },
                    {
                      field: "Overall Approval Rate (%)",
                      value: "approval_rate_all",
                    },
                    {
                      field: "Country Approval Rate (%)",
                      value: "approval_rate_country",
                    },
                  ]}
                  iconSize="sm"
                  className="h-8 w-8"
                />
              </div>
              <McDonutChartSingle
                data={dataBySubchannelCountries}
                side={"legend"}
                palette={_variablesFour}
                tooltipMeasureUnit="%"
              />
            </div>

            <SmallTitleFlex
              title="Approval rate by subchannel"
              tooltip={
                "Approval rates by different subchannels: Ecommerce, MOTO, Recurring, Others Approval rate is defined as number of successfully approved transactions to total transactions"
              }
            ></SmallTitleFlex>
            <McProgressChartBlock
              data={padArray(rateBySubchannelCountries, rateBySubchannel)}
              palette={_variablesFour}
            />

            <hr style={{ marginBottom: "2rem" }} />
            <div
              className="d-flex justify-content-end"
              style={{ marginBottom: "1rem" }}
            >
              <Download
                section="AutheBenchmark"
                chartName="TicketRange"
                data={mergedDataTicketRange}
                graphType="pie"
                label={"Ticket Range"}
                productType={productType}
                country={selectedCountries}
                timeRange={
                  dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
                }
                fields={[
                  { field: "Overall Share (%)", value: "value_all" },
                  { field: "Country Share (%)", value: "value_country" },
                  {
                    field: "Overall Approval Rate (%)",
                    value: "approval_rate_all",
                  },
                  {
                    field: "Country Approval Rate (%)",
                    value: "approval_rate_country",
                  },
                ]}
                iconSize="sm"
                className="h-8 w-8"
              />
            </div>

            <div className="mb-4">
              <SmallTitleFlex
                title={"Transaction count by ticket range"}
                tooltip={
                  "Card not present transactions split by ticket range incidence: 0€, 0.01-30€, 30.01-100€, 100.01-250€, 250.01-500€, or +500€.<br /><br />" +
                  "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                }
              />
              <McDonutChartSingle
                data={dataByTicketRangeCountries}
                side={"legend"}
                palette={_variablesSix}
                tooltipMeasureUnit="%"
              />
            </div>

            <SmallTitle>Approval rate by ticket range</SmallTitle>
            <McProgressChartBlock
              data={padArray(rateByTicketRangeCountries, rateByTicketRange)}
              palette={_variablesSix}
            />

            <hr />

            <div className="mb-4">
              <SmallTitleFlex
                title={"Transactions by token penetration"}
                tooltip={
                  "Card not present transactions split by tokenization incidence: tokenized or not tokenized.<br /><br />" +
                  "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                }
              />
              <McDonutChartSingle
                data={dataByTokenPenetrationCountries}
                side={"legend"}
                palette={_variablesTwoOranges}
                tooltipMeasureUnit="%"
              />
            </div>

            <SmallTitle>Approval rate by token penetration</SmallTitle>
            <McProgressChartBlock
              data={padArray(
                rateByTokenPenetrationCountries,
                rateByTokenPenetration
              )}
              palette={_variablesTwoOranges}
            />

            <hr />

            <div className="mb-4">
              <SmallTitleFlex
                title={"Transactions by program"}
                tooltip={
                  "Card not present transactions split by program incidence: 3DS or non 3DS.<br /><br />" +
                  "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                }
              />
              <McDonutChartSingle
                data={dataByProgramCountries}
                side={"legend"}
                palette={_variablesTwoOranges}
                tooltipMeasureUnit="%"
              />
            </div>

            <SmallTitle>Approval rate by program</SmallTitle>
            <McProgressChartBlock
              data={padArray(rateByProgramCountries, rateByProgram)}
              palette={_variablesTwoOranges}
            />

            {dashboardVersion2 && (
              <>
                <hr />
                <div className="mb-4">
                  <>
                    <div className="d-flex justify-content-between">
                      <SmallTitleFlex
                        title={"3DS Exemptions Share"}
                        tooltip={
                          "Transaction share of various CNP compositions along with exemptions."
                        }
                      />
                      <Download
                        section="AutheBenchmark"
                        chartName="3DS Exemptions"
                        data={mergedData3DSCompliance}
                        graphType="pie"
                        label={"3DS Exemptions"}
                        productType={productType}
                        country={selectedCountries}
                        timeRange={
                          dateLabels[timePeriod[0]] +
                          " - " +
                          dateLabels[timePeriod[1]]
                        }
                        fields={[
                          {
                            field: "Merchant Share (%)",
                            value: "trx_share_all",
                          },
                          {
                            field: "Peers Share (%)",
                            value: "trx_share_country",
                          },
                          {
                            field: "Merchant Approval Rate (%)",
                            value: "approval_rate_all",
                          },
                          {
                            field: "Peers Approval Rate (%)",
                            value: "approval_rate_country",
                          },
                        ]}
                        iconSize="sm"
                        className="h-8 w-8"
                      />
                    </div>
                    <div
                      style={{ display: "flex" }}
                      className="flex-lg-column flex-xl-row"
                    >
                      <McDonutChartSingle
                        data={data3DSComplianceCountries}
                        palette={_variablesSix}
                        tooltipMeasureUnit="%"
                      />
                      <div
                        style={{
                          marginTop: "",
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        {data3DSComplianceCountries.length > 0 && (
                          <b>Transaction Share</b>
                        )}
                        <McDonutSideLegend
                          data={data3DSComplianceCountries}
                          palette={_variablesSix}
                        />
                      </div>
                    </div>

                    <Modal
                      dataBsTarget="exemptionsModalBenchmark"
                      title={<b>Non-3DS Exemption Types</b>}
                      modalBody={
                        <div
                          style={{
                            width: "100%",
                            height: "30rem",
                            display: "flex",
                          }}
                        >
                          <TableAG
                            colDefs={colDefs}
                            rowData={dataExemptionTypesCountries}
                          />
                        </div>
                      }
                    >
                      <Download
                        section="AutheBenchmark"
                        chartName="Non-3DS Exemption Types"
                        data={dataExemptionTypesCountries}
                        graphType="pie"
                        label={"Exemption Type"}
                        productType={productType}
                        country={selectedCountries}
                        timeRange={
                          dateLabels[timePeriod[0]] +
                          " - " +
                          dateLabels[timePeriod[1]]
                        }
                        fields={[
                          {
                            field: "Transaction Share",
                            value: "transaction_share",
                          },
                          { field: "Approval Rate", value: "approval_rate" },
                        ]}
                        iconSize="sm"
                        className="h-8 w-8"
                      />
                    </Modal>
                  </>
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <SmallTitleFlex
                    title="Approval Rate by 3DS exemptions"
                    tooltip={
                      "Approval rate of various CNP compositions along with exemptions.<br /><br />" +
                      "Approval rate is defined as number of successfully approved transactions over total transactions"
                    }
                  />
                  <McProgressChartBlock
                    data={padArray(
                      rate3DSComplianceCountries,
                      rate3DSCompliance
                    )}
                    palette={_variablesSix}
                  />
                  {dataExemptionTypesCountries.length > 0 ? (
                    <Button
                      text="Exemptions Details"
                      style={{
                        fontSize: "12pt",
                        padding: "0.5rem",
                        marginTop: "2rem",
                        width: "15rem",
                        //marginLeft:"6rem",
                      }}
                      dataBsToggle="modal"
                      dataBsTarget={"exemptionsModalBenchmark"}
                    />
                  ) : (
                    <div
                      style={{
                        fontSize: "12pt",
                        padding: "0.5rem",
                        marginTop: "2rem",
                        width: "15rem",
                        height: "2.6rem",
                      }}
                    ></div>
                  )}
                </div>
                <hr />
                <div className="mb-4">
                  <>
                    <SmallTitleFlex
                      title={"Transaction share by merchant advice code (MAC)"}
                      tooltip={
                        "Share of declined transactions split by MAC Present or Absent status"
                      }
                    />
                    <div
                      style={{ display: "flex" }}
                      className="flex-lg-column flex-xl-row"
                    >
                      <McDonutChartSingle
                        data={dataMerchantAdviseCodeDonutCountries}
                        palette={_variablesTwoOranges}
                        tooltipMeasureUnit="%"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        {dataMerchantAdviseCodeDonutCountries.length > 0 && (
                          <span>
                            <b>Transaction Share </b>
                            (within declined transactions)
                          </span>
                        )}
                        <McDonutSideLegend
                          data={dataMerchantAdviseCodeDonutCountries.map(
                            (el) => {
                              return { name: el.name, value: el.value };
                            }
                          )}
                          palette={_variablesTwoOranges}
                        />
                      </div>
                    </div>
                    <Modal
                      dataBsTarget="authMethodModalBenchmark"
                      title={<b>Deepdive of MAC transaction share</b>}
                      modalBody={
                        <div
                          style={{
                            width: "100%",
                            height: "30rem",
                            display: "flex",
                          }}
                        >
                          <TableAG
                            colDefs={colsDefMerchantAdviseCode}
                            rowData={dataMerchantAdviseCodeCountries}
                          />
                        </div>
                      }
                    >
                      <Download
                        section="AutheBenchmark"
                        chartName="MAC"
                        data={dataMerchantAdviseCodeCountries}
                        graphType="pie"
                        label={"MAC"}
                        productType={productType}
                        country={selectedCountries}
                        timeRange={
                          dateLabels[timePeriod[0]] +
                          " - " +
                          dateLabels[timePeriod[1]]
                        }
                        fields={[
                          { field: "Transaction Share", value: "value" },
                        ]}
                        iconSize="sm"
                        className="h-8 w-8"
                      />
                    </Modal>
                    <div
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      {dataMerchantAdviseCodeDonutCountries.length > 0 ? (
                        <Button
                          text="Details"
                          style={{
                            fontSize: "12pt",
                            padding: "0.5rem",
                            marginTop: "3rem",
                            width: "15rem",
                          }}
                          dataBsToggle="modal"
                          dataBsTarget={"authMethodModalBenchmark"}
                        />
                      ) : (
                        <div
                          style={{
                            fontSize: "12pt",
                            padding: "0.5rem",
                            marginTop: "3rem",
                            width: "15rem",
                            height: "2.6rem",
                          }}
                        ></div>
                      )}
                    </div>
                  </>
                </div>
              </>
            )}
            <hr />
            <div className="d-flex justify-content-between">
              <SmallTitleFlex
                title={"Decline reasons"}
                tooltip={
                  "Incidence of each decline reason on total number of declined transactions (card not present channel only). " +
                  "Area of each rectangle indicates the magnitude of each decline reason."
                }
              />
              <Download
                section="AutheBenchmark"
                chartName="FailureReasons"
                data={mergedDataDeclineReasons}
                graphType="decline"
                label={"Decline Reason"}
                productType={productType}
                country={selectedCountries}
                timeRange={
                  dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
                }
                fields={[
                  { field: "Percentage Overall (%)", value: "percent_all" },
                  { field: "Percentage Country (%)", value: "percent_country" },
                ]}
                iconSize="sm"
                className="h-8 w-8"
              />
            </div>
            <McTreemapChart
              data={dataDeclineReasonsCountries}
              listDeclineReasons={listDeclineReasons}
              showAbsoluteValue={false}
            />
          </>
        }
      />
    </LayoutPageContainer>
  );
};

export default AuthorizationBenchmark;
