import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  LabelList,
  Line,
} from "recharts";
import { _variablesSix } from "../../styles/_variables";

const McLineChart = ({
  data,
  labels,
  height = 440,
  palette = _variablesSix,
}) => {
  const COLORS = Object.values(palette);
  return (
    <ResponsiveContainer width="100%" height={height}>
      <LineChart
        width="100%"
        height="100%"
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="yearmonth" />
        <YAxis tickFormatter={(value) => `${value}%`} />
        <Tooltip />
        <Legend wrapperStyle={{ fontSize: "18px" }} />
        <Line
          type="linear"
          dataKey="merchant"
          stroke={COLORS[1]}
          strokeWidth={3}
          activeDot={{ r: 8 }}
          name="Merchant"
        />
        <Line
          type="linear"
          dataKey="peers"
          stroke={COLORS[2]}
          strokeWidth={3}
          activeDot={{ r: 8 }}
          name="Peers"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

McLineChart.defaultProps = {
  data: [
    {
      yearmonth: "JAN 24",
      merchant: 50,
      peers: 60,
    },
    {
      yearmonth: "FEB 24",
      merchant: 57,
      peers: 40,
    },
    {
      yearmonth: "MAR 24",
      merchant: 34,
      peers: 81,
    },
    {
      yearmonth: "APR 24",
      merchant: 45,
      peers: 23,
    },
    {
      yearmonth: "MAY 24",
      merchant: 51,
      peers: 68,
    },
    {
      yearmonth: "JUN 24",
      merchant: 20,
      peers: 69,
    },
  ],
};

export default McLineChart;
