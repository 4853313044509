import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Label,
  LabelList,
  Legend,
} from "recharts";
import {
  customTooltipLayout,
  formatNumberFull,
  formatNumberRoundLocale,
} from "../../services/UtilsService";
import { _variablesFourGrey } from "../../styles/_variables";

const McBarChartVerticalDouble = ({
  data,
  dataGuide,
  labels,
  height = 600,
  palette = _variablesFourGrey,
  legend = false,
  isPerformance = false,
  background = true,
  tooltipMeasureUnit = "",
}) => {
  const COLORS = Object.values(palette);

  const tickFormatter = (value, index) => {
    const limit = 15;
    if (value.length < limit) return value;
    return `${value.substring(0, limit).replace(/ /g, "\u00A0")}...`;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return customTooltipLayout(
        active,
        payload,
        label,
        tooltipMeasureUnit,
        undefined,
        false
      );
    }

    return null;
  };

  const renderCustomizedLabel = ({ x, y, width, height, value }) => {
    let xPosition = 30 + x + width;

    return (
      <g>
        <text
          x={xPosition > 550 ? 550 : xPosition}
          y={y + height / 2}
          fill="#141413"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {value}
        </text>
      </g>
    );
  };

  return (
    <>
      <ResponsiveContainer width="100%" height={height}>
        <BarChart
          layout="vertical"
          width="100%"
          height="100%"
          data={data}
          margin={{
            top: 0,
            right: 60,
            left: 40,
            bottom: 30,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" allowDecimals={false}>
            {labels ? (
              labels[0] && (
                <Label value={labels[0]} offset={-40} position={"top"} />
              )
            ) : (
              <></>
            )}
          </XAxis>
          <YAxis
            type="category"
            dataKey={dataGuide.y}
            tickFormatter={tickFormatter}
            fontSize={10}
          ></YAxis>
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign="top" height={60} />

          {dataGuide.barsData.map((bar, index) => {
            return (
              <Bar
                dataKey={bar.dataName}
                name={bar.friendlyName}
                fill={COLORS[index % COLORS.length]}
                background={background && index === 0 && { fill: COLORS[2] }}
                key={`bar-${index}-${Math.random()}`}
                animationBegin={1000}
              >
                <LabelList
                  dataKey={bar.dataName}
                  content={renderCustomizedLabel}
                  position="right"
                  fill={COLORS[3]}
                />
              </Bar>
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default McBarChartVerticalDouble;
