import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { formatNumberFull } from "../../services/UtilsService";
import McDonutSideText from "./McDonutSideText";
import _variables, { _variablesThreeGrey } from "../../styles/_variables";

const McDonutChartDouble = ({
  data,
  sideTextRows,
  palette = _variablesThreeGrey,
  tooltipMeasureUnit = "",
}) => {
  const COLORS = Object.values(palette);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip-container p-2">
          {payload.map((p, index) => {
            return (
              <div key={index + p.value + Math.random()}>
                <div
                  style={{
                    color:
                      p.payload.fill === _variablesThreeGrey.chartGrey
                        ? _variables.textBlack
                        : p.payload.fill,
                  }}
                  className="custom-tooltip-text"
                >{`${p.name}: ${formatNumberFull(
                  p.value
                )}${tooltipMeasureUnit}`}</div>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  return (
    <div className="row">
      <div className="col-12 col-xl-5">
        <PieChart width={200} height={200}>
          <Pie
            data={data[1]}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={50}
            outerRadius={70}
            startAngle={90}
            endAngle={-270}
          >
            {data.length > 0 ? (
              data[1].map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={index == 0 ? COLORS[1] : COLORS[2]}
                />
              ))
            ) : (
              <></>
            )}
          </Pie>
          <Pie
            data={data[0]}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={80}
            outerRadius={100}
            startAngle={90}
            endAngle={-270}
          >
            {data.length > 0 ? (
              data[0].map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={index == 0 ? COLORS[0] : COLORS[2]}
                />
              ))
            ) : (
              <></>
            )}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </div>
      <div className="col-12 col-xl-7 mt-2 mt-xl-0 d-flex pe-3">
        <McDonutSideText rows={sideTextRows} />
      </div>
    </div>
  );
};

export default McDonutChartDouble;
