import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import {
  customTooltipLayout,
  formatNumberRoundLocale,
} from "../../services/UtilsService";
import { _variablesThreeOrangesRate } from "../../styles/_variables";

const McYoYChange = ({
  data,
  labels,
  height = 440,
  palette = _variablesThreeOrangesRate,
  tooltipMeasureUnit = "",
}) => {
  const COLORS = Object.values(palette);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return customTooltipLayout(active, payload, label, tooltipMeasureUnit);
    }
    return null;
  };

  // Max and Min values for YAxis
  const minValue = Math.min(
    ...data.map((item) => Math.min(item.trxCard, item.trxNoCard))
  );
  const maxValue = Math.max(
    ...data.map((item) => Math.max(item.trxCard, item.trxNoCard))
  );

  // Calculate magnitude for YAxis
  const magnitude = Math.pow(10, Math.floor(Math.log10(maxValue)));

  // Adapt step for YAxis
  let step;
  if (magnitude >= 1000) {
    step = 200;
  } else if (magnitude >= 100) {
    step = 100;
  } else if (magnitude >= 10) {
    step = 50;
  } else {
    step = 10;
  }

  // Adjust domain for YAxis
  const domain = [
    Math.floor(minValue / step) * step - step,
    Math.ceil(maxValue / step) * step + step,
  ];

  // Create ticks for YAxis
  const ticks = [];
  for (let i = domain[0]; i <= domain[1]; i += step) {
    ticks.push(i);
  }

  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart
        width="100%"
        height="100%"
        data={data}
        margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis
          tickFormatter={(value) => `${formatNumberRoundLocale(value)}%`}
          domain={domain}
          ticks={ticks}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend verticalAlign="top" height={36} />
        <Bar dataKey="trxCard" name="Card Present" fill={COLORS[0]}>
          <LabelList
            dataKey="trxCard"
            position="top"
            fill="black"
            formatter={(value) => value.toFixed(1)}
          />
        </Bar>
        <Bar dataKey="trxNoCard" name="Card Not Present" fill={COLORS[1]}>
          <LabelList
            dataKey="trxNoCard"
            position="top"
            fill="black"
            formatter={(value) => value.toFixed(1)}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

// Demo values
McYoYChange.defaultProps = {
  data: [
    { month: "JAN 22", trxCard: 100.33, trxNoCard: 14.46 },
    { month: "FEB 22", trxCard: 41.22, trxNoCard: 7.06 },
    // Add more months as needed
  ],
  labels: ["Transactions (K)"],
};

export default McYoYChange;
