import SmallTitle from "../../components/Title/SmallTitle";
import ReactSlider from "react-slider";
import "./TimePeriod.css";

// https://zillow.github.io/react-slider/
const TimePeriod = ({
  dateLabels,
  timePeriod,
  setTimePeriod,
  isSpecialMerchant = false,
}) => {
  // always 12 months
  const ticks = [...Array(isSpecialMerchant ? 9 : 12).keys()];

  return (
    <div className="pb-4">
      <SmallTitle>Time period</SmallTitle>
      <ReactSlider
        marks
        className="time-period-slider"
        markClassName="time-period-mark"
        thumbClassName="time-period-thumb"
        trackClassName="time-period-track"
        min={ticks[0]}
        max={ticks[ticks.length - 1]}
        defaultValue={timePeriod}
        onChange={(value, thumbIndex) => setTimePeriod(value)}
        pearling
        renderThumb={(props, state) => (
          <div {...props}>
            <div className="time-period-moving-label">
              {dateLabels[timePeriod[state.index]]}
            </div>
          </div>
        )}
        /* minDistance={1} */
      />

      {/* <div className="d-flex justify-content-between align-items-center mt-3">
        <div>{dateLabels[timePeriod[0]]}</div>
        <div>{dateLabels[timePeriod[1]]}</div>
      </div> */}
    </div>
  );
};

export default TimePeriod;
