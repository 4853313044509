import { formatNumberFull } from "../../services/UtilsService";
import { _variablesTwoOranges } from "../../styles/_variables";
import "./TableYoY.css";

const TableYoY = ({ data = [], dataGuide = "transactions" }) => {
  return (
    <>
      <div className="d-flex justify-content-center align-items-center mb-3">
        <div
          className="square me-1"
          style={{ backgroundColor: _variablesTwoOranges.primaryOrange }}
        ></div>
        <div
          className="me-2"
          style={{ color: _variablesTwoOranges.primaryOrange }}
        >
          Card present
        </div>
        <div
          className="square me-1"
          style={{
            backgroundColor: _variablesTwoOranges.primaryOrangeLight,
          }}
        ></div>
        <div style={{ color: _variablesTwoOranges.primaryOrangeLight }}>
          Card not present
        </div>
      </div>

      <div className="table-responsive">
        {data.length > 0 ? (
          <table className="table">
            <tbody>
              <tr className="noborder-tr">
                <td className="legend-td border-td">
                  <div
                    className="square"
                    style={{
                      backgroundColor: _variablesTwoOranges.primaryOrange,
                    }}
                  ></div>
                </td>
                {data.map((month, index) => (
                  <td key={index + "yoyfirst"} className="border-td">
                    {dataGuide === "transactions"
                      ? month.trxCard
                        ? formatNumberFull(month.trxCard) + "%"
                        : "-"
                      : month.volCard
                      ? formatNumberFull(month.volCard) + "%"
                      : "-"}
                  </td>
                ))}
              </tr>
              <tr>
                <td className="legend-td border-td">
                  <div
                    className="square"
                    style={{
                      backgroundColor: _variablesTwoOranges.primaryOrangeLight,
                    }}
                  ></div>
                </td>
                {data.map((month, index) => (
                  <td key={index + "yoysecond"} className="border-td">
                    {dataGuide === "transactions"
                      ? month.trxNoCard
                        ? formatNumberFull(month.trxNoCard) + "%"
                        : "-"
                      : month.volNoCard
                      ? formatNumberFull(month.volNoCard) + "%"
                      : "-"}
                  </td>
                ))}
              </tr>
              <tr className="noborder-tr">
                <td className="border-td"></td>
                {data.map((month, index) => (
                  <td key={index + "yoythird"} className="border-td">
                    {month.month}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default TableYoY;
