import "./FilterBar.css"

const FilterBar = ({children}) => {
  return (
    <div className="filter-box-single flex-sm-column flex-md-column flex-lg-row">
      {children}
    </div>
  )
}

export default FilterBar