import Button from "../../components/Button/Button";
import { useContext } from "react";
import { AccountContext } from "../../services/AccountService";
import "./TermsAndConditions.css";
import { MerchantsTerms } from "./MerchantsTerms";
import { AcquirersTerms } from "./AcquirersTerms";

const TermsAndConditions = ({ setIsReadingTnc }) => {
  const { userPermission } = useContext(AccountContext);
  return (
    <div className="text-white mb-5 mt-5 mx-5">
      <Button
        text="&lt; Go back"
        onClick={() => setIsReadingTnc(false)}
        styleClass={"sticky top-20 float-end mt-2"}
      />
      <div className="text-white" style={{ padding: "50px" }}>
        {userPermission === "acquirer" ? (
          <AcquirersTerms />
        ) : (
          <MerchantsTerms />
        )}
      </div>
    </div>
  );
};

export default TermsAndConditions;
