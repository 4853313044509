import SmallTitle from "./SmallTitle";
import TooltipInfo from "../TooltipInfo/TooltipInfo";

const SmallTitleFlex = ({ title, tooltip, styleClass = "mb-3" }) => {
  return (
    <div
      className={
        "d-flex justify-content-start align-items-center " + styleClass
      }
    >
      <SmallTitle styleClass={"mb-0 me-2"}>{title}</SmallTitle>
      <TooltipInfo content={tooltip} />
    </div>
  );
};

export default SmallTitleFlex;
