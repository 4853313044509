import McProgressChart from "./McProgressChart";
import _variables from "../../styles/_variables";

const McDonutSideBars = ({ bars, title }) => {
  return (
    <div className="w-100">
      {bars.length > 0 ? <div className="mb-2">{title}</div> : <></>}
      {bars.map((bar, index) => {
        return <McProgressChart bar={bar} key={index + "donutsidebar"} />;
      })}

      <div className="mt-4 row">
        {bars.map((bar, index) => {
          return (
            <div
              className="col-6 d-flex justify-content-start align-items-center"
              key={index + "donutsidelegend"}
            >
              <div
                className="square me-2"
                style={{ backgroundColor: bar.color }}
              ></div>
              <div>{bar.text}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default McDonutSideBars;
