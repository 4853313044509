import "./Alert.css";

const Alert = ({
  alertText,
  styleColor = "alert-danger",
  styleClass = "",
  onClick,
  paddingLeft = true,
}) => {
  return alertText ? (
    <div
      className={
        "container container-alert " +
        (paddingLeft === 0
          ? "ps-0"
          : paddingLeft
          ? "pl-expanded"
          : "pl-not-expanded")
      }
    >
      <div
        className={
          "alert alert-dismissible fade show alert-shadow " +
          styleClass +
          " " +
          (styleColor === "error"
            ? "alert-danger"
            : styleColor === "success"
            ? "alert-success"
            : styleColor)
        }
        role="alert"
      >
        <div className="text-start">{alertText}</div>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
          onClick={() => {
            onClick({ message: "" });
          }}
        ></button>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Alert;
